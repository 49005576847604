<template>


    <v-sheet class="pa-0 ma-0 " style="margin: auto" max-width="700">
        <v-app v-if="true" class="pa-0 ma-0">
            <v-sheet class="pa-0 ma-0 white">
                <v-col cols="12" class="px-0 mx-0 pt-5">
                    <AppBar app />
                </v-col>
                <v-col cols="12" class="px-0 mx-0 py-0">
                    <router-view />
                </v-col>
                <v-col cols="12" class="px-0 mx-0 pt-5 pb-0 text-center">
                    <FooterBar />
                </v-col>
            </v-sheet>
        </v-app>
        <v-app v-else class="text-center">

            <div class="ma-4">
                <br>
                <v-img
                    max-height="96"
                    max-width="96"
                    class="text-center"
                    src="./assets/logo_128x128.png"
                    alt="경마-말고고-경마분석-경마문화-서울경마-제주경마-부경경마-문자-서비스-점검-알림"
                ></v-img>
                <br>
                <div>서비스 점검중</div>
                <div>2021.10.28 16:00 ~ 2021.11.01 00:00</div>
                <br>
                <div>마사회 공공API 경주마번호 수정에 따라</div>
                <div>말고고.ai 데이터도 업데이트가 이뤄집니다.</div>
                <div>빠르고 정확한 업데이트하겠습니다.</div>
                <br>
                <div>nerd@malgogo.ai</div>
            </div>
        </v-app>
    </v-sheet>

</template>

<script>
import AppBar from "@/components/AppBar.vue";
import FooterBar from "@/components/FooterBar.vue";


export default {
    name: "App",
    active: "",
    components: {
        AppBar,
        FooterBar
    },

    watch: {
        $route(to, from) {
            if (to.path != from.path) { /* router path가 변경될 때마다 서버로 접근로그를 저장한다. */
                console.log("[App][watch-route] [" + to.path + "] => [" + from.path + "]");

            }
        }

    }
};
</script>

<style></style>