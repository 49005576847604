<template>
    <v-container>
        <v-card elevation="0" tile outlined class="mt-3">
            <v-row justify="center my-10">
                <v-img
                    max-height="128"
                    max-width="128"
                    class="text-center"
                    src="../assets/logo_128x128.png"
                    alt="경마-말고고-경마분석-경마문화-서울경마-제주경마-부경경마-문자-로딩시간-알림"
                ></v-img>
            </v-row>
            <v-card-text
                class="text-center text-h6 blue-grey--text font-weight-black my-5">
                잠시만요..
            </v-card-text>
            <v-card-text
                class="text-center  grey--text text--darken-3 font-weight-regular  pt-1 pb-10 px-5">
                경마 분석 말고고 카드를<br>URL 주소로 공유할 수 있어요 :D
            </v-card-text>
            <div class="text-center my-10">
                <v-progress-circular
                    :size="70"
                    :width="7"
                    color="grey"
                    indeterminate
                ></v-progress-circular>
            </div>
        </v-card>
    </v-container>
</template>

<script>


import router from "@/router";
import firebase from "firebase";

export default {
    name: "Share",
    components: {},

    computed: {},
    watch: {},
    methods: {},

    created() {

        const _this = this;
        firebase.auth().onAuthStateChanged(function() {

            const targetID = _this.$route.params.id;
            if (targetID == undefined || targetID == null) {

                setTimeout(function() {
                    alert("잘못된 말고고.ai URL 주소입니다 T.T");
                    router.replace({ name: "Home" });
                }, 200);
            } else {


                const targetIDSplit = targetID.split("-");

                if (targetIDSplit.length != 4) {
                    setTimeout(function() {
                        alert("잘못된 말고고.ai URL 주소입니다 T.T;;");
                        router.replace({ name: "Home" });
                    }, 200);
                } else {
                    let isHorse = false;
                    let isRace = false;
                    if (targetIDSplit[0] === "horse") {
                        isHorse = true;
                    } else if (targetIDSplit[0] === "race") {
                        isRace = true;
                    }


                    let collectionPath = "";
                    if (isHorse) {
                        collectionPath = "HorseDetail";
                    } else if (isRace) {
                        collectionPath = "RaceDetail";
                    }

                    firebase.firestore().collection(collectionPath).doc(targetID).get().then(function(doc) {
                        if (doc.exists) {
                            console.log("[92][Share.vue][created] Exist in FireStore: ", targetID);
                            let queueItem = {};
                            if (isHorse) {
                                queueItem = {
                                    "id": targetID,
                                    "target": targetIDSplit[0],
                                    "horse": {
                                        "name": targetIDSplit[1],
                                        "meet": parseInt(targetIDSplit[2]),
                                        "hid": targetIDSplit[3]
                                    },
                                    "race": {},
                                    "favorite": false
                                };
                                _this.$store.getters["favoriteStore/GET_FAVORITE"]["items"].some(fff => {
                                    if (fff["id"] == targetID) {
                                        queueItem["favorite"] = true;
                                        return true;
                                    } else {
                                        return false;
                                    }
                                });
                            } else if (isRace) {
                                queueItem = {
                                    "id": targetID,
                                    "target": targetIDSplit[0],
                                    "race": {
                                        "meet": parseInt(targetIDSplit[1]),
                                        "date": targetIDSplit[2],
                                        "no": targetIDSplit[3]
                                    },
                                    "favorite": false
                                };
                            }

                            let renewDetailDisplayItems = _this.$store.getters["detailCardStore/GET_DETAIL_DISPLAY"]["items"];
                            renewDetailDisplayItems = renewDetailDisplayItems.filter(ff => ff.id != targetID);
                            renewDetailDisplayItems = [queueItem].concat(renewDetailDisplayItems);

                            const renewDetailDisplay = {
                                ts: new Date().getTime(),
                                items: renewDetailDisplayItems
                            };

                            _this.$store.commit("detailCardStore/MUT_DETAIL_DISPLAY_OVERWRITE", renewDetailDisplay);
                            localStorage.setItem("DetailDisplay", JSON.stringify(renewDetailDisplay));
                            _this.$store.commit("detailCardStore/MUT_DETAIL_SCROLL_ID_OVERWRITE", targetID);
                        } else {
                            console.log("[92][Share.vue][created] NoExist in FireStore: ", targetID);
                            alert("잘못된 말고고.ai URL 주소입니다 T.T");
                        }

                        setTimeout(function() {
                            router.replace({ name: "Home" });
                        }, 200);

                    }).catch(function(error) {
                        console.log("[92][Share.vue][created] Firestore Collection Error Catch: ", targetID, error);

                        setTimeout(function() {
                            alert("잘못된 말고고.ai URL 주소입니다 T.T;");
                            router.replace({ name: "Home" });
                        }, 200);
                    });
                }
            }
        });

    },

    mounted() {
    },

    data() {
        return {};
    }
};
</script>

<style scoped></style>
