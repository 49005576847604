const userAccountInfoStore = {
    namespaced: true,
    state: {
        userAccountInfo: {
            login: true,
            provider: "",
            email: "",
            displayName: "",
            thumbnailURL: "",
            uid: ""
        }
    },
    getters: {
        GET_USER_ACCOUNT_INFO: state => state.userAccountInfo
    },
    mutations: {
        MUT_GET_USER_ACCOUNT_INFO_OVERWRITE: (state, payload) => {
            state.userAccountInfo = payload;
        }
    },
    actions: {}
};

export default userAccountInfoStore;