<template>
    <v-card elevation="0" tile outlined class="mt-5">
        <v-card-title class="headline">
            <v-icon>mdi-account-outline</v-icon>
            <v-spacer />
            나의 정보
            <v-spacer />
        </v-card-title>


        <div v-if="this.loginIng" class="text-center ma-5">
            <v-progress-circular
                :size="50"
                :width="7"
                color="grey"
                indeterminate
                class="mb-3"
            />
            <v-spacer />

            로그인 중입니다...
        </div>
        <div v-else-if="this.userLogin" class="ma-5">
            <v-card
                class="mx-auto"
                max-width="344"
                outlined
            >
                <v-list-item three-line>
                    <v-list-item-avatar
                        tile
                        size="80"
                        color="grey"
                    >
                        <img
                            :src=userThumbnailURL
                        >
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <div class="subtitle-2  font-weight-light ma-0 pa-0">
                            {{ this.userLoginProviderDisplay }}
                        </div>
                        <div class="text-h5   ma-0 pa-0">
                            {{ this.userDisplayName }}
                        </div>
                        <div class="subtitle-2 font-weight-regular ma-0 pa-0">
                            {{ this.userEmail }}
                        </div>

                    </v-list-item-content>

                </v-list-item>

                <v-card-actions>
                    <v-btn elevation="0" small block class="grey lighten-3 grey--text text--darken-4 text-center"
                           @click="logOutAccount()">
                        로그아웃
                    </v-btn>
                </v-card-actions>
            </v-card>
        </div>


        <div v-else-if="this.userLogin">
            <v-row class="px-3">
                <v-col cols="12" class="pa-0 mx-0 mt-0 mb-1 text-center text-body-1 grey--text text--darken-1">
                    {{ this.userLoginProviderDisplay }}
                </v-col>
                <v-col cols="12" class="pa-0 mx-0 mt-0 mb-1 text-center text-body-1 grey--text text--darken-1">
                    {{ this.userDisplayName }}
                </v-col>
                <v-col cols="12" class="pa-0 mx-0 mt-0 mb-1 text-center text-body-1 grey--text text--darken-1">
                    {{ this.userEmail }}
                </v-col>
                <v-col cols="2" />
                <v-col cols="8" class="mx-0 pa-0 my-3 ">
                    <v-btn elevation="0" small block class="grey lighten-3 grey--text text--darken-4 text-center"
                           @click="logOutAccount()">
                        로그아웃
                    </v-btn>
                </v-col>
                <v-col cols="2" />
            </v-row>
        </div>
        <div v-else class="mb-5">
            <v-row>
                <v-col cols="1" />
                <v-col cols="10" class="py-3">
                    <div class="px-5 text-center text-body-1 font-weight-light">
                        로그인하면 말고고.ai를
                        <br>
                        보다 즐겁게 사용할 수 있어요 :D
                    </div>
                </v-col>
                <v-col cols="1"></v-col>
            </v-row>
            <v-row class="px-3">
                <v-col cols="1" />
                <v-col cols="10" class="mx-0 my-1 pa-0 text-center">
                    <v-btn elevation="0" large block color="#db4a39" class="white--text text-center text-body-1"
                           @click="signInWithGoogle()">
                        <v-icon left>mdi-google</v-icon>
                        <v-spacer />
                        구글 로그인
                        <v-spacer />
                    </v-btn>
                    <!--                    <v-img class="align-center text-center" @click="signInWithGoogle()" width="100%" height="auto" src="../assets/LoginBtnGoogle.png" />-->
                </v-col>
                <v-col cols="1" />

                <v-col cols="1" />
                <v-col cols="10" class="mx-0 my-1 pa-0 text-center">
                    <!--                    <v-img src="../assets/LoginBtnKakao.png"></v-img>-->
                    <v-btn elevation="0" large block color="#ffe812" class="black--text text-center text-body-1"
                           @click="signInWithKakao()">
                        <v-img left
                               max-height="20"
                               max-width="20"
                               src="../assets/kakao.png"
                               class="text-center ma-0 pa-0"
                               alt="경마-말고고-경마분석-경마문화-서울경마-제주경마-부경경마-문자-로그인-카카오-버튼"
                        />
                        <v-spacer />
                        카카오 로그인
                        <v-spacer />
                    </v-btn>

                    <!--                    <v-img class="align-center justify-center text-center" @click="signInWithKakao()"  width="100%" height="auto" src="../assets/LoginBtnKakao.png" />-->
                </v-col>
                <v-col cols="1" />
                <div v-if="false">

                    <v-col cols="3" class="text-center mb-0">
                        <v-btn
                            color="#db4a39"
                            fab
                            large
                            class="white--text"
                        >
                            <v-icon>mdi-google</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="3" class="text-center mb-0">

                        <v-btn
                            color="#4267B2"
                            fab
                            large
                            dark
                        >
                            <v-icon>mdi-facebook</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="3" class="text-center mb-0">
                        <v-btn
                            color="#1DA1F2"
                            fab
                            large
                            dark
                        >
                            <v-icon>mdi-twitter</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="3" class="text-center mb-0">
                        <v-btn
                            color="#3EAF0E"
                            fab
                            large
                            dark
                        >
                            <v-icon>mdi-email</v-icon>
                        </v-btn>
                    </v-col>


                    <v-col cols="3" class="text-center mt-0 pt-0 grey--text text-body-2 font-weight-regular">
                        구글
                    </v-col>
                    <v-col cols="3" class="text-center mt-0 pt-0 grey--text text-body-2 font-weight-regular">
                        페이스북
                    </v-col>
                    <v-col cols="3" class="text-center mt-0 pt-0 grey--text text-body-2 font-weight-regular">
                        트위터
                    </v-col>
                    <v-col cols="3" class="text-center mt-0 pt-0 grey--text text-body-2 font-weight-regular">
                        이메일
                    </v-col>
                </div>
            </v-row>
        </div>

    </v-card>
</template>
<script>

import firebase from "firebase";
import "firebase/auth";
import "firebase/functions";

if (!window.Kakao.isInitialized()) {
    window.Kakao.init("cc62197b73736b79417722ae96ff00dc"); // js key
    window.Kakao.isInitialized();
}

export default {

    name: "LoginCard",

    computed: {},

    methods: {
        getUserInfo() {

            console.log("[LoginCard.vue][getUserInfo]  IN ", this.$route.query.code);

            const _this = this;
            firebase.auth().onAuthStateChanged(function(user) {
                console.log("[LoginCard.vue][getUserInfo][auth().onAuthStateChanged] In");

                let isLoginIng = false;
                let isUser = false;
                if (_this.$route.query.code || _this.googleLoginIng) {
                    isLoginIng = true;
                }
                if (user) {
                    isUser = true;
                }

                if (isUser) { // 로그인 상태
                    console.log("[LoginCard.vue][getUserInfo][auth().onAuthStateChanged] LogIn Status");
                    console.log("[LoginCard.vue][getUserInfo]  user: ", user);
                    _this.userLogin = true;
                    if (user.providerData.length > 0) {
                        user.providerData.forEach(function(profile) {
                            console.log("[LoginCard.vue][getUserInfo]  profile: ", profile);
                            _this.userLoginProvider = profile.providerId;
                            _this.userDisplayName = profile.displayName;
                            _this.userEmail = profile.email;

                            if (user.photoURL == null || user.photoURL == undefined || user.photoURL == "") {
                                _this.userThumbnailURL = _this.userThumbnailDefailtURL;
                            } else {
                                _this.userThumbnailURL = user.photoURL;
                            }

                            switch (_this.userLoginProvider) {
                                case "google.com":
                                    console.log("[LoginCard.vue][getUserInfo] _this.userLoginProviderDisplay: ", 1);
                                    _this.userLoginProviderDisplay = "구글 로그인";
                                    break;
                                case "":
                                    console.log("[LoginCard.vue][getUserInfo] _this.userLoginProviderDisplay: ", 2);
                                    _this.userLoginProviderDisplay = "";
                                    break;
                                default:
                                    console.log("[LoginCard.vue][getUserInfo] _this.userLoginProviderDisplay: ", 3);
                                    break;
                            }
                        });
                    } else {
                        if (user.uid.startsWith("kakao:")) {
                            _this.userLoginProvider = "kakao";
                            _this.userLoginProviderDisplay = "카카오 로그인";
                        } else {
                            _this.userLoginProvider = "none";
                            _this.userLoginProviderDisplay = "";
                        }
                        //
                        // userEmail
                        if (user.email.startsWith("kakao-")) {
                            _this.userEmail = user.email.slice(6);
                        } else if (user.email.startsWith("kakaono-")) {
                            _this.userEmail = "";
                        } else {
                            _this.userEmail = user.email;
                        }

                        //
                        // userDisplayName
                        if (user.displayName == "") {
                            _this.userDisplayName = "안녕하세요";
                        } else {
                            _this.userDisplayName = user.displayName;
                        }

                        //
                        // userThumbnailURL


                        if (user.photoURL == null || user.photoURL == undefined || user.photoURL == "") {
                            _this.userThumbnailURL = _this.userThumbnailDefailtURL;
                        } else {
                            _this.userThumbnailURL = user.photoURL;
                        }

                    }


                    _this.loginIng = false;
                    _this.googleLoginIng = false;
                } else {
                    if (!isLoginIng) { // 로그아웃 상태

                        console.log("[LoginCard.vue][getUserInfo][auth().onAuthStateChanged] LogOut Status");
                        console.log("[LoginCard.vue][getUserInfo]  NON");
                        _this.userLogin = false;
                        _this.userLoginProvider = "";
                        _this.userLoginProviderDisplay = "";
                        _this.userDisplayName = "";
                        _this.userEmail = "";
                        _this.userThumbnailURL = "";
                        _this.loginIng = false;
                        _this.googleLoginIng = false;
                    } else { // 로그아웃 => 로그인 진행상태
                        console.log("[LoginCard.vue][getUserInfo][auth().onAuthStateChanged] LogOut =ing=> LogIn Status");
                        _this.loginIng = true;
                    }
                }
            });
        },

        logOutAccount() {
            firebase.auth().signOut().then(function() {
                console.log("[LoginCard.vue][signOut] Success LogOut");
            }).catch(function(error) {
                console.log("[LoginCard.vue][signOut] Failure to LogOut. error:", error);
            });
            this.userLogin = false;
            this.userLoginProvider = "";
            this.userDisplayName = "";
            this.userEmail = "";
        },

        leaveAccout() {
            // 1. LogOut
            this.logOutAccount();

            // 2. 계정 삭제
            firebase.auth().currentUser.delete().then(function() {
                console.log("[LoginCard.vue][signOut] Success Remove Account");
            }).catch(function(error) {
                console.log("[LoginCard.vue][signOut] Failure to Remove Account. error:", error);
            });
            alert("탈퇴되었습니다!");
        },


        signInWithGoogle() {
            const _this = this;
            this.loginIng = true;
            this.googleLoginIng = true;
            const provider = new firebase.auth.GoogleAuthProvider();
            // 추가적인 권한이 있을 경우에는 아래와 같이 추가합니다.
            // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
            // 로그인시 보여줄 언어를 지정합니다.
            // firebase.auth().languageCode = 'pt';
            // To apply the default browser preference instead of explicitly setting it.
            // firebase.auth().useDeviceLanguage();

            // 로그인 아이디의 기본값을 지정합니다. 지정하지 않아도 됩니다.
            provider.setCustomParameters({
                "login_hint": "user@example.com"
            });
            // 로그인 팝업창을 띄웁니다.
            firebase.auth().signInWithPopup(provider).then(function(result) {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const token = result.credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                // ...
                firebase.analytics().logEvent("login_success", {
                    UserID: user,
                    Provider: "google"
                });
                console.log("[LoginCard.vue][signInWithGoogle] Success Login! USER:", user, "Token:", token);
                _this.googleLoginIng = false;
                _this.getUserInfo();
            }).catch(function(error) {
                console.log("[LoginCard.vue][signInWithGoogle] Failure Login! error:", error);
                firebase.analytics().logEvent("login_failure", {
                    Provider: "google",
                    ErrorMessage: error
                });
            });

        },

        // todo Kakao 로그인시 화면 전환안일어나게
        signInWithKakao() {

            this.loginIng = true;

            const protocol = location.protocol;
            const hostName = location.hostname;
            const port = location.port;

            let url = protocol + "//" + hostName + (port ? ":" + port : "") + "/account";
            console.log("[LoginCard.vue][signInWithKakao] redirectUri:", url);

            const params = {
                redirectUri: url
            };
            window.Kakao.Auth.authorize(params);
        }

    },


    mounted() {
        const _this = this;
        console.log("[LoginCard.vue][mounted]this.$route.query.code: ", this.$route.query.code);
        let kakaoAuthCode = this.$route.query.code;

        if (kakaoAuthCode) {
            console.log("[LoginCard.vue][mounted] kakaoAuthCode: ", kakaoAuthCode);
            this.loginIng = true;

            this.token = kakaoAuthCode;

            const hostName = location.hostname;
            let originHost = "";
            if (hostName == "localhost") {
                originHost = "localhost";
            } else if (hostName == "malgogo.ai") {
                originHost = "malgogo";
            }

            console.log("[LoginCard.vue] originHost: ", originHost);

            // 카카오 로그인 토큰을 파이어베이스 함수에 전달합니다.
            var kakaoAuth = firebase.functions().httpsCallable("kakaotoken");


            kakaoAuth({ code: kakaoAuthCode, originhost: originHost }).then(function(result) {
                console.log("[LoginCard.vue][functions-kakaotoken][then] result", result);

                // Read result of the Cloud Function.
                var kakaoToken = result.data.kakao_token;
                var fireToken = result.data.firebase_token;

                console.log("[LoginCard.vue][functions-kakaotoken][then] kakaoToken: ", kakaoToken);
                console.log("[LoginCard.vue][functions-kakaotoken][then] firebaseToken: ", fireToken);

                // 토근이 정상적으로 처리될 경우 로그인 처리합니다.
                firebase.auth().signInWithCustomToken(fireToken)
                    .then(function(result) {
                        console.log("[LoginCard.vue][firebase.auth().signInWithCustomToken][then] result: ", result);
                        _this.token = kakaoToken;
                        window.Kakao.Auth.setAccessToken(kakaoToken);
                        if (result.additionalUserInfo.isNewUser) {
                            console.log("[LoginCard.vue][firebase.auth().signInWithCustomToken][then] 신규사용자");
                            _this.$router.push({ name: "Account" });   // welcome
                        } else {
                            console.log("[LoginCard.vue][firebase.auth().signInWithCustomToken][then] 기존사용자");
                            _this.$router.push({ name: "Account" });
                        }
                    })
                    .catch(function(error) {
                        // Handle Errors here.
                        var errorCode = error.code;
                        var errorMessage = error.message;
                        console.log("[LoginCard.vue][firebase.auth().signInWithCustomToken][catch] error: ", errorCode, errorMessage);
                        // console.log(error);
                        // alert("토큰이 정상적이지 않습니다. 만료된 토큰이거나 이미 사용된 토큰입니다.");
                        alert("로그인 실패했습니다.\n( CODE: L006 )");
                        // _this.$router.replace({ name: "Account" });
                        _this.loginIng = false;
                        return;
                    });
            }).catch(function(error) {
                // Getting the Error details.
                console.log("[LoginCard.vue][functions-kakaotoken][catch] error: ", error.code, error.message, error.details);
                console.log("[LoginCard.vue][functions-kakaotoken][catch] error-body: ", error.body);

                // alert("정상적이지 않은 접근입니다. 만료된 데이터이거나 이미 사용된 데이터입니다.");
                alert("로그인 실패했습니다.\n( CODE: L007 )");
                // _this.$router.replace({ name: "Account" });
                _this.loginIng = false;
                return;
            });
        }

        this.getUserInfo();
        console.log("[LoginCard.vue][created] UserLogin:", this.userLogin);
        console.log("[LoginCard.vue][created] UserLoginProvider:", this.userLoginProvider);
        console.log("[LoginCard.vue][created] UserDisplayName:", this.userDisplayName);
        console.log("[LoginCard.vue][created] UserEmail:", this.userEmail);
    },

    created() {

    },
    data() {
        return {
            loginIng: true,
            googleLoginIng: false,
            userLogin: false,
            userLoginProvider: "",
            userLoginProviderDisplay: "",
            userDisplayName: "",
            userEmail: "",
            userThumbnailURL: "",
            userThumbnailDefailtURL: "https://firebasestorage.googleapis.com/v0/b/gopro-5362b.appspot.com/o/128x128.png?alt=media&token=acd823db-7085-41de-aa9b-c2180ad0ebf3",
            hasEmail: true,
            token: ""

        };
    }
};
</script>
<style scoped></style>>
