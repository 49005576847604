<template>
    <v-container>
        <v-card elevation="0" tile outlined class="mt-3">
            <!--            <div class="text-h4 grey&#45;&#45;text text&#45;&#45;darken-2 ma-0 pa-0 text-center" > 검색</div>-->
            <v-row class="pa-0 ma-0">
                <v-col cols="12" class="pa-0 ma-0 mt-3 mb-0">
                    <v-text-field
                        label="경주마명 검색 ( 2019.01 ~ 현재 )"
                        class="mx-10 mt-5"
                        clearable
                        type="search"
                        autofocus
                        outlined
                        v-model="searchWord"
                    ></v-text-field>
                </v-col>

                <v-col v-if="computedStartedSearchAction" cols="12" class="pa-0 pt-0 mx-0 mt-0 mb-10">
                    <v-card
                        class="mx-10 mt-0"
                        elevation="0"
                    >
                        <v-sheet class="text-center">
                            <v-list v-if="computedExistSearchResult" dense mt-0>
                                <v-list-item
                                    v-for="(item,index) in searchResult"
                                    :key="index"
                                >
                                    <v-list-item-content class="text-center ma-0 pa-0">
                                        <v-list-item-title @click="openHorseDetailCardFromSearch(item)">

                                            <v-row>

                                                <v-col cols="6" class="text-body-1 text-center">
                                                    {{ item["Name"] }}
                                                </v-col>
                                                <v-col cols="6" class="text-caption text-left">
                                                    ({{ getLocationString(item["Meet"]) }} {{ getHorseIdString(item["HID"]) }} {{ getActiveString(item["Active"]) }})
                                                </v-col>
                                            </v-row>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <div v-else class="text-subtitle-1">


                                <v-row justify="center mb-3">
                                    <v-img
                                        max-height="64"
                                        max-width="64"
                                        class="text-center"
                                        src="../assets/logo_128x128.png"
                                        alt="경마-말고고-경마분석-경마문화-서울경마-제주경마-부경경마-문자-검색결과-없음"
                                    ></v-img>
                                </v-row>
                                <v-card-text
                                    class="text-center text-h6 blue-grey--text font-weight-regular ma-0 pa-0">
                                    '{{ searchWord }}' <br> 검색 결과가 없습니다 :D
                                </v-card-text>

                            </div>
                        </v-sheet>
                    </v-card>
                </v-col>
                <v-row v-else justify="center mb-3">
                    <v-img
                        max-height="96"
                        max-width="96"
                        class="text-center"
                        src="../assets/logo_128x128.png"
                        alt="경마-말고고-경마분석-경마문화-서울경마-제주경마-부경경마-문자-기본-상태"
                    ></v-img>
                    <v-card-text
                        class="text-center  grey--text text--darken-3 font-weight-regular  pt-3 pb-5 px-0">
                        경마 분석 원하는 경주마를 검색하세요 :D
                    </v-card-text>
                </v-row>
                <v-col cols="12" class="pa-0 mx-0 mt-0 mb-5">
                    <v-card
                        class="mx-10 mt-0"
                        elevation="0"
                        outlined
                    >
                        <v-card-subtitle class="text-subtitle-1 text-center mb-0">최근검색어</v-card-subtitle>
                        <v-list dense mt-0 v-if="this.dataShowRecentSearch">
                            <v-list-item
                                v-for="(item,index) in this.dataRecentSearch" :key="index">
                                <v-list-item-content class="text-center ma-0 pa-0">
                                    <v-list-item-title v-text="item.Name"
                                                       @click="openHorseDetailCardFromSearch(item)">
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-icon>
                                    <v-icon small @click="deleteRecentSearch(item)"> mdi-close</v-icon>
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list>
                        <div v-else class="text-body-2 text-center mb-5"> 최근 검색어가 없습니다.</div>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>


import router from "@/router";
import firebase from "firebase";

export default {
    name: "Search",
    components: {},

    created() {

        // 만약 Home 진입이 없었으면, 강제로 Home으로 Route 를 Raplace 한다.
        // if (!this.$store.getters["masterStore/GET_ENTRY_HOME"]) {
        //     router.replace({ name: "Home" });
        // }


        // localStorage.clear();
        this.dataRecentSearch = this.computedRecentSearch;
        if (this.dataRecentSearch.length > 0) {
            this.dataShowRecentSearch = true;
        } else {
            this.dataShowRecentSearch = false;
        }
    },

    beforeDestroy() {
    },

    computed: {
        computedExistSearchResult() {
            if (this.searchResult.length != 0) {
                return true;
            } else {
                return false;
            }
        },

        computedStartedSearchAction() {
            if (this.searchWord == null) {
                return false;
            } else if (this.searchWord.length >= 2) {
                return true;
            } else {
                return false;
            }
        },

        computedRecentSearch() {

            let localStorageRecentSearch = JSON.parse(localStorage.getItem("RecentSearch"));

            console.log("[HZZ][Search.vue][computedRecents] RecentSearch: ", localStorageRecentSearch);

            if ((localStorageRecentSearch == null) || (localStorageRecentSearch == undefined)) {
                localStorageRecentSearch = [];
            }

            return localStorageRecentSearch;
        }
    },

    watch: {
        searchWord: function(val) {

            console.log("[HS][Search.vue]search: ", val, val.length, this.getConstantVowel(val));

            // case-3
            // length : 2 이상부터
            // 앞글자의 자음을 분리해서 searchStore, 없으면 fireStore에 요청
            // 요청된 결과를 contain 으로 판단하여 리스팅한다.
            if (val.length >= 2) {
                let initialWord = this.getConstantVowel(val)["f"];
                if (initialWord != undefined) {
                    console.log("[HS][Search.vue] initialWord: ", initialWord);

                    let prevStoreSearchHorse = this.$store.getters["searchStore/GET_SEARCH_HORSE"];
                    if (initialWord in prevStoreSearchHorse) {
                        console.log("[HS][Search.vue]search: 0 ", this.searchResult);
                        const currTS = new Date().getTime();
                        const thresholdTS = 1000; // TODO ts CHeck
                        if (currTS - prevStoreSearchHorse[initialWord]["ts"] > thresholdTS) {
                            this.loadHorseListForSearchFromFireStore(initialWord, val);
                            console.log("[HS][Search.vue]search: 2 ", this.searchResult);
                        } else {
                            console.log("[HS][Search.vue]search: 1 ", this.searchResult);
                        }
                    } else {
                        console.log("[HS][Search.vue]search: 3 ");
                        // this.searchResult = [];
                        this.loadHorseListForSearchFromFireStore(initialWord, val);
                        // console.log("[Search.vue]search: 3 ", this.getSampleFireStoreSearchHorse(initialWord));
                        // let sampleSearch = this.getSampleFireStoreSearchHorse(initialWord);
                        // sampleSearch["key"] = initialWord;
                        // this.$store.commit("searchStore/MUT_SEARCH_HORSE_PUSH_UNIQUE", sampleSearch);
                    }

                    this.searchResult = this.$store.getters["searchStore/GET_SEARCH_HORSE"][initialWord]["items"].filter(ff => ff["Name"].includes(val));
                    console.log("[HS][Search.vue]search: 5 ", this.searchResult);
                } else {
                    console.log("[HS][Search.vue] initialWord: undefined");
                    this.searchResult = [];
                }
            }
        }
    },

    methods: {


        loadHorseListForSearchFromFireStore(key, val) {
            const _this = this;
            firebase.firestore().collection("HorseList").doc(key).get().then(function(doc) {
                // const key = doc.data().InitialWord; // string "ㅅ"
                // const size = doc.data().Size; // int
                // const tid = doc.data().TID; // string "20210129.00"
                // const items = doc.data().Items;
                const search = {
                    key: doc.data().InitialWord,    // string "ㅅ"
                    size: doc.data().Size,          // int
                    tid: doc.data().TID,            // string "20210129.00"
                    items: doc.data().Items
                };
                _this.$store.commit("searchStore/MUT_SEARCH_HORSE_PUSH_UNIQUE", search);
                console.log("[HS][Search.vue] _this.$store.getters[searchStore/GET_SEARCH_HORSE][key][items] : ", _this.$store.getters["searchStore/GET_SEARCH_HORSE"][key]["items"]);
                _this.searchResult = _this.$store.getters["searchStore/GET_SEARCH_HORSE"][key]["items"].filter(ff => ff["Name"].includes(val));
            }).catch(function(error) {
                console.log(("HELLO : ERROR Getting cached document: ", error));
            });
        },

        getHorseIdString(hid) {
            return hid.split("-")[3];
        },

        getActiveString(active) {
            if (active) {
                return "";
            } else {
                return "퇴역";
            }
        },

        getLocationString(meet) {
            let loc = "";
            switch (meet) {
                case 1:
                    loc = "서울";
                    break;
                case 2:
                    loc = "제주";
                    break;
                case 3:
                    loc = "부경";
                    break;
            }

            return loc;
        },

        getConstantVowel(kor) {
            const f = ["ㄱ", "ㄲ", "ㄴ", "ㄷ", "ㄸ", "ㄹ", "ㅁ",
                "ㅂ", "ㅃ", "ㅅ", "ㅆ", "ㅇ", "ㅈ", "ㅉ",
                "ㅊ", "ㅋ", "ㅌ", "ㅍ", "ㅎ"];
            const s = ["ㅏ", "ㅐ", "ㅑ", "ㅒ", "ㅓ", "ㅔ", "ㅕ",
                "ㅖ", "ㅗ", "ㅘ", "ㅙ", "ㅚ", "ㅛ", "ㅜ",
                "ㅝ", "ㅞ", "ㅟ", "ㅠ", "ㅡ", "ㅢ", "ㅣ"];
            const t = ["", "ㄱ", "ㄲ", "ㄳ", "ㄴ", "ㄵ", "ㄶ",
                "ㄷ", "ㄹ", "ㄺ", "ㄻ", "ㄼ", "ㄽ", "ㄾ",
                "ㄿ", "ㅀ", "ㅁ", "ㅂ", "ㅄ", "ㅅ", "ㅆ",
                "ㅇ", "ㅈ", "ㅊ", "ㅋ", "ㅌ", "ㅍ", "ㅎ"];

            const ga = 44032;
            let uni = kor.charCodeAt(0);

            uni = uni - ga;

            let fn = parseInt(uni / 588);
            let sn = parseInt((uni - (fn * 588)) / 28);
            let tn = parseInt(uni % 28);

            return {
                f: f[fn],
                s: s[sn],
                t: t[tn]
            };
        },

        analyticsLogEvent(target, hID){
            let userID = this.$store.getters["userAccountInfoStore/GET_USER_ACCOUNT_INFO"].uid;
            if (!(userID == undefined || userID == null || userID == "")) {  // 로그인 상태
                userID = "none";
            }
            firebase.analytics().logEvent(target,{
                HID : hID,
                UserID: userID,
            })
        },

        openHorseDetailCardFromSearch(item) {
            console.log("[HS][Search.vue][methods-openHorseDetailCardFromSearch]", item);
            this.analyticsLogEvent("click_search_open_horseDetail",item["HID"]);

            let recentSearch = JSON.parse(localStorage.getItem("RecentSearch"));
            if (recentSearch == null) {
                console.log("[HS][Search.vue][methods-openHorseDetailCardFromSearch] recentSearch == null");
                recentSearch = [item];
            } else {
                console.log("[HS][Search.vue][methods-openHorseDetailCardFromSearch] recentSearch != null : ", recentSearch);
                recentSearch.unshift(item);
                let tmpIndex = 0;
                recentSearch = recentSearch.filter(ft => {
                    let filterResult = true;
                    if (tmpIndex != 0) {
                        if (ft["HID"] == item["HID"]) {
                            filterResult = false;
                        }
                    }
                    tmpIndex = tmpIndex + 1;
                    return filterResult;
                });
                recentSearch = recentSearch.slice(0, 5);
            }

            localStorage.setItem("RecentSearch", JSON.stringify(recentSearch));
            console.log("[HS][Search.vue]  JSON.parse(localStorage.getItem(RecentSearch)): ",  JSON.parse(localStorage.getItem("RecentSearch")));


            const hidSplit = item["HID"].split("-");
            const horseName = hidSplit[1];
            const horseMeet = parseInt(hidSplit[2]);
            const horseID = hidSplit[3];
            let queueItem = {
                "target": "horse",
                "id": item["HID"],
                "horse": {
                    "hid" : horseID,
                    "meet" : horseMeet,
                    "name" : horseName
                } ,
                "favorite": false
            };

            console.log("[HZZ] item - SC : ", item)

            this.$store.getters["favoriteStore/GET_FAVORITE"]["items"].some(fff => {
                if (fff["id"] == item["HID"]) {
                    queueItem["favorite"] = true;
                }
                return fff["id"] == item["HID"];
            });

            let renewDetailDisplayItems = this.$store.getters["detailCardStore/GET_DETAIL_DISPLAY"]["items"];
            renewDetailDisplayItems = renewDetailDisplayItems.filter(ff => ff["id"] != item["HID"]);
            renewDetailDisplayItems = [queueItem].concat(renewDetailDisplayItems);

            const renewDetailDisplay = {
                ts: new Date().getTime(),
                items: renewDetailDisplayItems
            };
            this.$store.commit("detailCardStore/MUT_DETAIL_DISPLAY_OVERWRITE", renewDetailDisplay);
            localStorage.setItem("DetailDisplay", JSON.stringify(this.$store.getters["detailCardStore/GET_DETAIL_DISPLAY"]));
            this.$store.commit("detailCardStore/MUT_DETAIL_SCROLL_ID_OVERWRITE", item["HID"]);
            router.push({ name: "Home" });
        },


        deleteRecentSearch(item) {
            this.dataRecentSearch = this.dataRecentSearch.filter(fff => fff["HID"] != item["HID"]);
            if (this.dataRecentSearch.length > 0) {
                this.dataShowRecentSearch = true;
            } else {
                this.dataShowRecentSearch = false;
            }
            localStorage.setItem("RecentSearch", JSON.stringify(this.dataRecentSearch));
            console.log("[Search.vue][method-deleteRecentSearch] renew LocalStorage:RecentSearch :", JSON.parse(localStorage.getItem("RecentSearch")));
        }
    },
    data() {
        return {
            dataShowRecentSearch: false,
            dataRecentSearch: [],
            searchWord: "",
            searchResult: []
        };
    }
};
</script>
<style scoped></style>
