import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import masterStore from "@/store/modules/masterStore.js";
import favoriteStore from "@/store/modules/favoriteStore.js";
import detailCardStore from "@/store/modules/detailCardStore.js";
import userAccountInfoStore from "@/store/modules/userAccountInfoStore.js";
import sampleFireStore from "@/store/modules/sampleFireStore.js";
import trendTop10Store from "@/store/modules/trendTop10Store.js";
import thisWeekLineUpStore from "@/store/modules/thisWeekLineUpStore";
import searchStore from "@/store/modules/searchStore.js";


export default new Vuex.Store({
    modules: {
        masterStore: masterStore,
        favoriteStore: favoriteStore,
        detailCardStore: detailCardStore,
        userAccountInfoStore: userAccountInfoStore,
        sampleFireStore: sampleFireStore,
        trendTop10Store: trendTop10Store,
        thisWeekLineUpStore: thisWeekLineUpStore,
        searchStore: searchStore
    }
});
