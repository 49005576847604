const masterStore = {
    namespaced: true,
    state: {
        entryHome: false
    },
    getters: {
        GET_ENTRY_HOME: state => state.entryHome
    },
    mutations: {
        MUT_ENTRY_HOME_TRUE: (state) => {
            state.entryHome = true
        }
    },
    actions: {}
};

export default masterStore;