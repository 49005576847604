<template>
    <div id="mgg-cards" class="mx-0 px-0 overflow-auto">
        <this-week-line-up-card></this-week-line-up-card>
        <!--<trend-top10-card></trend-top10-card>-->
        <detail-card
            v-for="(item, index) in computedResizeDetailDisplay['items']"
            :key="index" :card_title="item"></detail-card>
    </div>
</template>

<script>

import ThisWeekLineUpCard from "@/components/ThisWeekLineUpCard";
import DetailCard from "@/components/DetailCard.vue";

export default {
    name: "Home",
    components: {
        "this-week-line-up-card": ThisWeekLineUpCard,
        "detail-card": DetailCard
        // "trend-top10-card": TrendTop10Card
    },

    beforeMount() {
        console.log("[71][Home.vue][beforeMounted] IN");
    },

    mounted() {
        console.log("[71][Home.vue][mounted] IN");
        this.loginCheckAndAlertAndResizeDisplay();

        const scrollID = this.$store.getters["detailCardStore/GET_DETAIL_SCROLL_ID"];
        this.$store.commit("detailCardStore/MUT_DETAIL_SCROLL_ID_OVERWRITE", "");
        console.log("[71][Home.vue][mounted] ScrollID:", scrollID);
        if (scrollID != "") {
            this.$vuetify.goTo(document.querySelector("#" + scrollID), { duration: 500 }).then(function() {
                console.log("[z1]MOUNTED SCROLL THEN");
            }).catch(function(err) {
                console.log("[z1]MOUNTED SCROLL CATCH: ", err);
            });
        }
    },

    beforeUpdate() {
        console.log("[71][Home.vue][beforeUpdate] IN");
        this.loginCheckAndAlertAndResizeDisplay();
    },

    updated() {
        console.log("[71][Home.vue][Update] IN");
        const scrollID = this.$store.getters["detailCardStore/GET_DETAIL_SCROLL_ID"];
        this.$store.commit("detailCardStore/MUT_DETAIL_SCROLL_ID_OVERWRITE", "");
        console.log("[71][Home.vue][Updated] ScrollID:", scrollID);
        if (scrollID != undefined && scrollID != "") {
            this.$vuetify.goTo(document.querySelector("#" + scrollID), { duration: 500 }).then(function() {
                console.log("[z1]Updated SCROLL THEN");
            }).catch(function(err) {
                console.log("[z1]Updated SCROLL CATCH: ", err);
            });
        }
    },

    created() {
        // TODO 1. detailCardStore-horseDetailDisplay 의 ts 여부 확인

        console.log("[71][Home.vue][created] IN");

        let tmpFavorite = this.$store.getters["favoriteStore/GET_FAVORITE"];
        if ("ts" in tmpFavorite) {
            tmpFavorite["items"] = tmpFavorite["items"].filter(i => {
                return i["favorite"];
            });
        } else {
            tmpFavorite = this.firestoreFavoriteItemsOfHome;
        }
        console.log("[71][Home.vue][created] HOME-created FAVORITE:", tmpFavorite);
        this.$store.commit("favoriteStore/MUT_FAVORITE_OVERWRITE", tmpFavorite);
        console.log("[71][Home.vue][created]", this.$store.getters["favoriteStore/GET_FAVORITE"]);
        this.$store.commit("masterStore/MUT_ENTRY_HOME_TRUE");

    },


    beforeDestroy() {
        // TODO Update DetailDisplay to FireStore
        console.log("[71][Home.vue][beforeDestroy] Update DetailDisplay to FireStore. But Not Yet.");

        // TODO Update Favorite to FireStore
        console.log("[71][Home.vue][beforeDestroy] Update Favorite to FireStore. But Not Yet.");
        this.$store.commit("detailCardStore/MUT_DETAIL_SCROLL_ID_OVERWRITE", "");
    },

    computed: {
        computedResizeDetailDisplay() {
            console.log("[71][Home.vue][computed][resizeDetailDisplay] In ");
            return this.$store.getters["detailCardStore/GET_DETAIL_DISPLAY"];
        }
    },

    watch: {},

    methods: {
        loginCheckAndAlertAndResizeDisplay() {
            console.log("[71][Home.vue][loginCheckAndAlertAndResizeDisplay] In");

            const _this = this;

            if (!this.$store.getters["userAccountInfoStore/GET_USER_ACCOUNT_INFO"].login) { // 로그아웃 상태
                console.log("[71][Home.vue][loginCheckAndAlertAndResizeDisplay] Login: ", false);
                let lsDetailDisplay = JSON.parse(localStorage.getItem("DetailDisplay"));
                if (lsDetailDisplay == null || lsDetailDisplay == undefined) {
                    lsDetailDisplay = {
                        "ts": new Date().getTime(),
                        "items": []
                    };
                }
                if (lsDetailDisplay["items"].length > 2) {
                    console.log("[71][Home.vue][loginCheckAndAlertAndResizeDisplay] Login: FALSE and Too Many Card");
                    alert("말고고카드는..\n\n" +
                        "비로그인시 최대2장~\n" +
                        "로그인시 제한없이 여러장~\n\n" +
                        "동시 오픈 가능해요 :D");
                    lsDetailDisplay["items"] = lsDetailDisplay["items"].slice(0, 2);
                    lsDetailDisplay["ts"] = new Date().getTime();

                    this.$store.commit("detailCardStore/MUT_DETAIL_DISPLAY_OVERWRITE", lsDetailDisplay);
                    localStorage.setItem("DetailDisplay", JSON.stringify(lsDetailDisplay));

                    console.log("[z1][Home.vue][loginCheckAndAlertAndResizeDisplay] FIFO : ", _this.$store.getters["detailCardStore/GET_DETAIL_DISPLAY"], JSON.parse(localStorage.getItem("DetailDisplay")));
                }
            }
            console.log("[71][Home.vue][loginCheckAndAlertAndResizeDisplay] Out");
        }
    },
    data() {
        return {};
    }
};
</script>


