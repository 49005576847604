/* eslint-disable */
<template>
  <v-footer
      dark
      padless
  >
    <v-card
        flat
        tile
        width="100%"
        class="white  grey--text text--darken-3 text-center"
    >
      <v-card-text>
        <v-btn
            v-for="icon in icons"
            :key="icon"
            class="mx-4 grey--text text--darken-3"
            icon
        >
          <v-icon size="24px" @click="clickSocial(icon)">
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <!--            <v-card-text class=" grey&#45;&#45;text text&#45;&#45;darken-3  pt-0">-->
      <!--                Hello-->
      <!--            </v-card-text>-->

      <v-divider class="grey lighten-2"></v-divider>

      <v-card-text class=" grey--text text--darken-3 ">
        {{ new Date().getFullYear() }} — <strong>malgogo.ai</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>

export default {
  name: "AppBar",

  data() {
    return {
      active: "",
      group: "",
      icons: [
        "mdi-youtube",
        // "mdi-twitter",
        "mdi-email"
      ]
    };
  },

  computed: {},

  watch: {},

  created() {
  },

  beforeMount() {
  },

  beforeUpdate() {
  },

  updated() {
  },

  mounted() {
  },

  methods: {

    clickSocial(target) {
      switch (target) {
        case "mdi-youtube":
          window.open("https://www.youtube.com/channel/UCCsdYMMhlMrmHIoPT81uhBw", "_blank");
          break;
        case "mdi-twitter":
          window.open("https://twitter.com/malgogo_ai", "_blank");
          break;
        case "mdi-email":
          window.location.href = "mailto:nerd@malgogo.ai";
          break;
      }
    }
  }
};
</script>
