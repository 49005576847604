const sampleFireStore = {
    namespaced: true,
    state: {
        sampleFireStoreTrendTop10: {
            ts: new Date().getTime(),
            items: [
                {
                    id: "horse-샴로커-1-036488",
                    target: "horse",
                    horse: {
                        name: "샴로커",
                        meet: 1,
                        hid: "036488"
                    },
                    race: {}
                },
                {
                    id: "horse-샴로2-1-036482",
                    target: "horse",
                    horse: {
                        name: "샴로2",
                        meet: 1,
                        hid: "036482"
                    },
                    race: {}
                },
                {
                    id: "horse-샴로3-1-036483",
                    target: "horse",
                    horse: {
                        name: "샴로3",
                        meet: 1,
                        hid: "036483"
                    },
                    race: {}
                },
                {
                    id: "horse-샴로4-1-036484",
                    target: "horse",
                    horse: {
                        name: "샴로4",
                        meet: 1,
                        hid: "036484"
                    },
                    race: {}
                },
                {
                    id: "horse-샴로5-1-036485",
                    target: "horse",
                    horse: {
                        name: "샴로5",
                        meet: 1,
                        hid: "036485"
                    },
                    race: {}
                },
                {
                    id: "horse-샴로6-1-036486",
                    target: "horse",
                    horse: {
                        name: "샴로6",
                        meet: 1,
                        hid: "036486"
                    },
                    race: {}
                },
                {
                    id: "horse-샴로7-1-036487",
                    target: "horse",
                    horse: {
                        name: "샴로7",
                        meet: 1,
                        hid: "036487"
                    },
                    race: {}
                },
                {
                    id: "horse-샴로9-1-036489",
                    target: "horse",
                    horse: {
                        name: "샴로9",
                        meet: 1,
                        hid: "036489"
                    },
                    race: {}
                },
                {
                    id: "horse-샴로0-1-036480",
                    target: "horse",
                    horse: {
                        name: "샴로0",
                        meet: 1,
                        hid: "036480"
                    },
                    race: {}
                },
                {
                    id: "horse-샴로A-1-03648A",
                    target: "horse",
                    horse: {
                        name: "샴로A",
                        meet: 1,
                        hid: "03648A"
                    },
                    race: {}
                },
                {
                    id: "horse-샴로B-1-03648B",
                    target: "horse",
                    horse: {
                        name: "샴로B",
                        meet: 1,
                        hid: "03648B"
                    },
                    race: {}
                },
                {
                    id: "horse-샴로C-1-03648C",
                    target: "horse",
                    horse: {
                        name: "샴로C",
                        meet: 1,
                        hid: "03648C"
                    },
                    race: {}
                },
                {
                    id: "horse-샴로D-1-03648D",
                    target: "horse",
                    horse: {
                        name: "샴로D",
                        meet: 1,
                        hid: "03648D"
                    },
                    race: {}
                },
                {
                    id: "horse-샴로E-1-03648E",
                    target: "horse",
                    horse: {
                        name: "샴로E",
                        meet: 1,
                        hid: "03648E"
                    },
                    race: {}
                },
                {
                    id: "horse-샴로F-1-03648F",
                    target: "horse",
                    horse: {
                        name: "샴로F",
                        meet: 1,
                        hid: "03648F"
                    },
                    race: {}
                }
            ]

        }
    },

    getters: {
        GET_SAMPLE_FIRESTORE_TREND_TOP10: state => state.sampleFireStoreTrendTop10,
    },
    mutations: {
        MUT_SAMPLE_FIRESTORE_TREND_TOP10_SHUFFLE: state => {
            console.log("[zoo]GET_SAMPLE_FIRESTORE_TREND_TOP10 1");
            let sampleTrendTop10 = state.sampleFireStoreTrendTop10["items"];
            console.log("[zoo]GET_SAMPLE_FIRESTORE_TREND_TOP10 2");
            let j, x, i;
            for (i = sampleTrendTop10.length; i; i -= 1) {
                j = Math.floor(Math.random() * i);
                x = sampleTrendTop10[i - 1];
                sampleTrendTop10[i - 1] = sampleTrendTop10[j];
                sampleTrendTop10[j] = x;
            }
            console.log("[zoo]GET_SAMPLE_FIRESTORE_TREND_TOP10 3");
            state.sampleFireStoreTrendTop10 = {
                "ts": new Date().getTime(),
                "items": sampleTrendTop10.slice(0, 10)
            };
        }
    },
    actions: {}
};

export default sampleFireStore;