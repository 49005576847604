const favoriteStore = {
    namespaced: true,
    state: {
        favorite: {
            userEmail: "",
            userDisplayName: "",
            userProvider: "",
            ts: 0,
            items: []
        }
    },
    getters: {
        GET_FAVORITE: state => state.favorite
    },
    mutations: {
        MUT_FAVORITE_OVERWRITE: (state, payload) => {
            state.favorite = payload;
        },

        MUT_FAVORITE_PUSH_UNIQUE: (state, addFavorite) => {

            console.log("[MUT_FAVORITE_PUSH_UNIQUE] Added FAVORITE:", addFavorite);
            console.log("[MUT_FAVORITE_PUSH_UNIQUE] Added FAVORITE:", state.favorite);

            let tmpItems = state.favorite["items"];
            tmpItems = tmpItems.filter(fff => {
                return addFavorite["id"] != fff["id"];
            });
            tmpItems.push(addFavorite);

            // state.favorite['items'] = state.favorite['items'].filter(fff => {
            //     return addFavorite["id"] != fff["id"];
            // });
            // state.favorite['items'].push(addFavorite);

            state.favorite["ts"] = new Date().getTime();
            state.favorite["items"] = tmpItems;


            console.log("[MUT_FAVORITE_PUSH_UNIQUE] Added FAVORITE:", state.favorite);
        },

        MUT_FAVORITE_REMOVE_UNIQUE: (state, removeFavorite) => {
            console.log("[MUT_FAVORITE_REMOVE_UNIQUE] Removed FAVORITE:", removeFavorite);
            console.log("[MUT_FAVORITE_REMOVE_UNIQUE] Removed FAVORITE:", state.favorite);
            // state.favorite['items'] = state.favorite['items'].filter(fff => {
            //     return removeFavorite["id"] != fff["id"];
            // });

            let tmpItems = state.favorite["items"];
            tmpItems = tmpItems.filter(fff => {
                return removeFavorite["id"] != fff["id"];
            });
            state.favorite["ts"] = new Date().getTime();
            state.favorite["items"] = tmpItems;


            console.log("[MUT_FAVORITE_REMOVE_UNIQUE] Removed FAVORITE:", state.favorite);
        }

    },
    actions: {}
};

export default favoriteStore;