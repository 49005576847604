<template>
    <v-card elevation="0" tile outlined>
        <v-card-title class="headline">
            <v-icon>mdi-face-agent</v-icon>
            <v-spacer />
            말고고.ai ?
            <v-spacer />
        </v-card-title>
        <v-row justify="center my-3">
            <v-img
                max-height="96"
                max-width="96"
                class="text-center"
                src="../assets/logo_128x128.png"
                alt="경마-말고고-경마분석-경마문화-서울경마-제주경마-부경경마-문자-기본정보-알림"
            ></v-img>
        </v-row>
        <v-row>
            <v-col cols="1" />
            <v-col cols="10" class="py-3">
                <div class="px-5 text-body-1 text-center font-weight-light">

                    말고고.ai 는
                    <br>
                    1인이 개발하고 운영하는
                    <br>
                    경마 분석 서비스입니다.
                    <br><br>
                    이에 서비스품질 대응이
                    <br>
                    천천히 진행 될 수 있습니다.
                    <br><br>
                    하지만 정확하고 보기 쉬운
                    <br>
                    경마분석 정보 제공을 위해
                    <br>
                    노력하겠습니다.

                    <v-divider class="my-7 mx-5" />

                    머신러닝 분야 기술적용으로
                    <br>
                    경마분석 확장 및 향후 추천 기능도
                    <br>
                    지속적으로 개발 진행중입니다.

                    <v-divider class="my-7 mx-5" />

                    말고고.ai를 만든이는
                    <br>
                    단지 데이터 다루는 것을
                    <br>
                    좋아하는 사람입니다.
                    <br>
                    <br>
                    경마분석 관련하여
                    <br>
                    여러 문의사항과 광고문의 등
                    <br>
                    이메일 연락 부탁드립니다.
                    <br>
                    <br>
                    <a href="javascript:;" @click="copyClipboardAddress('email')" class="font-weight-regular">
                        {{ emailAddress }} </a>

                    <v-divider class="my-7 mx-5" />
                    말고고.ai를 통해
                    <br>
                    경마분석 정보가 도움이 되어
                    <br>
                    도움을 주고 싶으시다면,
                    <br>
                    암호화폐 통해 가능합니다.
                    <br>
                    말고고를 응원해 주시는
                    <br>
                    모든 분들께 감사합니다.
                    <br>
                    <br>

                    <v-row>
                        <v-col cols="2" />
                        <v-col cols="8" class="text-center my-1 py-0">
                            <v-btn
                                block
                                tile
                                elevation="0"
                                class="grey lighten-3"
                                @click="copyClipboardAddress('BTC')"
                            >
                                <v-icon left>
                                    mdi-bitcoin
                                </v-icon>
                                BTC 주소
                            </v-btn>
                        </v-col>
                        <v-col cols="2" />
                        <v-col cols="12" />
                        <v-col cols="12" />
                    </v-row>
                    <!-- <v-row>
                        <v-col cols="2" />
                        <v-col cols="8" class="text-center my-1 py-0">
                            <v-btn
                                block
                                tile
                                elevation="0"
                                class="grey lighten-3"
                                @click="copyClipboardAddress('ETH')"
                            >
                                <v-icon left>
                                    mdi-ethereum
                                </v-icon>
                                ETH 주소
                            </v-btn>
                        </v-col>
                        <v-col cols="2" />
                    </v-row> -->
                    <!--                    <v-row>-->
                    <!--                        <v-col cols="2" />-->
                    <!--                        <v-col cols="8" class="text-center my-1 py-0">-->
                    <!--                            <v-btn-->
                    <!--                                block-->
                    <!--                                tile-->
                    <!--                                elevation="0"-->
                    <!--                                class="grey lighten-3"-->
                    <!--                                @click="copyClipboardAddress('KLAY')"-->
                    <!--                            >-->

                    <!--                                <v-img-->
                    <!--                                    max-height="17"-->
                    <!--                                    max-width="17"-->
                    <!--                                    src="../assets/klay.png"-->
                    <!--                                ></v-img>-->
                    <!--                                ㅤKLAY 주소-->
                    <!--                            </v-btn>-->
                    <!--                        </v-col>-->
                    <!--                        <v-col cols="2" />-->
                    <!--                    </v-row>-->
                </div>
            </v-col>
            <v-col cols="1"></v-col>
        </v-row>

        <v-overlay class="text-center"
                   :value="showOverlayNotiCopyEmail">
            <v-row>
                <v-col cols="12">
                    <v-btn
                        class="white--text text-body-2 font-weight-regular"
                        color="grey darken-4"
                        x-large
                        @click="showOverlayNotiCopyEmail = false; overlayNotiCopyEmailMessage='';"
                    >
                        {{ overlayNotiCopyEmailMessage }}
                    </v-btn>
                </v-col>
                <v-col cols="4"></v-col>
                <v-col cols="4" class="align-content-center">
                    <qr_code v-if="this.isBTC" class="mt-5"  size="140" :text="this.BTC"></qr_code>
                    <!-- <v-img v-if="this.isBTC"
                        max-height="140"
                        max-width="140"
                        src="../assets/BTC_QR.jpg"
                           alt="경마-말고고-경마분석-경마문화-서울경마-제주경마-부경경마-기부-비트코인-주소"
                    ></v-img>
                    <v-img v-else-if="this.isETC"
                           max-height="140"
                           max-width="140"
                           src="../assets/ETH_QR.jpg"
                           alt="경마-말고고-경마분석-경마문화-서울경마-제주경마-부경경마-문자-기부-이더리움-주소"
                    ></v-img> -->
                </v-col>
                <v-col cols="4"></v-col>
            </v-row>
        </v-overlay>
    </v-card>
</template>
<script>


import firebase from "firebase";
import qr_code from 'vue-qrcode-component';

export default {

    name: "AboutCard",

    components: {qr_code},
    computed: {},

    created() {
    },


    data() {
        return {
            showOverlayNotiCopyEmail: false,
            overlayNotiCopyEmailMessage: "",
            overlayNotiCopyEmailMessage2: "",

            emailAddress: "nerd@malgogo.ai",

            isEmail : false,
            isBTC: false,
            // isETC: false,

            BTC: "12zBvBqD3UCA9yeQKCBey4JPzJZJnQRiV9",
            // ETH: "0x6ADC5872259336D2F64E983c76b7e3f096Bc708c",
            // KLAY: "0x7ae4F1e11cBD0aE58E3715ef1D7Ef3202B028A0B",

            // BTC_QR: "../assets/BTC_QR.jpg",
            // ETH_QR: "../assets/ETH_QR.jpg"
        };
    },
    methods: {
        analyticsLogEvent(target) {
            let userID = this.$store.getters["userAccountInfoStore/GET_USER_ACCOUNT_INFO"].uid;
            if (!(userID == undefined || userID == null || userID == "")) {  // 로그인 상태
                userID = "none";
            }
            firebase.analytics().logEvent(target, {
                UserID: userID
            });
        },

        copyClipboardAddress(target) {
            this.analyticsLogEvent("click_about_" + target);
            let dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            let messageTarget = "";
            switch (target) {
                case "email":
                    dummy.value = this.emailAddress;
                    messageTarget = "이메일주소";
                    this.isEmail = true;
                    this.isBTC = false;
                    // this.isETC = false;
                    break;
                case "BTC":
                    dummy.value = this.BTC;
                    messageTarget = "비트코인(BTC)주소";
                    this.overlayNotiCopyEmailMessage2 = this.BTC;
                    this.isEmail = false;
                    this.isBTC = true;
                    // this.isETC = false;
                    break;
                case "ETH":
                    dummy.value = this.ETH;
                    messageTarget = "이더리움(ETH)주소";
                    this.overlayNotiCopyEmailMessage2 = this.ETH;
                    this.isEmail = false;
                    this.isBTC = false;
                    // this.isETC = true;
                    break;
            }
            const successMSG = messageTarget + " 클립보드 복사됨 :D";
            const failureMSG = messageTarget + " 복사 못했어요 T.T";

            console.log("[AboutCard.vue][methods-copyClipboardEmailAddress] ", target, dummy.value);
            dummy.select();
            let successful = document.execCommand("copy");
            if (!successful) {
                console.log("[COPY-CLIPBOARD] 3 false");
                // From dummy.select();
                const oldContentEditable = dummy.contentEditable,
                    oldReadOnly = dummy.readOnly,
                    range = document.createRange();

                dummy.contentEditable = true;
                dummy.readOnly = false;
                range.selectNodeContents(dummy);

                const s = window.getSelection();
                s.removeAllRanges();
                s.addRange(range);

                dummy.setSelectionRange(0, 999999);

                dummy.contentEditable = oldContentEditable;
                dummy.readOnly = oldReadOnly;

                successful = document.execCommand("copy");
                // To successful = document.execCommand("copy");
            }
            let msg = successful ? successMSG : failureMSG;
            this.overlayNotiCopyEmailMessage = msg;
            document.body.removeChild(dummy);
            this.showOverlayNotiCopyEmail = true;
        }
    }
};
</script>
<style scoped></style>>
