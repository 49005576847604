<script>

import { Bar, mixins } from "vue-chartjs";

const { reactiveProp } = mixins;

export default {
    extends: Bar,
    mixins: [reactiveProp],
    data() {
        return {
            options: {
                tooltips: {
                    position:  'average',
                    mode: 'index',
                    intersect: false
                },
                scales: {
                    yAxes: [
                        {
                            gridLines: {
                                display: true
                            },
                            scaleLabel: {
                                display: true,
                                fontColor: "#111",
                                labelString: "레이팅",
                                fontSize: 15,
                                padding: 0
                            },
                            ticks: {
                                // suggestedMin: 350,
                                // suggestedMax: 140,
                                stepSize: 5
                                // suggestedMax: 80
                            }
                        }
                    ],
                    xAxes: [
                        {
                            stacked: true,
                            gridLines: {
                                display: true
                            },
                            // scaleLabel: {
                            //     display: true,
                            //     fontColor: "#111",
                            //     labelString: "경기일자",
                            //     fontSize: 15,
                            //     padding: 0
                            // }
                        }
                    ]
                },
                legend: {
                    display: true
                },
                responsive: true,
                maintainAspectRatio: false
            }
        };
    },
    mounted() {
        this.renderChart(this.chartData, this.options);
    }
};
</script>
