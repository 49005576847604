<script>

import { Bar, mixins } from "vue-chartjs";

const { reactiveProp } = mixins;

export default {
    extends: Bar,
    mixins: [reactiveProp],
    data() {
        return {
            options: {
                tooltips: {
                    position:  'average',
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                        label: function(tooltipItems, data) {
                            return data.datasets[tooltipItems.datasetIndex].label + ": " + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] + " kg";
                        },
                        // footer: function() {
                        //     return " -단위 : 속도 (m/s)";
                        // }
                    }
                },
                scales: {
                    yAxes: [
                        {
                            gridLines: {
                                display: true
                            },
                            scaleLabel: {
                                display: true,
                                fontColor: "#111",
                                labelString: "무게 ( kg )",
                                fontSize: 15,
                                padding: 0
                            },
                            ticks: {
                                stepSize: 5
                            }
                        }
                    ],
                    xAxes: [
                        {
                            stacked: true,
                            gridLines: {
                                display: true
                            },
                            // scaleLabel: {
                            //     display: true,
                            //     fontColor: "#111",
                            //     labelString: "경기일자",
                            //     fontSize: 15,
                            //     padding: 0
                            // }
                        }
                    ]
                },
                legend: {
                    display: true
                },
                responsive: true,
                maintainAspectRatio: false
            }
        };
    },
    mounted() {
        this.renderChart(this.chartData, this.options);
    }
};
</script>
