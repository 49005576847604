const detailCardStore = {
    namespaced: true,
    state: {
        detailScrollID: "",
        detailHorse: [],
        detailRace: [],
        detailDisplay: {
            "ts": 0,
            "items": []
        },
        detailCardStyle: {}
    },
    getters: {
        GET_DETAIL_CARD_STYLE: state => state.detailCardStyle,
        GET_DETAIL_HORSE: state => state.detailHorse,
        GET_DETAIL_DISPLAY: state => state.detailDisplay,
        GET_DETAIL_SCROLL_ID: state => state.detailScrollID,
        GET_DETAIL_RACE: state => state.detailRace // todo #73
    },
    mutations: {

        //
        // DETIAL-CARD-STYLE
        MUT_DETAIL_CARD_STYLE_REMOVE: (state, payload) => {
            console.log("[d1][horseDetialStore] payload : ", payload);
            state.detailCardStyle[payload] = null;
            console.log("[d1][horseDetialStore] state.detailCardStyle before: ", state.detailCardStyle);
            const newKeys = Object.keys(state.detailCardStyle).filter(ff => ff != payload);
            let tmpDetailCardStyle = {};
            newKeys.forEach(ff => {
                tmpDetailCardStyle[ff] = state.detailCardStyle[ff];
            });
            state.detailCardStyle = tmpDetailCardStyle;
            console.log("[d1][horseDetialStore] state.detailCardStyle after: ", state.detailCardStyle);
        },
        MUT_DETAIL_CARD_STYLE_UPDATE: (state, payload) => {
            const tKey = payload["key"];
            const tVal = payload["val"];
            state.detailCardStyle[tKey] = tVal;
        },
        MUT_DETAIL_CARD_STYLE_UPDATE_2: (state, payload) => {
            const tKey1 = payload["key1"];
            const tKey2 = payload["key2"];
            const tVal = payload["val"];
            state.detailCardStyle[tKey1][tKey2] = tVal;
        },

        //
        // DETIAL-HORSE
        MUT_DETAIL_HORSE_PUSH_UNIQUE: (state, horse) => {
            console.log("[detailCardStore.js][MUT_DETAIL_HORSE_PUSH_UNIQUE] horse : ", horse);
            state.detailHorse = state.detailHorse.filter(fff => {
                return horse["id"] != fff["id"];
            });
            state.detailHorse.push(horse);
        },
        MUT_DETAIL_HORSE_UPDATE: (state, horse) => {
            console.log("[detailCardStore.js][MUT_DETAIL_HORSE_UPDATE] horse : ", horse);
            let idx = 0;
            state.detailHorse.some(s => {
                if (horse["id"] == s["id"]) {
                    return true
                } else {
                    idx = idx + 1;
                }
            });
            state.detailHorse[idx] = horse;
        },
        MUT_DETAIL_HORSE_REMOVE: (state, horseID) => {
            state.detailHorse  = state.detailHorse.filter(fff => fff["id"] != horseID);
        },

        //
        // DETIAL-RACE
        MUT_DETAIL_RACE_PUSH_UNIQUE: (state, race) => {
            // todo #73
            console.log("[73][detailCardStore.js][MUT_DETAIL_RACE_PUSH_UNIQUE] race : ", race);
            state.detailRace = state.detailRace.filter(fff => {
                return race["id"] != fff["id"];
            });
            state.detailRace.push(race);
        },
        MUT_DETAIL_RACE_UPDATE: (state, race) => {
            // todo #73
            console.log("[73][detailCardStore.js][MUT_DETAIL_RACE_UPDATE] race : ", race);
            let idx = 0;
            state.detailRace.some(s => {
                if (race["id"] == s["id"]) {
                    return true
                } else {
                    idx = idx + 1;
                }
            });
            state.detailRace[idx] = race;
        },
        MUT_DETAIL_RACE_REMOVE: (state, raceID) => {
            // todo #73
            state.detailRace  = state.detailRace.filter(fff => fff["id"] != raceID);
        },

        //
        // DETIAL-DISPLAY
        MUT_DETAIL_DISPLAY_OVERWRITE: (state, payload) => {
            console.log("[71][detailCardStore.js][MUT_DETAIL_DISPLAY_OVERWRITE] payload : ", payload);
            state.detailDisplay = payload;
        },
        MUT_DETAIL_DISPLAY_PUSH_UNIQUE: (state, display) => {
            console.log("[71][detailCardStore.js][MUT_DETAIL_DISPLAY_PUSH_UNIQUE] display : ", display);
            state.detailDisplay["items"] = state.detailDisplay["items"].filter(fff => {
                return display["id"] != fff["id"];
            });
            state.detailDisplay["items"].push(display);
        },

        //
        // DETAIL-SCROLL-ID
        MUT_DETAIL_SCROLL_ID_OVERWRITE: (state, payload) => {
            console.log("[71][detailCardStore.js][MUT_DETAIL_SCROLL_ID_OVERWRITE] payload : ", payload);
            state.detailScrollID = payload;
        }
    },
    actions: {}
};

export default detailCardStore;