<template>
    <v-container v-if="computedFavorites.length != 0">
        <v-row class="pa-0 ma-2">
            <v-col v-for="(item, index) in computedFavorites" :key="index"
                   align-self="stretch" cols="6" class="pa-1  ma-0">
                <v-card outlined>
                    <v-row class="px-1 py-0 ma-0 justify-end">
                        <v-btn icon @click="toggleFavorite(item)">
                            <v-icon v-if="item['favorite']" color="red">mdi-heart-outline</v-icon>
                            <v-icon v-else>mdi-heart-outline</v-icon>
                        </v-btn>
                    </v-row>
                    <v-card v-if="item['target']=='horse'" elevation="0"
                            class="mx-2 mb-2 lighten-5"
                            @click="openDetailCard(item)">
                        <v-card-title class="justify-center pt-0">
                            {{ item["horse"]["name"] }}
                        </v-card-title>
                        <v-card-subtitle class="text-center">
                            {{ getLocationStringForFavorite(item["horse"]["meet"]) }} / {{ item["horse"]["hid"] }}
                        </v-card-subtitle>
                    </v-card>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
    <v-container v-else class="text-center">
        <v-card outlined>

            <v-row justify="center pt-10 pb-3 px-3">
                <v-img
                    max-height="64"
                    max-width="64"
                    class="text-center"
                    src="../assets/logo_128x128.png"
                    alt="경마-말고고-경마분석-경마문화-서울경마-제주경마-부경경마-문자-좋아하는-카드-없음-알림"
                ></v-img>
            </v-row>
            <v-card-text
                class="text-center text-h6 blue-grey--text font-weight-regular  pa-5 ">
                좋아하는 말고고 카드가 <br> 없습니다 :D
            </v-card-text>
            <v-card-text
                class="text-center  grey--text text--darken-3 font-weight-regular  pt-1 pb-10 px-5">
                경마 분석 카드 하트♡를 클릭하세요.
            </v-card-text>

        </v-card>
    </v-container>
</template>

<script>


import router from "@/router";
import firebase from "firebase";

export default {
    name: "Favorite",
    components: {},

    beforeUpdate() {
        console.log("[60][Favorite.vue][beforeUpdate] In");
    },

    beforeMount() {
        console.log("[60][Favorite.vue][beforeMount] In");
    },
    created() {

        console.log("[60][Favorite.vue][Created] In");
        console.log("[60][Favorite.vue][Created] LoginInfo:", this.$store.getters["userAccountInfoStore/GET_USER_ACCOUNT_INFO"]);

        // if (this.$store.getters["userAccountInfoStore/GET_USER_ACCOUNT_INFO"].uid == "") {
        //     router.replace({ name: "Home" });
        // }

        // this.getFavoriteOfAccountFromFireStore();

        // Favorite 가 Create 될때 무조건 FireStore 에서 User 의 Favorite 정보를 갖고 온다.
        // let firestoreFavorite = this.firestoreFavoriteItemsOfFavorite;
        // let firestoreFavorite = this.getFavoriteOfAccountFromFireStore();
        // this.$store.commit("favoriteStore/MUT_FAVORITE_OVERWRITE", firestoreFavorite);
        let tmpFavorite = this.$store.getters["favoriteStore/GET_FAVORITE"];
        console.log("[60][Favorite.vue][tmpFavorite] ", tmpFavorite);
    },

    beforeDestroy() {
        console.log("[60][Favorite.vue][beforeDestroy] Update Favorite to FireStore. But Not Yet.");
        console.log("[60][z2] [Favorite.vue][beforeDestroy] ");


        let renewFavoriteItems = this.$store.getters["favoriteStore/GET_FAVORITE"]["items"];
        console.log("[60][z2] [Favorite.vue][beforeDestroy] ", renewFavoriteItems);

        renewFavoriteItems = renewFavoriteItems.filter(ff => ff["favorite"]);
        console.log("[60][z2] [Favorite.vue][beforeDestroy] ", renewFavoriteItems);

        const renewFavorite = { "ts": new Date().getTime(), "items": renewFavoriteItems };
        this.$store.commit("favoriteStore/MUT_FAVORITE_OVERWRITE", renewFavorite);
        // TODO FireStore Update Favorite to FireStore
        this.writeFavoriteOfAccountFromFireStore();
    },

    computed: {
        computedFavorites() {

            if (this.$store.getters["userAccountInfoStore/GET_USER_ACCOUNT_INFO"].login) {

                return this.$store.getters["favoriteStore/GET_FAVORITE"]["items"];
            } else {
                return [];
            }
        }
    },

    watch: {},

    methods: {
        analyticsLogEvent(target, hID) {
            let userID = this.$store.getters["userAccountInfoStore/GET_USER_ACCOUNT_INFO"].uid;
            if (!(userID == undefined || userID == null || userID == "")) {  // 로그인 상태
                userID = "none";
            }
            firebase.analytics().logEvent(target, {
                HID: hID,
                UserID: userID
            });
        },

        writeFavoriteOfAccountFromFireStore() {
            const Favorite = this.$store.getters["favoriteStore/GET_FAVORITE"];
            if (this.isUpdateFavorite) {
                const userEmail = this.$store.getters["userAccountInfoStore/GET_USER_ACCOUNT_INFO"].email;
                firebase.firestore().collection("UserDetail").doc(userEmail).set({ Favorite }).then(function() {
                    console.log("[60][Favorite] Success to Write Favorite: ", Favorite);
                }).catch(function(error) {
                    console.log("[60][Favorite] Failure to Write Favorite: ", error);
                });
            }
        },

        toggleFavorite(item) {
            this.isUpdateFavorite = true;
            let tmpFavorite = this.$store.getters["favoriteStore/GET_FAVORITE"];
            let tmpFavoriteItems = tmpFavorite["items"];
            tmpFavoriteItems = tmpFavoriteItems.map(ti => {
                if (ti["id"] == item["id"]) {
                    ti["favorite"] = !ti["favorite"];
                    if (ti["favorite"]) {
                        this.analyticsLogEvent("click_favorite_on", item["id"]);
                    } else {
                        this.analyticsLogEvent("click_favorite_off", item["id"]);
                    }
                }
                return ti;
            });
            tmpFavorite = {
                "ts": new Date().getTime(),
                "items": tmpFavoriteItems
            };
            this.$store.commit("favoriteStore/MUT_FAVORITE_OVERWRITE", tmpFavorite);
            console.log("[z2] Favorite-FAVORITE:", tmpFavorite, this.$store.getters["favoriteStore/GET_FAVORITE"]);

        },


        openDetailCard(item) {

            this.analyticsLogEvent("click_favorite_open_horseDetail", item["id"]);

            let renewDetailDisplayItems = this.$store.getters["detailCardStore/GET_DETAIL_DISPLAY"]["items"];
            renewDetailDisplayItems = renewDetailDisplayItems.filter(ff => ff["id"] != item["id"]);

            this.$store.getters["favoriteStore/GET_FAVORITE"]["items"].some(fff => {
                if (fff["id"] == item["id"]) {
                    item["favorite"] = fff["favorite"];
                }
                return fff["id"] == item["id"];
            });

            renewDetailDisplayItems = [item].concat(renewDetailDisplayItems);

            const renewDetailDisplay = {
                ts: new Date().getTime(),
                items: renewDetailDisplayItems
            };
            this.$store.commit("detailCardStore/MUT_DETAIL_DISPLAY_OVERWRITE", renewDetailDisplay);
            localStorage.setItem("DetailDisplay", JSON.stringify(this.$store.getters["detailCardStore/GET_DETAIL_DISPLAY"]));
            this.$store.commit("detailCardStore/MUT_DETAIL_SCROLL_ID_OVERWRITE", item["id"]);
            router.replace({ name: "Home" });
        },

        getLocationStringForFavorite(meet) {
            console.log("[HELLOZ] getLocationStringForFavorite:", meet);
            let loc = "";
            switch (meet) {
                case 1:
                    loc = "서울";
                    break;
                case 2:
                    loc = "제주";
                    break;
                case 3:
                    loc = "부경";
                    break;
            }

            return loc;
        }
    },
    data() {
        return {

            favorites: this.$store.getters["favoriteStore/GET_FAVORITE"],

            isUpdateFavorite: false

        };
    }
};
</script>
<style scoped></style>
