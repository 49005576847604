<template>
    <div class="mx-3 px-0 overflow-auto">
        <login-card class="py-0 my-0"></login-card>
        <about-card class="my-3"></about-card>

    </div>
</template>

<script>

import LoginCard from "@/components/LoginCard.vue";
import AboutCard from "@/components/AboutCard.vue";
import router from "@/router";

export default {
    name: "Account",
    components: {
        "login-card": LoginCard,
        "about-card": AboutCard
    },


    created() {

        if (this.$route.params.code == "") {
            // 만약 Home 진입이 없었으면, 강제로 Home으로 Route 를 Raplace 한다.
            if (!this.$store.getters["masterStore/GET_ENTRY_HOME"]) {
                router.replace({ name: "Home" });
            }
        }

    },

    beforeDestroy() {
    },

    computed: {},

    watch: {},

    methods: {},
    data() {
        return {};
    }
};
</script>
<style scoped></style>
