<script>
import { HorizontalBar, mixins } from "vue-chartjs";

const { reactiveProp } = mixins;
export default {
    extends: HorizontalBar,
    mixins: [reactiveProp],

    data() {
        return {
            options: {
                tooltips: {
                    enabled: true,
                    position: "average",
                    mode: "index",
                    intersect: false,
                    callbacks: {
                        label: function(tooltipItems, data) {
                            return data.datasets[tooltipItems.datasetIndex].label + ": " + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] + " s";
                        },
                        footer: function(tooltipItems, data) {


                            console.log("zzzzz: tooltipItems, data", tooltipItems, data);

                            let tIndexHorse = -1;
                            const tSectionIndexs = [];

                            tooltipItems.forEach(function(tooltipItem) {
                                tIndexHorse = tooltipItem.index;
                                tSectionIndexs.push(tooltipItem.datasetIndex);
                            });

                            let sums = {};
                            tSectionIndexs.forEach(function(tSectionIndex) {
                                const records = data.datasets[tSectionIndex].data;

                                records.forEach(function(record, hIndex) {
                                    if (sums[hIndex] == undefined || sums[hIndex] == null) {
                                        sums[hIndex] = 0;
                                    }
                                    sums[hIndex] =  sums[hIndex] + record;
                                });
                            });

                            let rank = 1;
                            const tSum = sums[tIndexHorse];
                            Object.values(sums).forEach(function(s){
                                if (s < tSum){
                                    rank = rank +1;
                                }
                            });

                            return '   - 총합 : ' + (Math.round(tSum*100)/100) +' s '  + '\n   - 순위 : ' + rank+' 위';
                        }
                    }
                },

                legend: {
                    display: true
                },
                scales: {
                    xAxes: [{
                        // type: 'logarithmic',
                        ticks: {
                            beginAtZero: true,
                            // suggestedMin: 50
                            stepSize: 1
                            // suggestedMax: 80
                        },
                        scaleLabel: {
                            display: true,
                            fontColor: "#111",
                            labelString: "구간별 시간 기록 (s)",
                            fontSize: 15,
                            padding: 0
                        },
                        stacked: true,
                        categoryPercentage: 1,
                        barPercentage: 1
                    }],
                    yAxes: [{

                        stacked: true,
                        ticks: {
                            callback: function(value) {
                                //  tHorsesTmp.push(kHorse.substr(0,5));
                                return value.toString().substr(0, 4);
                            }
                        }
                    }]
                },

                responsive: true,
                maintainAspectRatio: false
            }
        };
    },

    beforeMount() {
        console.log(this.chartData.temp);
        this.tempString = this.chartData.temp;
    },
    mounted() {

        this.renderChart(this.chartData, this.options);
    }
};
</script>
