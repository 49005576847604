import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Favorite from "../views/Favorite.vue";
import Search from "../views/Search.vue";
import Share from "../views/Share.vue";
import Account from "../views/Account.vue";
import AuthKakao from "../components/AuthKakao.vue";
// import BlankContainer from "../components/BlankContainer.vue";
// import CallbackKakao from "@/components/CallbackKakao";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/favorite",
        name: "Favorite",
        component: Favorite,
        props: true
    },
    {
        path: "/search",
        name: "Search",
        component: Search,
        props: true
    },
    {
        path: "/share",
        name: "Share",
        component: Share,
        props: true
    },
    {
        path: "/share/:id",
        name: "Share",
        component: Share,
        props: true
    },
    {
        path: "/account",
        name: "Account",
        component: Account,
        props: true
    },
    {
        path: "/auth-kakao",
        name: "AuthKakao",
        component: AuthKakao,
        props: true
    },
    {
        path: "*",
        redirect: "/",
        props: true
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;
