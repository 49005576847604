<template>
    <!-- DETAIL-HORSE-->
    <v-card v-if="this.card_title['target'] == 'horse'"
            outlined elevation="4" :id="this.card_title['id']" class="my-3 pa-0" :color="this.colorCard"
            :loading="loading">
        <!-- 2. TREND 상금 -->
        <!-- 2. TREND 레이팅 -->
        <!-- 2. TREND 마체중 -->
        <!-- 2. MATCH 마번 -->
        <!-- 2. MATCH 기수 -->
        <!-- 2. MATCH 장구 -->
        <!-- 2. MATCH 경주거리 -->
        <!-- 2. MATCH 경주로 : 건조(x<6) / 양호(6<=x<10) / 다습(10<=x<15) / 포화(15<=x<20) / 불량(20<=x)-->
        <!-- 2. Analytics 스피드 -->
        <!--  -->
        <!-- 1. Headline -->
        <v-card-title class="headline">
            <!-- <v-icon>mdi-chart-line</v-icon> -->
            <!-- <v-icon>mdi-horse</v-icon> -->


            <v-btn v-if="this.favoriteCard" icon @click="clickFavoriteCard()" color="red">
                <v-icon>mdi-heart-outline</v-icon>
            </v-btn>
            <v-btn v-else icon @click="clickFavoriteCard()">
                <v-icon>mdi-heart-outline</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <div @click="copyClipboardDetailURL()">
                <b> {{ card_title["horse"]["name"] }} </b>
                <v-btn icon>
                    <v-icon small>mdi-link-variant</v-icon>
                </v-btn>
            </div>

            <v-overlay
                :value="showOverlayNotiShareURL"
            >
                <v-btn
                    class="white--text text-body-1 font-weight-regular"
                    color="grey darken-4"
                    x-large
                    @click="showOverlayNotiShareURL = false; overlayNotiShareUrlMessage='';"
                >
                    {{ overlayNotiShareUrlMessage }}
                </v-btn>
            </v-overlay>
            <v-spacer></v-spacer>

            <v-btn icon @click="renewCardHorseDataAll()">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn icon @click="closeDetailCard()">
                <v-icon> mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <!--        <input type="text" :id="'clipboard-'+card_title['id']" :value="rootPathShareURL+card_title['id']" disabled>-->

        <!--  -->
        <!-- 2. Race 기본정보 Chips -->
        <v-card class="mx-1 my-1 pa-0" :color="this.colorChipCard" outlined elevation="0">
            <v-tooltip top v-for="(item, index) in horseChips" :key="index">
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                        <v-chip
                            :color="item.color"
                            class="ma-1"
                            label
                            small
                            outlined
                            text-color="grey darken-3"
                        >
                            <v-icon left>
                                {{ item.icon }}
                            </v-icon>
                            {{ item.text }}
                        </v-chip>
                    </span>
                </template>
                <span> {{ item.label }} </span>
            </v-tooltip>
        </v-card>

        <!-- https://github.com/sitepoint-editors/vue-charts/tree/master/src/views -->
        <!-- <v-divider class="my-4 mx-7" />-->
        <v-row class="mx-1 my-1 pa-0">

            <!--  -->
            <!-- 분석 - 속도 -->
            <v-col cols="12" class="mx-0 my-1 pa-0" v-if="addHorseChartItems['analyticsSpeed']['opened']">
                <v-card elevation="0" class="pa-0 ma-0">
                    <v-card-title
                        class="headline px-5 py-0"
                        @click="toggleExpand1ChartHorseDetail('analyticsSpeed')"
                    >
                        <v-col cols="5" class="mx-0 px-0">
                            <div
                                :class="[`text-overline`, active && `mb-1`]"
                                class="transition-swing"
                            >
                                Analytics
                            </div>
                        </v-col>
                        <v-col cols="5" class="mx-0 px-0">
                            <div
                                :class="[`text-h5`, active && `mb-1`]"
                                class="transition-swing"
                            >
                                펄롱속도
                            </div>
                        </v-col>
                        <v-col cols="1" class="mx-0 px-0">
                            <v-btn icon>
                                <v-icon v-if="toggleActiveHorse['analyticsSpeed']">
                                    mdi-chevron-up
                                </v-icon>
                                <v-icon v-else> mdi-chevron-down</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="1" class="mx-0 px-0">
                            <v-btn icon @click="close1ChartHorseDetail('analyticsSpeed')">
                                <v-icon small> mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                    </v-card-title>

                    <v-sheet class="px-5">
                        <v-expand-transition>
                            <v-responsive v-if="toggleActiveHorse['analyticsSpeed']">
                                <v-row class="px-5 py-0 my-0">
                                    <v-col cols="12"
                                           class="py-0 my-0 px-0 mx-0 align-center text-subtitle-1 grey--text text--darken-4 text-right">
                                        {{ infoHorseAll["horseName"] }}
                                    </v-col>
                                    <v-col cols="12"
                                           class="py-0 my-0 px-0 mx-0 text-subtitle-1 font-weight-light  black--text text--darken-4 text-right">
                                        최근 성적 기반 구간별 속도분석
                                    </v-col>
                                </v-row>
                                <v-row class="px-5 py-1 my-1 align-center">
                                    <v-col cols="3" class="px-0 mx-0 py-0 my-0"></v-col>
                                    <v-col cols="1" class="px-0 mx-0 py-0 my-0 align-center">
                                        <v-icon>mdi-calendar-month</v-icon>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0 py-0 my-0">
                                        <v-btn v-if="radioButtonRangeHorse['analyticsSpeedLatest']['all']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text deep-orange lighten-2">모든
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('analyticsSpeedLatest','all')"
                                               class="text-center text-overline deep-orange--text grey lighten-3">모든
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0 py-0 my-0">
                                        <v-btn v-if="radioButtonRangeHorse['analyticsSpeedLatest']['latest9']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text brown lighten-2">최근9
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('analyticsSpeedLatest','latest9')"
                                               class="text-center text-overline brown--text grey lighten-3">최근9
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0 py-0 my-0">
                                        <v-btn v-if="radioButtonRangeHorse['analyticsSpeedLatest']['latest6']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text green lighten-2">최근6
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('analyticsSpeedLatest','latest6')"
                                               class="text-center text-overline green--text grey lighten-3">최근6
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0 py-0 my-0">
                                        <v-btn v-if="radioButtonRangeHorse['analyticsSpeedLatest']['latest3']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text blue lighten-2">최근3
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('analyticsSpeedLatest','latest3')"
                                               class="text-center text-overline blue--text grey lighten-3">최근3
                                        </v-btn>
                                    </v-col>
                                </v-row>


                                <v-row class="px-5 py-1 my-1 align-center">
                                    <v-col cols="5" class="px-0 mx-0 py-0 my-0"></v-col>
                                    <v-col cols="1" class="px-0 mx-0 py-0 my-0 align-center">
                                        <v-icon>mdi-clock-fast</v-icon>
                                    </v-col>
                                    <v-col cols="2" class="px-0 py-0 my-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['analyticsSpeedMinMaxAvg']['avg']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text green lighten-2">평균
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('analyticsSpeedMinMaxAvg','avg')"
                                               class="text-center text-overline green--text grey lighten-3">평균
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0 py-0 my-0">
                                        <v-btn v-if="radioButtonRangeHorse['analyticsSpeedMinMaxAvg']['max']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text brown lighten-2">최고
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('analyticsSpeedMinMaxAvg','max')"
                                               class="text-center text-overline brown--text grey lighten-3">최고
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0 py-0 my-0">
                                        <v-btn v-if="radioButtonRangeHorse['analyticsSpeedMinMaxAvg']['min']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text blue lighten-2">최저
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('analyticsSpeedMinMaxAvg','min')"
                                               class="text-center text-overline blue--text grey lighten-3">최저
                                        </v-btn>
                                    </v-col>
                                </v-row>

                                <chart-analytics-speed
                                    :chart-data="chartDataAnalyticsSpeed"
                                >
                                </chart-analytics-speed>
                            </v-responsive>
                        </v-expand-transition>
                    </v-sheet>
                </v-card>
                <!-- <v-divider class="my-0 mx-7" />-->
            </v-col>

            <!--  -->
            <!-- 매칭율 - 경주거리 -->
            <v-col cols="12" class="mx-0 my-1 pa-0" v-if="addHorseChartItems['matchRaceDistance']['opened']">
                <v-card elevation="0" class="pa-0 ma-0">
                    <v-card-title
                        class="headline px-5 py-0"
                        @click="toggleExpand1ChartHorseDetail('matchRaceDistance')"
                    >
                        <v-col cols="5" class="mx-0 px-0">
                            <div
                                :class="[`text-overline`, active && `mb-1`]"
                                class="transition-swing"
                            >
                                Match
                            </div>
                        </v-col>
                        <v-col cols="5" class="mx-0 px-0">
                            <div
                                :class="[`text-h5`, active && `mb-1`]"
                                class="transition-swing"
                            >
                                경주거리
                            </div>
                        </v-col>
                        <v-col cols="1" class="mx-0 px-0">
                            <v-btn icon>
                                <v-icon v-if="toggleActiveHorse['matchRaceDistance']">
                                    mdi-chevron-up
                                </v-icon>
                                <v-icon v-else> mdi-chevron-down</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="1" class="mx-0 px-0">
                            <v-btn icon @click="close1ChartHorseDetail('matchRaceDistance')">
                                <v-icon small> mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                    </v-card-title>

                    <v-sheet class="pl-5 pr-7">
                        <v-expand-transition>
                            <v-responsive v-if="toggleActiveHorse['matchRaceDistance']">
                                <v-row class="px-5 py-0 my-0">
                                    <v-col cols="12"
                                           class="py-0 my-0 px-0 mx-0 align-center text-subtitle-1 grey--text text--darken-4 text-right">
                                        {{ infoHorseAll["horseName"] }}
                                    </v-col>
                                    <v-col cols="12"
                                           class="py-0 my-0 px-0 mx-0 text-subtitle-1 font-weight-light  black--text text--darken-4 text-right">
                                        최근 성적 기반 경주거리별 매칭률
                                    </v-col>
                                </v-row>
                                <v-row class="px-5 align-center">
                                    <v-col cols="3" class="px-0 mx-0"></v-col>
                                    <v-col cols="1" class="px-0 mx-0 align-center">
                                        <v-icon>mdi-calendar-month</v-icon>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['matchRaceDistance']['all']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text deep-orange lighten-2">모든
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('matchRaceDistance','all')"
                                               class="text-center text-overline deep-orange--text grey lighten-3">모든
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['matchRaceDistance']['latest9']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text brown lighten-2">최근9
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('matchRaceDistance','latest9')"
                                               class="text-center text-overline brown--text grey lighten-3">최근9
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['matchRaceDistance']['latest6']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text green lighten-2">최근6
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('matchRaceDistance','latest6')"
                                               class="text-center text-overline green--text grey lighten-3">최근6
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['matchRaceDistance']['latest3']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text blue lighten-2">최근3
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('matchRaceDistance','latest3')"
                                               class="text-center text-overline blue--text grey lighten-3">최근3
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <chart-match-race-distance
                                    :chart-data="chartDataMatchRaceDistance"
                                >
                                </chart-match-race-distance>
                                <v-card-text class="mt-4 mb-2 ml-5 mr-0 pa-0">
                                    <div class="text-left grey--text text--darken-2 font-weight-regular ma-0 pa-0"
                                         v-for="(summary,index) in summaryMatchRaceDistance"
                                         :key="index">
                                        • {{ summary }}
                                    </div>
                                </v-card-text>
                            </v-responsive>
                        </v-expand-transition>
                    </v-sheet>

                </v-card>
                <!-- <v-divider class="my-0 mx-7" />-->
            </v-col>

            <!--  -->
            <!-- 매칭율 - 기수 -->
            <v-col cols="12" class="mx-0 my-1 pa-0" v-if="addHorseChartItems['matchJockey']['opened']">
                <v-card elevation="0" class="pa-0 ma-0">
                    <v-card-title
                        class="headline px-5 py-0"
                        @click="toggleExpand1ChartHorseDetail('matchJockey')"
                    >
                        <v-col cols="5" class="mx-0 px-0">
                            <div
                                :class="[`text-overline`, active && `mb-1`]"
                                class="transition-swing"
                            >
                                Match
                            </div>
                        </v-col>
                        <v-col cols="5" class="mx-0 px-0">
                            <div
                                :class="[`text-h5`, active && `mb-1`]"
                                class="transition-swing"
                            >
                                기수
                            </div>
                        </v-col>
                        <v-col cols="1" class="mx-0 px-0">
                            <v-btn icon>
                                <v-icon v-if="toggleActiveHorse['matchJockey']">
                                    mdi-chevron-up
                                </v-icon>
                                <v-icon v-else> mdi-chevron-down</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="1" class="mx-0 px-0">
                            <v-btn icon @click="close1ChartHorseDetail('matchJockey')">
                                <v-icon small> mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                    </v-card-title>

                    <v-sheet class="pl-5 pr-7">
                        <v-expand-transition>
                            <v-responsive v-if="toggleActiveHorse['matchJockey']">
                                <v-row class="px-5 py-0 my-0">
                                    <v-col cols="12"
                                           class="py-0 my-0 px-0 mx-0 align-center text-subtitle-1 grey--text text--darken-4 text-right">
                                        {{ infoHorseAll["horseName"] }}
                                    </v-col>
                                </v-row>
                                <v-col cols="12"
                                       class="py-0 my-0 px-0 mx-0 text-subtitle-1 font-weight-light  black--text text--darken-4 text-right">
                                    최근 성적 기반 기수별 매칭률
                                </v-col>
                                <v-row class="px-5 align-center">
                                    <v-col cols="3" class="px-0 mx-0"></v-col>
                                    <v-col cols="1" class="px-0 mx-0 align-center">
                                        <v-icon>mdi-calendar-month</v-icon>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['matchJockey']['all']" x-small elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text deep-orange lighten-2">모든
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('matchJockey','all')"
                                               class="text-center text-overline deep-orange--text grey lighten-3">모든
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['matchJockey']['latest9']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text brown lighten-2">최근9
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('matchJockey','latest9')"
                                               class="text-center text-overline brown--text grey lighten-3">최근9
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['matchJockey']['latest6']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text green lighten-2">최근6
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('matchJockey','latest6')"
                                               class="text-center text-overline green--text grey lighten-3">최근6
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['matchJockey']['latest3']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text blue lighten-2">최근3
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('matchJockey','latest3')"
                                               class="text-center text-overline blue--text grey lighten-3">최근3
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <chart-match-jockey
                                    :chart-data="chartDataMatchJockey"
                                >
                                </chart-match-jockey>
                                <v-card-text class="mt-4 mb-2 ml-5 mr-0 pa-0">
                                    <div class="text-left grey--text text--darken-2 font-weight-regular ma-0 pa-0"
                                         v-for="(summary,index) in summaryMatchJockeys"
                                         :key="index">
                                        • {{ summary }}
                                    </div>
                                </v-card-text>
                            </v-responsive>
                        </v-expand-transition>
                    </v-sheet>

                </v-card>
                <!-- <v-divider class="my-0 mx-7" />-->
            </v-col>

            <!--  -->
            <!-- 매칭율 - 마번 -->
            <v-col cols="12" class="mx-0 my-1 pa-0" v-if="addHorseChartItems['matchHorseNumber']['opened']">
                <v-card elevation="0" class="pa-0 ma-0">
                    <v-card-title
                        class="headline px-5 py-0"
                        @click="toggleExpand1ChartHorseDetail('matchHorseNumber')"
                    >
                        <v-col cols="5" class="mx-0 px-0">
                            <div
                                :class="[`text-overline`, active && `mb-1`]"
                                class="transition-swing"
                            >
                                Match
                            </div>
                        </v-col>
                        <v-col cols="5" class="mx-0 px-0">

                            <div
                                :class="[`text-h5`, active && `mb-1`]"
                                class="transition-swing"
                            >
                                마번
                            </div>
                        </v-col>
                        <v-col cols="1" class="mx-0 px-0">
                            <v-btn icon>
                                <v-icon v-if="toggleActiveHorse['matchHorseNumber']">
                                    mdi-chevron-up
                                </v-icon>
                                <v-icon v-else> mdi-chevron-down</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="1" class="mx-0 px-0">
                            <v-btn icon @click="close1ChartHorseDetail('matchHorseNumber')">
                                <v-icon small> mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                    </v-card-title>
                    <v-sheet class="pl-5 pr-7">
                        <v-expand-transition>
                            <v-responsive v-if="toggleActiveHorse['matchHorseNumber']">
                                <v-row class="px-5 py-0 my-0">
                                    <v-col cols="12"
                                           class="py-0 my-0 px-0 mx-0 align-center text-subtitle-1 grey--text text--darken-4 text-right">
                                        {{ infoHorseAll["horseName"] }}
                                    </v-col>
                                    <v-col cols="12"
                                           class="py-0 my-0 px-0 mx-0 text-subtitle-1 font-weight-light  black--text text--darken-4 text-right">
                                        최근 성적 기반 마번별 매칭률
                                    </v-col>
                                </v-row>
                                <v-row class="px-5 align-center">
                                    <v-col cols="3" class="px-0 mx-0"></v-col>
                                    <v-col cols="1" class="px-0 mx-0 align-center">
                                        <v-icon>mdi-calendar-month</v-icon>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['matchHorseNumber']['all']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text deep-orange lighten-2">모든
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('matchHorseNumber','all')"
                                               class="text-center text-overline deep-orange--text grey lighten-3">모든
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['matchHorseNumber']['latest9']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text brown lighten-2">최근9
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('matchHorseNumber','latest9')"
                                               class="text-center text-overline brown--text grey lighten-3">최근9
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['matchHorseNumber']['latest6']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text green lighten-2">최근6
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('matchHorseNumber','latest6')"
                                               class="text-center text-overline green--text grey lighten-3">최근6
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['matchHorseNumber']['latest3']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text blue lighten-2">최근3
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('matchHorseNumber','latest3')"
                                               class="text-center text-overline blue--text grey lighten-3">최근3
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <chart-match-horse-number
                                    :chart-data="chartDataMatchHorseNumber"
                                >
                                </chart-match-horse-number>
                                <v-card-text class="mt-4 mb-2 ml-5 mr-0 pa-0">
                                    <div class="text-left grey--text text--darken-2 font-weight-regular ma-0 pa-0"
                                         v-for="(summary,index) in summaryMatchHorseNumbers"
                                         :key="index">
                                        • {{ summary }}
                                    </div>
                                </v-card-text>
                            </v-responsive>
                        </v-expand-transition>
                    </v-sheet>

                </v-card>
                <!-- <v-divider class="my-0 mx-7" />-->
            </v-col>

            <!--  -->
            <!-- 매칭율 - 경주로상태 -->
            <v-col cols="12" class="mx-0 my-1 pa-0" v-if="addHorseChartItems['matchRaceTrackCondition']['opened']">
                <v-card elevation="0" class="pa-0 ma-0">
                    <v-card-title
                        class="headline px-5 py-0"
                        @click="toggleExpand1ChartHorseDetail('matchRaceTrackCondition')"
                    >
                        <v-col cols="5" class="mx-0 px-0">
                            <div
                                :class="[`text-overline`, active && `mb-1`]"
                                class="transition-swing"
                            >
                                Match
                            </div>
                        </v-col>
                        <v-col cols="5" class="mx-0 px-0">
                            <div
                                :class="[`text-h5`, active && `mb-1`]"
                                class="transition-swing"
                            >
                                경주로상태
                            </div>
                        </v-col>
                        <v-col cols="1" class="mx-0 px-0">
                            <v-btn icon>
                                <v-icon v-if="toggleActiveHorse['matchRaceTrackCondition']">
                                    mdi-chevron-up
                                </v-icon>
                                <v-icon v-else> mdi-chevron-down</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="1" class="mx-0 px-0">
                            <v-btn icon @click="close1ChartHorseDetail('matchRaceTrackCondition')">
                                <v-icon small> mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                    </v-card-title>

                    <v-sheet class="pl-5 pr-7">
                        <v-expand-transition>
                            <v-responsive v-if="toggleActiveHorse['matchRaceTrackCondition']">
                                <v-row class="px-5 py-0 my-0">
                                    <v-col cols="12"
                                           class="py-0 my-0 px-0 mx-0 align-center text-subtitle-1 grey--text text--darken-4 text-right">
                                        {{ infoHorseAll["horseName"] }}
                                    </v-col>
                                    <v-col cols="12"
                                           class="py-0 my-0 px-0 mx-0 text-subtitle-1 font-weight-light  black--text text--darken-4 text-right">
                                        최근 성적 기반 경주로상태별 매칭률
                                    </v-col>
                                </v-row>
                                <v-row class="px-5 align-center">
                                    <v-col cols="3" class="px-0 mx-0"></v-col>
                                    <v-col cols="1" class="px-0 mx-0 align-center">
                                        <v-icon>mdi-calendar-month</v-icon>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['matchRaceTrackCondition']['all']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text deep-orange lighten-2">모든
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('matchRaceTrackCondition','all')"
                                               class="text-center text-overline deep-orange--text grey lighten-3">모든
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['matchRaceTrackCondition']['latest9']"
                                               x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text brown lighten-2">최근9
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('matchRaceTrackCondition','latest9')"
                                               class="text-center text-overline brown--text grey lighten-3">최근9
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['matchRaceTrackCondition']['latest6']"
                                               x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text green lighten-2">최근6
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('matchRaceTrackCondition','latest6')"
                                               class="text-center text-overline green--text grey lighten-3">최근6
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['matchRaceTrackCondition']['latest3']"
                                               x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text blue lighten-2">최근3
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('matchRaceTrackCondition','latest3')"
                                               class="text-center text-overline blue--text grey lighten-3">최근3
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <chart-match-race-track-condition
                                    :chart-data="chartDataMatchRaceTrackCondition"
                                >
                                </chart-match-race-track-condition>
                                <v-card-text class="mt-4 mb-2 ml-5 mr-0 pa-0">
                                    <div class="text-left grey--text text--darken-2 font-weight-regular ma-0 pa-0"
                                         v-for="(summary,index) in summaryMatchRaceTrackCondition"
                                         :key="index">
                                        • {{ summary }}
                                    </div>
                                </v-card-text>
                            </v-responsive>
                        </v-expand-transition>
                    </v-sheet>

                </v-card>
                <!-- <v-divider class="my-0 mx-7" />-->
            </v-col>

            <!--  -->
            <!-- 매칭율 - 장구 -->
            <v-col cols="12" class="mx-0 my-1 pa-0" v-if="addHorseChartItems['matchTool']['opened']">
                <v-card elevation="0" class="pa-0 ma-0">
                    <v-card-title
                        class="headline px-5 py-0"
                        @click="toggleExpand1ChartHorseDetail('matchTool')"
                    >
                        <v-col cols="5" class="px-0 mx-0">
                            <div
                                :class="[`text-overline`, active && `mb-1`]"
                                class="transition-swing"
                            >
                                Match
                            </div>
                        </v-col>
                        <v-col cols="5" class="px-0 mx-0">
                            <div
                                :class="[`text-h5`, active && `mb-1`]"
                                class="transition-swing"
                            >
                                장구(승)
                            </div>
                        </v-col>
                        <v-col cols="1" class="mx-0 px-0">
                            <v-btn icon>
                                <v-icon v-if="toggleActiveHorse['matchTool']">
                                    mdi-chevron-up
                                </v-icon>
                                <v-icon v-else> mdi-chevron-down</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="1" class="mx-0 px-0">
                            <v-btn icon @click="close1ChartHorseDetail('matchTool')">
                                <v-icon small> mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                    </v-card-title>

                    <v-sheet class="pl-5 pr-7">
                        <v-expand-transition>
                            <v-responsive v-if="toggleActiveHorse['matchTool']">
                                <v-row class="px-5 py-0 my-0">
                                    <v-col cols="12"
                                           class="py-0 my-0 px-0 mx-0 align-center text-subtitle-1 grey--text text--darken-4 text-right">
                                        {{ infoHorseAll["horseName"] }}
                                    </v-col>
                                    <v-col cols="12"
                                           class="py-0 my-0 px-0 mx-0 text-subtitle-1 font-weight-light  black--text text--darken-4 text-right">
                                        최근 성적 기반 장구(승)별 매칭률
                                    </v-col>
                                </v-row>
                                <v-row class="px-5 align-center">
                                    <v-col cols="3" class="px-0 mx-0"></v-col>
                                    <v-col cols="1" class="px-0 mx-0 align-center">
                                        <v-icon>mdi-calendar-month</v-icon>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['matchTool']['all']" x-small elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text deep-orange lighten-2">모든
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('matchTool','all')"
                                               class="text-center text-overline deep-orange--text grey lighten-3">모든
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['matchTool']['latest9']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text brown lighten-2">최근9
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('matchTool','latest9')"
                                               class="text-center text-overline brown--text grey lighten-3">최근9
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['matchTool']['latest6']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text green lighten-2">최근6
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('matchTool','latest6')"
                                               class="text-center text-overline green--text grey lighten-3">최근6
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['matchTool']['latest3']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text blue lighten-2">최근3
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('matchTool','latest3')"
                                               class="text-center text-overline blue--text grey lighten-3">최근3
                                        </v-btn>
                                    </v-col>
                                </v-row>


                                <chart-match-tool
                                    :chart-data="chartDataMatchTool"
                                >
                                </chart-match-tool>
                                <v-card-text class="mt-4 mb-2 ml-5 mr-0 pa-0">
                                    <div class="text-left grey--text text--darken-2 font-weight-regular ma-0 pa-0"
                                         v-for="(summary,index) in summaryMatchTools"
                                         :key="index">
                                        • {{ summary }}
                                    </div>
                                </v-card-text>
                            </v-responsive>
                        </v-expand-transition>
                    </v-sheet>

                </v-card>
                <!--  <v-divider class="my-0 mx-7" />-->
            </v-col>

            <!--  -->
            <!-- 트랜드 - 상금 -->
            <v-col cols="12" class="mx-0 my-1 pa-0" v-if="addHorseChartItems['trendPrize']['opened']">
                <v-card elevation="0" class="pa-0 ma-0">
                    <v-card-title
                        class="headline px-5 py-0"
                        @click="toggleExpand1ChartHorseDetail('trendPrize')"
                    >
                        <v-col cols="5" class="mx-0 px-0">
                            <div
                                :class="[`text-overline`, active && `mb-1`]"
                                class="transition-swing"
                            >
                                Trend
                            </div>
                        </v-col>
                        <v-col cols="5" class="mx-0 px-0">
                            <div
                                :class="[`text-h5`, active && `mb-1`]"
                                class="transition-swing"
                            >
                                상금
                            </div>
                        </v-col>
                        <v-col cols="1" class="mx-0 px-0">
                            <v-btn icon>
                                <v-icon v-if="toggleActiveHorse['trendPrize']">
                                    mdi-chevron-up
                                </v-icon>
                                <v-icon v-else> mdi-chevron-down</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="1" class="mx-0 px-0">
                            <v-btn icon @click="close1ChartHorseDetail('trendPrize')">
                                <v-icon small> mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                    </v-card-title>

                    <v-sheet class="px-5">
                        <v-expand-transition>
                            <v-responsive v-if="toggleActiveHorse['trendPrize']">
                                <v-row class="px-5 py-0 my-0">
                                    <v-col cols="12"
                                           class="py-0 my-0 px-0 mx-0 align-center text-subtitle-1 grey--text text--darken-4 text-right">
                                        {{ infoHorseAll["horseName"] }}
                                    </v-col>
                                    <v-col cols="12"
                                           class="py-0 my-0 px-0 mx-0 text-subtitle-1 font-weight-light  black--text text--darken-4 text-right">
                                        최근 성적 기반 누적상금 트렌드
                                    </v-col>
                                </v-row>

                                <v-row class="px-5 pt-0 mt-0 align-center">
                                    <v-col cols="3" class="px-0 mx-0"></v-col>
                                    <v-col cols="1" class="px-0 mx-0 align-center">
                                        <v-icon>mdi-calendar-month</v-icon>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['trendPrize']['all']" x-small elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text deep-orange lighten-2">모든
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('trendPrize','all')"
                                               class="text-center text-overline deep-orange--text grey lighten-3">모든
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['trendPrize']['latest9']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text brown lighten-2">최근9
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('trendPrize','latest9')"
                                               class="text-center text-overline brown--text grey lighten-3">최근9
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['trendPrize']['latest6']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text green lighten-2">최근6
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('trendPrize','latest6')"
                                               class="text-center text-overline green--text grey lighten-3">최근6
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['trendPrize']['latest3']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text blue lighten-2">최근3
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('trendPrize','latest3')"
                                               class="text-center text-overline blue--text grey lighten-3">최근3
                                        </v-btn>
                                    </v-col>
                                </v-row>


                                <!--                                <div-->
                                <!--                                    :class="[`text-body-1`, active && `mb-1`]"-->
                                <!--                                    class="transition-swing ml-3 mb-3 font-weight-regular"-->
                                <!--                                >-->
                                <!--                                    <code class="grey&#45;&#45;text text&#45;&#45;darken-3">-->
                                <!--                                        <v-icon small color="grey darken-4">-->
                                <!--                                            mdi-information-outline-->
                                <!--                                        </v-icon>-->
                                <!--                                        {{ summaryTrendPrize }}-->
                                <!--                                    </code>-->
                                <!--                                </div>-->


                                <chart-trend-prize
                                    :chart-data="chartDataTrendPrize"
                                >
                                </chart-trend-prize>
                                <v-card
                                    elevation="0"
                                    class="mt-4 mb-2 ml-5 mr-0  px-0"
                                >
                                    <v-card-text class="ma-0 pa-0">
                                        <div class="text-left grey--text text--darken-2 font-weight-regular ma-0 pa-0"
                                             v-for="(summary,index) in summaryTrendPrizes"
                                             :key="index">
                                            • {{ summary }}
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-responsive>
                        </v-expand-transition>
                    </v-sheet>
                </v-card>
                <!--  <v-divider class="my-0 mx-7" />-->
            </v-col>

            <!--  -->
            <!-- 트랜드 - 레이팅 -->
            <v-col cols="12" class="mx-0 my-1 pa-0" v-if="addHorseChartItems['trendRating']['opened']">
                <v-card elevation="0" class="pa-0 ma-0">
                    <v-card-title
                        class="headline px-5 py-0"
                        @click="toggleExpand1ChartHorseDetail('trendRating')"
                    >
                        <v-col cols="5" class="mx-0 px-0">
                            <div
                                :class="[`text-overline`, active && `mb-1`]"
                                class="transition-swing"
                            >
                                Trend
                            </div>
                        </v-col>
                        <v-col cols="5" class="mx-0 px-0">
                            <div
                                :class="[`text-h5`, active && `mb-1`]"
                                class="transition-swing"
                            >
                                레이팅
                            </div>
                        </v-col>
                        <v-col cols="1" class="mx-0 px-0">
                            <v-btn icon>
                                <v-icon v-if="toggleActiveHorse['trendRating']">
                                    mdi-chevron-up
                                </v-icon>
                                <v-icon v-else> mdi-chevron-down</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="1" class="mx-0 px-0">
                            <v-btn icon @click="close1ChartHorseDetail('trendRating')">
                                <v-icon small> mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                    </v-card-title>

                    <v-sheet class="px-5">
                        <v-expand-transition>
                            <v-responsive v-if="toggleActiveHorse['trendRating']">
                                <v-row class="px-5 py-0 my-0">
                                    <v-col cols="12"
                                           class="py-0 my-0 px-0 mx-0 align-center text-subtitle-1 grey--text text--darken-4 text-right">
                                        {{ infoHorseAll["id"] }}
                                    </v-col>
                                    <v-col cols="12"
                                           class="py-0 my-0 px-0 mx-0 text-subtitle-1 font-weight-light  black--text text--darken-4 text-right">
                                        최근 성적 기반 레이팅 트렌드
                                    </v-col>
                                </v-row>
                                <v-row class="px-5 align-center">
                                    <v-col cols="3" class="px-0 mx-0"></v-col>
                                    <v-col cols="1" class="px-0 mx-0 align-center">
                                        <v-icon>mdi-calendar-month</v-icon>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['trendRating']['all']" x-small elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text deep-orange lighten-2">모든
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('trendRating','all')"
                                               class="text-center text-overline deep-orange--text grey lighten-3">모든
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['trendRating']['latest9']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text brown lighten-2">최근9
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('trendRating','latest9')"
                                               class="text-center text-overline brown--text grey lighten-3">최근9
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['trendRating']['latest6']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text green lighten-2">최근6
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('trendRating','latest6')"
                                               class="text-center text-overline green--text grey lighten-3">최근6
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['trendRating']['latest3']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text blue lighten-2">최근3
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('trendRating','latest3')"
                                               class="text-center text-overline blue--text grey lighten-3">최근3
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <chart-trend-rating
                                    :chart-data="chartDataTrendRating"
                                >
                                </chart-trend-rating>
                            </v-responsive>
                        </v-expand-transition>
                    </v-sheet>
                </v-card>
                <!--  <v-divider class="my-0 mx-7" />-->
            </v-col>

            <!--  -->
            <!-- 트랜드 - 마체중 -->
            <v-col cols="12" class="mx-0 my-1 pa-0" v-if="addHorseChartItems['trendWeight']['opened']">
                <v-card elevation="0" class="pa-0 ma-0">
                    <v-card-title
                        class="headline px-5 py-0"
                        @click="toggleExpand1ChartHorseDetail('trendWeight')"
                    >
                        <!-- <v-icon>mdi-chart-line</v-icon> -->

                        <v-col cols="5" class="mx-0 px-0">
                            <div
                                :class="[`text-overline`, active && `mb-1`]"
                                class="transition-swing"
                            >
                                Trend
                            </div>
                        </v-col>
                        <v-col cols="5" class="mx-0 px-0">
                            <div
                                :class="[`text-h5`, active && `mb-1`]"
                                class="transition-swing"
                            >
                                마체중
                            </div>
                        </v-col>
                        <v-col cols="1" class="mx-0 px-0">
                            <v-btn icon>
                                <v-icon v-if="toggleActiveHorse['trendWeight']">
                                    mdi-chevron-up
                                </v-icon>
                                <v-icon v-else> mdi-chevron-down</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="1" class="mx-0 px-0">
                            <v-btn icon @click="close1ChartHorseDetail('trendWeight')">
                                <v-icon small> mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                    </v-card-title>

                    <v-sheet class="px-5">
                        <v-expand-transition>
                            <v-responsive v-if="toggleActiveHorse['trendWeight']">
                                <v-row class="px-5 py-0 my-0">
                                    <v-col cols="12"
                                           class="py-0 my-0 px-0 mx-0 align-center text-subtitle-1 grey--text text--darken-4 text-right">
                                        {{ infoHorseAll["horseName"] }}
                                    </v-col>
                                    <v-col cols="12"
                                           class="py-0 my-0 px-0 mx-0 text-subtitle-1 font-weight-light  black--text text--darken-4 text-right">
                                        최근 성적 기반 마체중 트렌드
                                    </v-col>
                                </v-row>
                                <v-row class="px-5 align-center">
                                    <v-col cols="3" class="px-0 mx-0"></v-col>
                                    <v-col cols="1" class="px-0 mx-0 align-center">
                                        <v-icon>mdi-calendar-month</v-icon>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['trendWeight']['all']" x-small elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text deep-orange lighten-2">모든
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('trendWeight','all')"
                                               class="text-center text-overline deep-orange--text grey lighten-3">모든
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['trendWeight']['latest9']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text brown lighten-2">최근9
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('trendWeight','latest9')"
                                               class="text-center text-overline brown--text grey lighten-3">최근9
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['trendWeight']['latest6']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text green lighten-2">최근6
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('trendWeight','latest6')"
                                               class="text-center text-overline green--text grey lighten-3">최근6
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0">
                                        <v-btn v-if="radioButtonRangeHorse['trendWeight']['latest3']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text blue lighten-2">최근3
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeHorse('trendWeight','latest3')"
                                               class="text-center text-overline blue--text grey lighten-3">최근3
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <chart-trend-weight
                                    :chart-data="chartDataTrendWeight"
                                >
                                </chart-trend-weight>
                            </v-responsive>
                        </v-expand-transition>
                    </v-sheet>
                </v-card>
                <!-- <v-divider class="my-0 mx-7" />-->
            </v-col>
        </v-row>
        <v-row class="mx-4 my-3 pa-0" v-if="addButtonActiveHorse">
            <v-col cols="12" v-if="true" class="pa-0 ma-0">
                <v-btn
                    block
                    elevation="3"
                    x-large
                    tile
                    color="grey lighten-5"
                    @click="toggleExpandChartListHorse()"
                >
                    <div class="text-center text-subtitle-1 text--black font-weight-medium"
                         v-if="!toggleActiveHorse['addChartList']">
                        {{ toggleBtnName }}
                        <v-icon small color="black">
                            mdi-plus
                        </v-icon>
                    </div>
                    <div class="text-center text-subtitle-1 text--black font-weight-medium" v-else>
                        닫기
                        <v-icon small color="black">
                            mdi-close
                        </v-icon>
                    </div>
                </v-btn>
            </v-col>
            <v-col cols="12" v-else class="pa-0 ma-0"></v-col>

            <v-expand-transition>
                <v-responsive v-if="toggleActiveHorse['addChartList']">
                    <v-col cols="12" class="pa-0 ma-0" v-for="(value,name) in addHorseChartItems" :key="name">
                        <v-btn
                            block
                            elevation="2"
                            medium
                            tile
                            color="grey lighten-3"
                            @click="load1ChartHorseDetail(name)"
                            v-if="!value['opened']"
                        >
                            <v-row>
                                <v-col cols="3" class="px-0 mx-0">
                                    <div
                                        :class="[`text-overline`, active ]"
                                        class="transition-swing"
                                    >
                                        {{ value["label1"] }}
                                    </div>
                                </v-col>
                                <v-col cols="6" class="px-0 mx-0 mt-1">
                                    <div
                                        :class="[`text-body-1`, active ]"
                                        class="transition-swing"
                                    >
                                        {{ value["label2"] }}
                                    </div>
                                </v-col>
                                <v-col cols="3" class="px-0 mx-0 mt-5 py-0">
                                    <v-icon small>
                                        mdi-plus
                                    </v-icon>
                                </v-col>

                            </v-row>
                        </v-btn>
                    </v-col>
                </v-responsive>
            </v-expand-transition>
        </v-row>
        <v-row class="my-2" v-else></v-row>
    </v-card>

    <!-- DETAIL-RACE-->
    <v-card v-else-if="this.card_title['target'] == 'race'"
            outlined elevation="2" :id="this.card_title['id']" class="my-3 pa-0" :color="this.colorCard"
            :loading="loading">

        <!-- 1. Headline -->
        <v-card-title class="headline">
            <v-spacer />
            <div @click="copyClipboardDetailURL()">
                <b> {{ this.getLocationString(card_title["race"]["meet"]) }} {{ card_title["race"]["date"]
                    }}-{{ card_title["race"]["no"] }}</b>
                <v-btn icon>
                    <v-icon small>mdi-link-variant</v-icon>
                </v-btn>
            </div>
            <v-overlay :value="showOverlayNotiShareURL">
                <v-btn
                    class="white--text text-body-1 font-weight-regular"
                    color="grey darken-4"
                    x-large
                    @click="showOverlayNotiShareURL = false; overlayNotiShareUrlMessage='';"
                >
                    {{ overlayNotiShareUrlMessage }}
                </v-btn>
            </v-overlay>

            <v-spacer />
            <v-btn icon @click="renewCardRaceDataAll()">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn icon @click="closeDetailCard()">
                <v-icon> mdi-close</v-icon>
            </v-btn>
        </v-card-title>

        <!--  핵심!!! Vue LifeCycle Updated 상태로 전환하는 방법. 향후 이 방법으로 Renewer 하는 방법 변경되어야함  -->
        {{ currTS.slice(0,0) }}

        <!--  -->
        <!-- 2. Race 기본정보 Chips -->
        <v-card class="mx-1 my-1 pa-0" :color="this.colorChipCard" outlined elevation="0">
            <v-tooltip top v-for="(item, index) in raceChips" :key="index">
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                        <v-chip
                            :color="item.color"
                            class="ma-1"
                            label
                            small
                            outlined
                            text-color="grey darken-2"
                        >
                            <v-icon left>
                                {{ item.icon }}
                            </v-icon>
                            {{ item.text }}
                        </v-chip>
                    </span>
                </template>

                <span> {{ item.label }} </span>
            </v-tooltip>
        </v-card>

        <!-- https://github.com/sitepoint-editors/vue-charts/tree/master/src/views -->
        <v-row class="mx-1 my-1 pa-0">

            <!--  -->
            <!-- VS - 구간속도 -->


            <!--            <v-col cols="12" class="mx-0 my-1 pa-0" v-if="addHorseChartItems['trendWeight']['opened']">-->
            <!--                <v-card elevation="0" class="pa-0 ma-0">-->

            <v-col cols="12" class="mx-0 my-1 pa-0" v-if="addRaceChartItems['versusSpeeds']['opened']">
                <v-card elevation="0" class="pa-0 ma-0">
                    <v-card-title
                        class="headline px-5 py-0"
                        @click="toggleExpand1ChartRaceDetail('versusSpeeds')"
                    >
                        <v-col cols="5" class="mx-0 px-0">
                            <div
                                :class="[`text-overline`, active && `mb-1`]"
                                class="transition-swing"
                            >
                                VERSUS
                            </div>
                        </v-col>
                        <v-col cols="5" class="mx-0 px-0">
                            <div
                                :class="[`text-h5`, active && `mb-1`]"
                                class="transition-swing"
                            >
                                구간속도
                            </div>
                        </v-col>
                        <v-col cols="1" class="mx-0 px-0">
                            <v-btn icon>
                                <v-icon v-if="toggleActiveRace['versusSpeeds']">
                                    mdi-chevron-up
                                </v-icon>
                                <v-icon v-else> mdi-chevron-down</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="1" class="mx-0 px-0">
                            <v-btn icon @click="close1ChartRaceDetail('versusSpeeds')">
                                <v-icon small> mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                    </v-card-title>

                    <v-sheet class="px-5">
                        <v-expand-transition>
                            <v-responsive v-if="toggleActiveRace['versusSpeeds']">
                                <v-row class="px-5 py-0 my-0">
                                    <v-col cols="12"
                                           class="py-0 my-0 px-0 mx-0 align-center text-subtitle-1 grey--text text--darken-4 text-right">
                                        {{ getLocationString(infoRaceAll["raceMeet"]) }} - {{ infoRaceAll["raceDate"] }}
                                        - {{ infoRaceAll["raceNo"] }} ({{ infoRaceAll["raceDistance"] }}m)
                                    </v-col>
                                    <v-col cols="12"
                                           class="py-0 my-0 px-0 mx-0 text-subtitle-1 font-weight-light  black--text text--darken-4 text-right">
                                        최근 {{ infoRaceAll["raceDistance"] }}m 성적 기반 구간속도
                                    </v-col>
                                </v-row>
                                <v-row class="px-5 py-1 my-1 align-center" v-if="!isEmptyChartDataVersusSpeeds">
                                    <v-col cols="3" class="px-0 mx-0 py-0 my-0"></v-col>
                                    <v-col cols="1" class="px-0 mx-0 py-0 my-0 align-center">
                                        <v-icon>mdi-calendar-month</v-icon>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0 py-0 my-0">
                                        <v-btn v-if="radioButtonRangeRace['versusSpeedsLatest']['all']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text deep-orange lighten-2">모든
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeRace('versusSpeedsLatest','all')"
                                               class="text-center text-overline deep-orange--text grey lighten-3">모든
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0 py-0 my-0">
                                        <v-btn v-if="radioButtonRangeRace['versusSpeedsLatest']['latest9']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text brown lighten-2">최근9
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeRace('versusSpeedsLatest','latest9')"
                                               class="text-center text-overline brown--text grey lighten-3">최근9
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0 py-0 my-0">
                                        <v-btn v-if="radioButtonRangeRace['versusSpeedsLatest']['latest6']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text green lighten-2">최근6
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeRace('versusSpeedsLatest','latest6')"
                                               class="text-center text-overline green--text grey lighten-3">최근6
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0 py-0 my-0">
                                        <v-btn v-if="radioButtonRangeRace['versusSpeedsLatest']['latest3']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text blue lighten-2">최근3
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeRace('versusSpeedsLatest','latest3')"
                                               class="text-center text-overline blue--text grey lighten-3">최근3
                                        </v-btn>
                                    </v-col>
                                </v-row>

                                <v-row class="px-5 py-1 my-1 align-center" v-if="!isEmptyChartDataVersusSpeeds">
                                    <v-col cols="5" class="px-0 mx-0 py-0 my-0"></v-col>
                                    <v-col cols="1" class="px-0 mx-0 py-0 my-0 align-center">
                                        <v-icon>mdi-clock-fast</v-icon>
                                    </v-col>
                                    <v-col cols="2" class="px-0 py-0 my-0 mx-0">
                                        <v-btn v-if="radioButtonRangeRace['versusSpeedsMinMaxAvg']['avg']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text green lighten-2">평균
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeRace('versusSpeedsMinMaxAvg','avg')"
                                               class="text-center text-overline green--text grey lighten-3">평균
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0 py-0 my-0">
                                        <v-btn v-if="radioButtonRangeRace['versusSpeedsMinMaxAvg']['max']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text brown lighten-2">최고
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeRace('versusSpeedsMinMaxAvg','max')"
                                               class="text-center text-overline brown--text grey lighten-3">최고
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0 py-0 my-0">
                                        <v-btn v-if="radioButtonRangeRace['versusSpeedsMinMaxAvg']['min']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text blue lighten-2">최저
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeRace('versusSpeedsMinMaxAvg','min')"
                                               class="text-center text-overline blue--text grey lighten-3">최저
                                        </v-btn>
                                    </v-col>
                                </v-row>

                                <chart-versus-speed v-if="!isEmptyChartDataVersusSpeeds"
                                                    :chart-data="chartDataVersusSpeeds"
                                >
                                </chart-versus-speed>

                                <v-card
                                    v-if=!isEmptyChartDataVersusSpeeds&&infoRaceAll.ExistExceptHIDs
                                    elevation="0"
                                    class="mt-4 mb-2 ml-5 mr-0  px-0"
                                >
                                    <v-card-text class="text-left text-body-2 ma-0 pa-0">
                                        <div v-for="(item, index) in infoRaceAll.ExceptHIDs" :key="index">
                                            • <b> {{ getHorseName(item) }} </b> : 과거 {{ infoRaceAll["raceDistance"] }}m 경주 기록정보가 없습니다.
                                        </div>
                                    </v-card-text>
                                </v-card>

                                <v-card
                                    v-if=isEmptyChartDataVersusSpeeds
                                    elevation="0"
                                    class="mt-4 mb-2 ml-5 mr-0  px-0"
                                >
                                    <v-row justify="center mb-3">
                                        <v-img
                                            max-height="64"
                                            max-width="64"
                                            class="text-center"
                                            src="../assets/logo_128x128.png"
                                            alt="경마-말고고-경마분석-경마문화-서울경마-제주경마-부경경마-문자-데이터-없음-알림"
                                        ></v-img>
                                    </v-row>
                                    <v-card-text
                                        class="text-center text-h6 blue-grey--text font-weight-black ma-0 pa-0">
                                        정보가 없습니다
                                    </v-card-text>
                                    <v-card-text class="text-body-2 text-center ma-0 pa-0">
                                        <div class="black--text font-weight-medium">
                                            {{ getDateString(infoRaceAll["raceDate"]) }}이전 +
                                            {{ infoRaceAll["raceDistance"] }}m 경주
                                        </div>
                                        해당 경주마들의 기록정보가 없습니다.
                                    </v-card-text>
                                </v-card>

                            </v-responsive>
                        </v-expand-transition>
                    </v-sheet>
                </v-card>
            </v-col>

            <!--  -->
            <!-- VS - 구간기록 -->
            <v-col cols="12" class="mx-0 my-1 pa-0" v-if="addRaceChartItems['versusRecords']['opened']">
                <v-card elevation="0" class="pa-0 ma-0">
                    <v-card-title
                        class="headline px-5 py-0"
                        @click="toggleExpand1ChartRaceDetail('versusRecords')"
                    >
                        <v-col cols="5" class="mx-0 px-0">
                            <div :class="[`text-overline`, active && `mb-1`]" class="transition-swing"> VERSUS</div>
                        </v-col>
                        <v-col cols="5" class="mx-0 px-0">
                            <div :class="[`text-h5`, active && `mb-1`]" class="transition-swing"> 구간기록</div>
                        </v-col>
                        <v-col cols="1" class="mx-0 px-0">
                            <v-btn icon>
                                <v-icon v-if="toggleActiveRace['versusRecords']">
                                    mdi-chevron-up
                                </v-icon>
                                <v-icon v-else> mdi-chevron-down</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="1" class="mx-0 px-0">
                            <v-btn icon @click="close1ChartRaceDetail('versusRecords')">
                                <v-icon small> mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                    </v-card-title>

                    <v-sheet class="px-5">
                        <v-expand-transition>
                            <v-responsive v-if="toggleActiveRace['versusRecords']">
                                <v-row class="px-5 py-0 my-0">
                                    <v-col cols="12"
                                           class="py-0 my-0 px-0 mx-0 align-center text-subtitle-1 grey--text text--darken-4 text-right">
                                        {{ getLocationString(infoRaceAll["raceMeet"]) }} - {{ infoRaceAll["raceDate"] }}
                                        - {{ infoRaceAll["raceNo"] }} ({{ infoRaceAll["raceDistance"] }}m)
                                    </v-col>
                                    <v-col cols="12"
                                           class="py-0 my-0 px-0 mx-0 text-subtitle-1 font-weight-light  black--text text--darken-4 text-right">
                                        최근 {{ infoRaceAll["raceDistance"] }}m 성적 기반 구간기록
                                    </v-col>
                                </v-row>
                                <v-row class="px-5 py-1 my-1 align-center" v-if="!isEmptyChartDataVersusRecords">
                                    <v-col cols="3" class="px-0 mx-0 py-0 my-0"></v-col>
                                    <v-col cols="1" class="px-0 mx-0 py-0 my-0 align-center">
                                        <v-icon>mdi-calendar-month</v-icon>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0 py-0 my-0">
                                        <v-btn v-if="radioButtonRangeRace['versusRecordsLatest']['all']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text deep-orange lighten-2">모든
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeRace('versusRecordsLatest','all')"
                                               class="text-center text-overline deep-orange--text grey lighten-3">모든
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0 py-0 my-0">
                                        <v-btn v-if="radioButtonRangeRace['versusRecordsLatest']['latest9']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text brown lighten-2">최근9
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeRace('versusRecordsLatest','latest9')"
                                               class="text-center text-overline brown--text grey lighten-3">최근9
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0 py-0 my-0">
                                        <v-btn v-if="radioButtonRangeRace['versusRecordsLatest']['latest6']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text green lighten-2">최근6
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeRace('versusRecordsLatest','latest6')"
                                               class="text-center text-overline green--text grey lighten-3">최근6
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0 py-0 my-0">
                                        <v-btn v-if="radioButtonRangeRace['versusRecordsLatest']['latest3']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text blue lighten-2">최근3
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeRace('versusRecordsLatest','latest3')"
                                               class="text-center text-overline blue--text grey lighten-3">최근3
                                        </v-btn>
                                    </v-col>
                                </v-row>

                                <v-row class="px-5 py-1 my-1 align-center" v-if="!isEmptyChartDataVersusRecords">
                                    <v-col cols="5" class="px-0 mx-0 py-0 my-0"></v-col>
                                    <v-col cols="1" class="px-0 mx-0 py-0 my-0 align-center">
                                        <v-icon>mdi-clock-fast</v-icon>
                                    </v-col>
                                    <v-col cols="2" class="px-0 py-0 my-0 mx-0">
                                        <v-btn v-if="radioButtonRangeRace['versusRecordsMinMaxAvg']['avg']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text green lighten-2">평균
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeRace('versusRecordsMinMaxAvg','avg')"
                                               class="text-center text-overline green--text grey lighten-3">평균
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0 py-0 my-0">
                                        <v-btn v-if="radioButtonRangeRace['versusRecordsMinMaxAvg']['max']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text brown lighten-2">최고
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeRace('versusRecordsMinMaxAvg','max')"
                                               class="text-center text-overline brown--text grey lighten-3">최고
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="px-0 mx-0 py-0 my-0">
                                        <v-btn v-if="radioButtonRangeRace['versusRecordsMinMaxAvg']['min']" x-small
                                               elevation="0"
                                               block
                                               tile
                                               class="text-center text-overline black--text blue lighten-2">최저
                                        </v-btn>
                                        <v-btn v-else x-small elevation="0"
                                               block
                                               tile
                                               @click="selectRadioButtonRangeRace('versusRecordsMinMaxAvg','min')"
                                               class="text-center text-overline blue--text grey lighten-3">최저
                                        </v-btn>
                                    </v-col>
                                </v-row>

                                <chart-versus-record v-if="!isEmptyChartDataVersusRecords"
                                                     :chart-data="chartDataVersusRecords"
                                >
                                </chart-versus-record>

                                <v-card
                                    v-if=!isEmptyChartDataVersusRecords&&infoRaceAll.ExistExceptHIDs
                                    elevation="0"
                                    class="mt-4 mb-2 ml-5 mr-0  px-0"
                                >
                                    <v-card-text class="text-left text-body-2 ma-0 pa-0">
                                        <div v-for="(item, index) in infoRaceAll.ExceptHIDs" :key="index">
                                            • <b> {{ getHorseName(item) }} </b> : 과거 {{ infoRaceAll["raceDistance"] }}m 경주 기록정보가 없습니다.
                                        </div>
                                    </v-card-text>

                                </v-card>

                                <v-card
                                    v-if=isEmptyChartDataVersusRecords
                                    elevation="0"
                                    class="mt-4 mb-2 ml-5 mr-0  px-0"
                                >
                                    <v-row justify="center mb-3">
                                        <v-img
                                            max-height="64"
                                            max-width="64"
                                            class="text-center"
                                            src="../assets/logo_128x128.png"
                                            alt="경마-말고고-경마분석-경마문화-서울경마-제주경마-부경경마-문자-데이터-없음-알림"
                                        ></v-img>
                                    </v-row>
                                    <v-card-text
                                        class="text-center text-h6 blue-grey--text font-weight-black ma-0 pa-0">
                                        정보가 없습니다
                                    </v-card-text>
                                    <v-card-text class="text-body-2 text-center ma-0 pa-0">
                                        <div class="black--text font-weight-medium">
                                            {{ getDateString(infoRaceAll["raceDate"]) }}이전 +
                                            {{ infoRaceAll["raceDistance"] }}m 경주
                                        </div>
                                        해당 경주마들의 기록정보가 없습니다.
                                    </v-card-text>
                                </v-card>

                            </v-responsive>
                        </v-expand-transition>
                    </v-sheet>
                </v-card>
                <!-- <v-divider class="my-0 mx-7" />-->
            </v-col>

        </v-row>

        <v-row class="mx-4 my-3 pa-0" v-if="addButtonActiveRace">
            <v-col cols="12" v-if="true" class="pa-0 ma-0">
                <v-btn
                    block
                    elevation="3"
                    x-large
                    tile
                    color="grey lighten-5"
                    @click="toggleExpandChartListRace()"
                >
                    <div class="text-center text-subtitle-1 text--black font-weight-medium"
                         v-if="!toggleActiveRace['addChartList']">
                        {{ toggleBtnName }}
                        <v-icon small color="black">
                            mdi-plus
                        </v-icon>
                    </div>
                    <div class="text-center text-subtitle-1 text--black font-weight-medium" v-else>
                        닫기
                        <v-icon small color="black">
                            mdi-close
                        </v-icon>
                    </div>
                </v-btn>
            </v-col>
            <v-col cols="12" v-else class="pa-0 ma-0"></v-col>

            <v-expand-transition>
                <v-responsive v-if="toggleActiveRace['addChartList']">
                    <v-col cols="12" class="pa-0 ma-0" v-for="(value,name) in addRaceChartItems" :key="name">
                        <v-btn
                            block
                            elevation="2"
                            medium
                            tile
                            color="grey lighten-3"
                            @click="load1ChartRaceDetail(name)"
                            v-if="!value['opened']"
                        >
                            <v-row>
                                <v-col cols="3" class="px-0 mx-0">
                                    <div
                                        :class="[`text-overline`, active ]"
                                        class="transition-swing"
                                    >
                                        {{ value["label1"] }}
                                    </div>
                                </v-col>
                                <v-col cols="6" class="px-0 mx-0 mt-1">
                                    <div
                                        :class="[`text-body-1`, active ]"
                                        class="transition-swing"
                                    >
                                        {{ value["label2"] }}
                                    </div>
                                </v-col>
                                <v-col cols="3" class="px-0 mx-0 mt-5 py-0">
                                    <v-icon small>
                                        mdi-plus
                                    </v-icon>
                                </v-col>
                            </v-row>
                        </v-btn>
                    </v-col>
                </v-responsive>
            </v-expand-transition>
        </v-row>

        <v-row class="my-2" v-else></v-row>

    </v-card>
</template>

<script>
import TrendPrizeMixedLineBar from "@/components/chart/TrendPrizeMixedLineBar";
import TrendRatingMixedLineBar from "@/components/chart/TrendRatingMixedLineBar";
import TrendWeightMixedLineBar from "@/components/chart/TrendWeightMixedLineBar";
import MatchHorseNumberBar from "@/components/chart/MatchHorseNumberBar";
import MatchJockeyBar from "@/components/chart/MatchJockeyBar";
import MatchToolBar from "@/components/chart/MatchToolBar";
import MatchRaceDistanceBar from "@/components/chart/MatchRaceDistanceBar";
import MatchRaceTrackConditionBar from "@/components/chart/MatchRaceTrackConditionBar";
import AnalyticsSpeedMixedLineBar from "@/components/chart/AnalyticsSpeedMixedLineBar";
import VersusSpeedMixedLineBar from "@/components/chart/VersusSpeedMixedLineBar";
import VersusRecordMixedLineBar from "@/components/chart/VersusRecordMixedLineBar";
import firebase from "firebase";

export default {
    name: "DetailCard",
    active: "",
    components: {
        "chart-trend-prize": TrendPrizeMixedLineBar,
        "chart-trend-rating": TrendRatingMixedLineBar,
        "chart-trend-weight": TrendWeightMixedLineBar,
        "chart-match-horse-number": MatchHorseNumberBar,
        "chart-match-jockey": MatchJockeyBar,
        "chart-match-tool": MatchToolBar,
        "chart-match-race-distance": MatchRaceDistanceBar,
        "chart-match-race-track-condition": MatchRaceTrackConditionBar,
        "chart-analytics-speed": AnalyticsSpeedMixedLineBar,
        "chart-versus-speed": VersusSpeedMixedLineBar,
        "chart-versus-record": VersusRecordMixedLineBar
    },
    created() {
        console.log("[104][DetailCard.vue][created] IN : this.card_title-id : ", this.card_title["id"]);
        this.renewData();
    },
    beforeMount() {
        console.log("[104][DetailCard.vue][beforeMount] this.card_title-id : ", this.card_title["id"]);
    },
    mounted() {
        console.log("[104][DetailCard.vue][mounted] this.card_title-id : ", this.card_title["id"]);
        this.renewData();
    },
    beforeUpdate() {
        console.log("[104][DetailCard.vue][beforeUpdate] this.card_title-id : ", this.card_title["id"], this.clickRangeToggle);
        if (this.clickRangeToggle) {
            this.clickRangeToggle = false;
        } else {
            this.initData();
            this.renewData();
        }
    },
    updated() {
        console.log("[104][DetailCard.vue][updated] this.card_title-id : ", this.card_title["id"]);
    },
    props: [
        "card_title"
    ],
    computed: {},
    beforeDestroy() {
        // TODO Favorite 의 수정이 발생했다면, 이를 fireStore에 갱신해야한다.
        console.log("[104][DetailCard.vue][beforeDestroy] this.card_title-id : ", this.card_title["id"]);
        this.writeFireStoreFavorite();
    },
    methods: {
        analyticsLogEvent(target) {
            const hID = this.card_title["id"];
            let userID = this.$store.getters["userAccountInfoStore/GET_USER_ACCOUNT_INFO"].uid;
            if (!(userID == undefined || userID == null || userID == "")) {  // 로그인 상태
                userID = "none";
            }
            firebase.analytics().logEvent(target, {
                HID: hID,
                UserID: userID
            });
        },
        renewData() {
            console.log("[104][DetailCard.vue][renewData] this.card_title-id : ", this.card_title["id"]);
            this.fillCardColorAndToggleBtnName();
            this.fillDataChartAll();
            this.checkFavorite();
            this.currTS = new Date().toISOString();
        },
        fillCardColorAndToggleBtnName() {
            let colors = ["red", "pink", "purple", "deep-purple", "indigo", "blue", "light-blue", "cyan", "teal", "green", "light-green", "lime", "yellow", "amber", "orange", "deep-orange", "brown"];
            let currID = this.card_title["id"];

            let hashNum = this.hashFnv32a(currID);
            let colorIndex = hashNum % 17;
            let baseColor = colors[colorIndex];
            this.colorCard = baseColor + " lighten-4";
            this.colorChipCard = baseColor + " lighten-5";


            // horse {{ card_title["horse"]["name"] }}
            // race {{ this.getLocationString(card_title["race"]["meet"]) }} {{ card_title["race"]["date"]}}-{{ card_title["race"]["no"] }}


            if (this.card_title["target"] == "horse") {
                this.toggleBtnName = "#" + this.card_title["horse"]["name"] + " 분석 열기";
            } else if (this.card_title["target"] == "race") {
                this.toggleBtnName = "#" + this.getLocationString(this.card_title["race"]["meet"]) + "-" + this.card_title["race"]["date"] + "-" + this.card_title["race"]["no"] + " 분석 열기";
            }
        },
        hashFnv32a(str) {
            /*jshint bitwise:false */
            var i, l, hval = 0x811c9dc5;

            for (i = 0, l = str.length; i < l; i++) {
                hval ^= str.charCodeAt(i);
                hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
            }
            return hval >>> 0;
        },

        checkFavorite() {
            const userID = this.$store.getters["userAccountInfoStore/GET_USER_ACCOUNT_INFO"].uid;
            if (!(userID == undefined || userID == null || userID == "")) {  // 로그인 상태
                let tmpFavorite = this.$store.getters["favoriteStore/GET_FAVORITE"];
                if ("ts" in tmpFavorite) {
                    tmpFavorite["items"] = tmpFavorite["items"].filter(i => {
                        return i["favorite"];
                    });
                }
                let isFavorite = false;
                tmpFavorite["items"].some(ff => {
                    if (ff["id"] == this.card_title["id"]) {
                        isFavorite = true;
                    }
                    return isFavorite;
                });
                if (isFavorite) {
                    this.favoriteCard = true;
                }
            }
        },
        copyClipboardDetailURL() {
            this.analyticsLogEvent("click_horseDetail_url");
            this.clickRangeToggle = true;
            let dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.value = this.rootPathShareURL + this.card_title["id"];
            dummy.select();

            let isHorse = false;
            let isRace = false;
            if (this.card_title["target"] === "horse") {
                isHorse = true;
            } else if (this.card_title["target"] === "race") {
                isRace = true;
            }

            let successMSG = "";
            let failureMSG = "";
            if (isHorse) {
                successMSG = "'" + this.card_title["horse"]["name"] + "' 공유주소 클립보드 복사됨 :D"; // + this.rootPathShareURL + this.card_title["id"];
                failureMSG = "'" + this.card_title["horse"]["name"] + "' 공유링크를 클립보드에 복사 실패됨.";
            } else if (isRace) {
                const raceNameTmp = this.getLocationString(this.card_title["race"]["meet"]) + "-" + this.card_title["race"]["date"] + "-" + this.card_title["race"]["no"];
                successMSG = "'" + raceNameTmp + "' 공유주소 클립보드 복사됨 :D"; // + this.rootPathShareURL + this.card_title["id"];
                failureMSG = "'" + raceNameTmp + "' 공유링크를 클립보드에 복사 실패됨.";
            }

            let successful = document.execCommand("copy");


            if (!successful) {
                const oldContentEditable = dummy.contentEditable,
                    oldReadOnly = dummy.readOnly,
                    range = document.createRange();

                dummy.contentEditable = true;
                dummy.readOnly = false;
                range.selectNodeContents(dummy);

                const s = window.getSelection();
                s.removeAllRanges();
                s.addRange(range);

                dummy.setSelectionRange(0, 999999);

                dummy.contentEditable = oldContentEditable;
                dummy.readOnly = oldReadOnly;

                successful = document.execCommand("copy");
            }
            let msg = successful ? successMSG : failureMSG;
            this.overlayNotiShareUrlMessage = msg;
            document.body.removeChild(dummy);
            this.showOverlayNotiShareURL = true;
        },
        clickFavoriteCard() {
            this.clickRangeToggle = true;
            const userInfo = this.$store.getters["userAccountInfoStore/GET_USER_ACCOUNT_INFO"];
            const userID = userInfo.uid;
            const userEmail = userInfo.email;
            if (!(userID == undefined || userID == null || userID == "")) {  // 로그인 상태
                this.favoriteCard = !this.favoriteCard;
                if (this.favoriteCard) {
                    this.analyticsLogEvent("click_favorite_on");
                    this.card_title["favorite"] = true;
                    this.$store.commit("favoriteStore/MUT_FAVORITE_PUSH_UNIQUE", this.card_title);
                } else {
                    this.analyticsLogEvent("click_favorite_off");
                    this.card_title["favorite"] = false;
                    this.$store.commit("favoriteStore/MUT_FAVORITE_REMOVE_UNIQUE", this.card_title);
                }
                const Favorite = this.$store.getters["favoriteStore/GET_FAVORITE"];
                Favorite.ts = new Date().getTime();
                firebase.firestore().collection("UserDetail").doc(userEmail).set({ Favorite }).then(function() {
                }).catch(function(error) {
                    console.error("[Favorite] Failure to Write Favorite: ", error);
                });
            } else {  // 로그아웃 상태
                alert(this.cannotFavoriteMessageOfDetailCard);
            }
        },
        writeFireStoreFavorite() {
            // TODO favoriteStore/MUT_FAVORITE_OVERWRITE => fireStore-Favorite
        },

        updateRaceDetailFromFireStore() {
            console.log("[104][DetailCard.vue][updateRaceDetailFromFireStore] this.card_title-id : ", this.card_title["id"]);
            this.loading = true;
            this.analyticsLogEvent("firestore_read_raceDetail");
            const rID = this.card_title["id"];
            let raceDetailShell = {
                id: rID,
                raceDate: 19820612,
                raceHorses: [],
                raceMeet: 0,
                raceAgeLimit: "",
                raceBurdenType: "",
                raceClass: "",
                raceDistance: 0,
                raceName: "",
                raceNo: "",
                racePrize1: "",
                racePrize1A: "",
                racePrize2: "",
                racePrize2A: "",
                racePrize3: "",
                racePrize3A: "",
                racePrize4: "",
                racePrize4A: "",
                racePrize5: "",
                racePrize5A: "",
                raceRating: "",
                raceStartTime: "",
                raceVersusSpeeds: [],
                raceVersusRecords: []
                // racePredictScroeTop3 todo
            };
            let isExist = false;
            this.$store.getters["detailCardStore/GET_DETAIL_RACE"].some(s => {
                if (s.id == rID) {
                    isExist = true;
                    return true;
                }
            });
            if (!isExist) {
                // 기존 존재 안하면, 빈껍질을 넣어준다.
                this.$store.commit("detailCardStore/MUT_DETAIL_RACE_PUSH_UNIQUE", raceDetailShell);
            }

            const _this = this;
            firebase.firestore().collection("RaceDetail").doc(rID).get().then(function(doc) {
                raceDetailShell.id = rID;
                raceDetailShell.raceDate = doc.data().Date;
                raceDetailShell.raceHorses = doc.data().Horses;
                raceDetailShell.raceMeet = doc.data().Meet;
                raceDetailShell.raceAgeLimit = doc.data().RaceAgeLimit;
                raceDetailShell.raceBurdenType = doc.data().RaceBurdenType;
                raceDetailShell.raceClass = doc.data().RaceClass;
                raceDetailShell.raceDistance = doc.data().RaceDistance;
                raceDetailShell.raceName = doc.data().RaceName;
                raceDetailShell.raceNo = doc.data().RaceNo;
                raceDetailShell.racePrize1 = doc.data().RacePrize1;
                raceDetailShell.racePrize1A = doc.data().RacePrize1A;
                raceDetailShell.racePrize2 = doc.data().RacePrize2;
                raceDetailShell.racePrize2A = doc.data().RacePrize2A;
                raceDetailShell.racePrize3 = doc.data().RacePrize3;
                raceDetailShell.racePrize3A = doc.data().RacePrize3A;
                raceDetailShell.racePrize4 = doc.data().RacePrize4;
                raceDetailShell.racePrize4A = doc.data().RacePrize4A;
                raceDetailShell.racePrize5 = doc.data().RacePrize5;
                raceDetailShell.racePrize5A = doc.data().RacePrize5A;
                raceDetailShell.raceRating = doc.data().RaceRating;
                raceDetailShell.raceStartTime = doc.data().RaceStartTime;
                raceDetailShell.raceVersusSpeeds = doc.data().VersusSpeeds;
                raceDetailShell.raceVersusRecords = doc.data().VersusRecords;
                // raceDetailShell.racePredictScoreTop3 = doc.data().PredictScoreTop3; // todo

                _this.infoRaceAll = raceDetailShell;
                _this.$store.commit("detailCardStore/MUT_DETAIL_RACE_UPDATE", raceDetailShell);
                _this.loading = false;

            }).catch(function(error) {
                console.error("[104][DetailCard.vue][updateRaceDetailFromFireStore] ", error);
                _this.loading = false;
            });
        },

        updateHorseDetailFromFireStore() {
            console.log("[104][DetailCard.vue][updateHorseDetailFromFireStore] this.card_title-id : ", this.card_title["id"]);
            this.loading = true;
            this.analyticsLogEvent("firestore_read_horseDetail");
            const hID = this.card_title["id"];
            let horseDetailShell = {
                id: hID,
                horseMeet: 0,
                horseID: "",
                horseName: "",
                horseRating: 0,
                horseGrade: "",
                horseSex: "",
                horseHome: "",
                horseTrainer: "",
                horseOwner: "",
                horseBirth: "",
                horseAge: "",
                horseColor: "",
                horseRaceRange: "",
                horseRaceRecord: "",
                horseWinningRate: "",
                horseRegistration: "",
                horsePrizeAll: "",
                horsePrizeLatest6: "",
                horsePrizeLatest3: "",
                trendPrize: [],
                trendRating: [],
                trendWeight: [],
                matchHorseNumber: [],
                matchJockey: [],
                matchTool: [],
                matchRaceDistance: [],
                matchTrackConditionDisc: [],
                matchTrackConditionPercentage: [],
                analyticsSpeed: []
            };
            let isExist = false;
            this.$store.getters["detailCardStore/GET_DETAIL_HORSE"].some(s => {
                if (s.id == hID) {
                    isExist = true;
                    return true;
                }
            });
            if (!isExist) {
                // 기존 존재 안하면, 빈껍질을 넣어준다.
                this.$store.commit("detailCardStore/MUT_DETAIL_HORSE_PUSH_UNIQUE", horseDetailShell);
            }
            const _this = this;
            firebase.firestore().collection("HorseDetail").doc(hID).get().then(function(doc) {
                horseDetailShell.id = doc.data().HID;
                horseDetailShell.horseMeet = doc.data().HorseMeet;
                horseDetailShell.horseID = doc.data().HorseID;
                horseDetailShell.horseName = doc.data().HorseName;
                horseDetailShell.horseRating = doc.data().HorseRating;
                horseDetailShell.horseGrade = doc.data().HorseGrade;
                horseDetailShell.horseSex = doc.data().HorseSex;
                horseDetailShell.horseHome = doc.data().HorseHome;
                const trainerSplits = doc.data().HorseTrainer.split("-");
                if (trainerSplits.length >= 2) {
                    horseDetailShell.horseTrainer = trainerSplits[1];
                }
                const ownerSplits = doc.data().HorseOwner.split("-");
                if (ownerSplits.length >= 2) {
                    horseDetailShell.horseOwner = ownerSplits[1];
                }
                horseDetailShell.horseBirth = doc.data().HorseBirth;
                horseDetailShell.horseAge = doc.data().HorseAge;
                horseDetailShell.horseColor = doc.data().HorseColor;
                horseDetailShell.horseRaceRange = doc.data().HorseRaceRange;
                horseDetailShell.horseRaceRecord = doc.data().HorseRaceRecord;
                horseDetailShell.horseWinningRate = doc.data().HorseWinningRate;
                horseDetailShell.horseRegistration = doc.data().HorseRegistration;
                horseDetailShell.horsePrizeAll = doc.data().HorsePrizeAll;
                horseDetailShell.horsePrizeLatest6 = doc.data().HorsePrizeLatest6;
                horseDetailShell.horsePrizeLatest3 = doc.data().HorsePrizeLatest3;
                horseDetailShell.trendPrize = doc.data().TrendPrize;
                horseDetailShell.trendRating = doc.data().TrendRating;
                horseDetailShell.trendWeight = doc.data().TrendWeight;
                horseDetailShell.matchHorseNumber = doc.data().MatchHorseNumber;
                horseDetailShell.matchJockey = doc.data().MatchJockey;
                horseDetailShell.matchTool = doc.data().MatchTool;
                horseDetailShell.matchRaceDistance = doc.data().MatchRaceDistance;
                horseDetailShell.matchTrackConditionDisc = doc.data().MatchTrackConditionDisc;
                horseDetailShell.matchTrackConditionPercentage = doc.data().MatchTrackConditionPercentage;
                horseDetailShell.analyticsSpeed = doc.data().AnalyticsSpeed;

                _this.infoHorseAll = horseDetailShell;
                _this.$store.commit("detailCardStore/MUT_DETAIL_HORSE_UPDATE", horseDetailShell);
                _this.loading = false;

            }).catch(function(error) {
                console.error("[104][DetailCard.vue][updateHorseDetailFromFireStore] ", error);
                _this.loading = false;
            });
        },
        getLocationString(meet) {
            let loc = "";
            switch (meet) {
                case 1:
                    loc = "서울";
                    break;
                case 2:
                    loc = "제주";
                    break;
                case 3:
                    loc = "부경";
                    break;
            }
            return loc;
        },

        getDateString(tDate) {
            let dateStr = "" + tDate;
            // 20210101
            let dateStrs = dateStr.split("");
            if (dateStrs.length === 8) {
                dateStr = dateStrs[0] + dateStrs[1] + dateStrs[2] + dateStrs[3] + "." + dateStrs[4] + dateStrs[5] + "." + dateStrs[6] + dateStrs[7];
            }

            return dateStr;
        },


        getHorseName(tHID) {

            let splitHID = tHID.split("-");
            if (splitHID.length >= 2) {
                return splitHID[1];
            }

            return tHID;
        },

        getLatestRace() {
            let latestRaceStr = "";
            if (this.radioButtonRangeRace["versusSpeedsLatest"]["all"]) {
                latestRaceStr = "모든";
            } else if (this.radioButtonRangeRace["versusSpeedsLatest"]["latest9"]) {
                latestRaceStr = "최근9";
            } else if (this.radioButtonRangeRace["versusSpeedsLatest"]["latest6"]) {
                latestRaceStr = "최근6";
            } else if (this.radioButtonRangeRace["versusSpeedsLatest"]["latest3"]) {
                latestRaceStr = "최근3";
            }
            return latestRaceStr;
        },

        load1ChartHorseDetail(target) {
            this.analyticsLogEvent("click_open_horseDetail_chart_" + target);
            this.clickRangeToggle = true;
            this.addHorseChartItems[target]["opened"] = true;
            this.toggleExpandChartListHorse();
            let tempIndex = 0;
            Object.keys(this.addHorseChartItems).forEach(f => {
                if (!this.addHorseChartItems[f]["opened"]) {
                    tempIndex = tempIndex + 1;
                }
            });
            if (tempIndex == 0) {
                this.addButtonActiveHorse = false;
            } else {
                this.addButtonActiveHorse = true;
            }
            this.toggleActiveHorse[target] = true;
        },
        load1ChartRaceDetail(target) {
            this.analyticsLogEvent("click_open_raceDetail_chart_" + target);
            this.clickRangeToggle = true;
            this.addRaceChartItems[target]["opened"] = true;
            this.toggleExpandChartListRace();
            let tempIndex = 0;
            Object.keys(this.addRaceChartItems).forEach(f => {
                if (!this.addRaceChartItems[f]["opened"]) {
                    tempIndex = tempIndex + 1;
                }
            });
            if (tempIndex == 0) {
                this.addButtonActiveRace = false;
            } else {
                this.addButtonActiveRace = true;
            }
            this.toggleActiveRace[target] = true;
        },

        close1ChartHorseDetail(target) {
            this.analyticsLogEvent("click_close_horseDetail_chart_" + target);
            this.clickRangeToggle = true;
            this.addHorseChartItems[target]["opened"] = false;
            let tempIndex = 0;
            Object.keys(this.addHorseChartItems).forEach(f => {
                if (!this.addHorseChartItems[f]["opened"]) {
                    tempIndex = tempIndex + 1;
                }
            });
            if (tempIndex == 0) {
                this.addButtonActiveHorse = false;
            } else {
                this.addButtonActiveHorse = true;
            }
        },
        close1ChartRaceDetail(target) {
            this.analyticsLogEvent("click_close_raceDetail_chart_" + target);
            this.clickRangeToggle = true;
            this.addRaceChartItems[target]["opened"] = false;
            let tempIndex = 0;
            Object.keys(this.addRaceChartItems).forEach(f => {
                if (!this.addRaceChartItems[f]["opened"]) {
                    tempIndex = tempIndex + 1;
                }
            });
            if (tempIndex == 0) {
                this.addButtonActiveRace = false;
            } else {
                this.addButtonActiveRace = true;
            }
        },
        selectRadioButtonRangeHorse(target, range) {
            this.clickRangeToggle = true;

            switch (target) {
                case "analyticsSpeedLatest":
                    this.radioButtonRangeHorse[target]["all"] = false;
                    this.radioButtonRangeHorse[target]["latest3"] = false;
                    this.radioButtonRangeHorse[target]["latest6"] = false;
                    this.radioButtonRangeHorse[target]["latest9"] = false;
                    this.radioButtonRangeHorse[target][range] = true;
                    break;
                case "analyticsSpeedMinMaxAvg":
                    this.radioButtonRangeHorse[target]["min"] = false;
                    this.radioButtonRangeHorse[target]["max"] = false;
                    this.radioButtonRangeHorse[target]["avg"] = false;
                    this.radioButtonRangeHorse[target][range] = true;
                    break;
                default:
                    this.radioButtonRangeHorse[target]["all"] = false;
                    this.radioButtonRangeHorse[target]["latest3"] = false;
                    this.radioButtonRangeHorse[target]["latest6"] = false;
                    this.radioButtonRangeHorse[target]["latest9"] = false;
                    this.radioButtonRangeHorse[target][range] = true;
                    break;
            }

            switch (target) {
                case "trendPrize":
                    this.getInfoTrendPrize(range);
                    break;
                case "trendRating":
                    this.getInfoTrendRating(range);
                    break;
                case "trendWeight":
                    this.getInfoTrendWeight(range);
                    break;
                case "matchHorseNumber":
                    this.getInfoMatchHorseNumber(range);
                    break;
                case "matchJockey":
                    this.getInfoMatchJockey(range);
                    break;
                case "matchTool":
                    this.getInfoMatchTool(range);
                    break;
                case "matchRaceDistance":
                    this.getInfoMatchRaceDistance(range);
                    break;
                case "matchRaceTrackCondition":
                    this.getInfoMatchRaceTrackCondition(range);
                    break;
                case "analyticsSpeedLatest":
                    this.getInfoAnalyticsSpeed("Latest", range);
                    break;

                case "analyticsSpeedMinMaxAvg":
                    this.getInfoAnalyticsSpeed("MinMaxAvg", range);
                    break;

            }
        },
        selectRadioButtonRangeRace(target, range) {
            this.clickRangeToggle = true;
            switch (target) {
                case "versusSpeedsLatest":
                    this.radioButtonRangeRace[target]["all"] = false;
                    this.radioButtonRangeRace[target]["latest9"] = false;
                    this.radioButtonRangeRace[target]["latest6"] = false;
                    this.radioButtonRangeRace[target]["latest3"] = false;
                    this.radioButtonRangeRace[target][range] = true;
                    this.getInfoVersusSpeeds(target, range);
                    break;
                case "versusSpeedsMinMaxAvg":
                    this.radioButtonRangeRace[target]["avg"] = false;
                    this.radioButtonRangeRace[target]["max"] = false;
                    this.radioButtonRangeRace[target]["min"] = false;
                    this.radioButtonRangeRace[target][range] = true;
                    this.getInfoVersusSpeeds(target, range);
                    break;
                case "versusRecordsLatest":
                    this.radioButtonRangeRace[target]["all"] = false;
                    this.radioButtonRangeRace[target]["latest9"] = false;
                    this.radioButtonRangeRace[target]["latest6"] = false;
                    this.radioButtonRangeRace[target]["latest3"] = false;
                    this.radioButtonRangeRace[target][range] = true;
                    this.getInfoVersusRecords(target, range);
                    break;
                case "versusRecordsMinMaxAvg":
                    this.radioButtonRangeRace[target]["avg"] = false;
                    this.radioButtonRangeRace[target]["max"] = false;
                    this.radioButtonRangeRace[target]["min"] = false;
                    this.radioButtonRangeRace[target][range] = true;
                    this.getInfoVersusRecords(target, range);
                    break;
            }
        },
        toggleExpandChartListHorse() {
            this.clickRangeToggle = true;
            this.toggleActiveHorse["addChartList"] = !this.toggleActiveHorse["addChartList"];
        },
        toggleExpand1ChartHorseDetail(target) {
            this.clickRangeToggle = true;
            this.toggleActiveHorse[target] = !this.toggleActiveHorse[target];
        },

        toggleExpandChartListRace() {
            this.clickRangeToggle = true;
            this.toggleActiveRace["addChartList"] = !this.toggleActiveRace["addChartList"];
        },
        toggleExpand1ChartRaceDetail(target) {
            this.clickRangeToggle = true;
            this.toggleActiveRace[target] = !this.toggleActiveRace[target];
        },

        getInfoHorseChip() {
            console.log("[104][DetailCard.vue][getInfoHorseChip] this.card_title-id : ", this.card_title["id"]);
            switch (this.infoHorseAll["horseMeet"]) {
                case 1:
                    this.horseChips[0]["text"] = "서울";
                    break;
                case 2:
                    this.horseChips[0]["text"] = "제주";
                    break;
                case 3:
                    this.horseChips[0]["text"] = "부산경남";
                    break;
            }
            this.horseChips[1]["text"] = this.infoHorseAll["horseID"];
            switch (this.infoHorseAll["horseRating"]) {
                case 0 :
                    this.horseChips[2]["text"] = "-";
                    break;
                default :
                    this.horseChips[2]["text"] = this.infoHorseAll["horseRating"].toString();
                    break;
            }
            this.horseChips[3]["text"] = this.infoHorseAll["horseGrade"];
            this.horseChips[4]["text"] = this.infoHorseAll["horseSex"];
            this.horseChips[5]["text"] = this.infoHorseAll["horseHome"];
            this.horseChips[6]["text"] = this.infoHorseAll["horseBirth"];
            this.horseChips[7]["text"] = this.infoHorseAll["horseColor"];
            this.horseChips[8]["text"] = this.infoHorseAll["horseRaceRange"];
            this.horseChips[9]["text"] = this.infoHorseAll["horseRaceRecord"];
            this.horseChips[10]["text"] = this.infoHorseAll["horseWinningRate"];
            this.horseChips[11]["text"] = this.infoHorseAll["horsePrizeAll"];
            switch (this.infoHorseAll["horsePrizeLatest6"]) {
                case "" :
                    break;
                default :
                    this.horseChips[12]["text"] = this.infoHorseAll["horsePrizeLatest6"];
                    break;
            }
            switch (this.infoHorseAll["horsePrizeLatest3"]) {
                case "" :
                    break;
                default :
                    this.horseChips[13]["text"] = this.infoHorseAll["horsePrizeLatest3"];
                    break;
            }
        },
        getInfoRaceChip() {
            console.log("[104][DetailCard.vue][getInfoRaceChip] this.card_title-id : ", this.card_title["id"], this.infoRaceAll);
            this.raceChips[0]["text"] = this.infoRaceAll["raceName"];
            this.raceChips[1]["text"] = this.infoRaceAll.raceClass;
            this.raceChips[2]["text"] = this.infoRaceAll.raceDistance + "m";
            this.raceChips[3]["text"] = this.infoRaceAll.raceStartTime;
            this.raceChips[4]["text"] = this.infoRaceAll.raceAgeLimit;
            this.raceChips[5]["text"] = this.infoRaceAll.raceBurdenType;
            this.raceChips[6]["text"] = this.infoRaceAll.raceRating;


            let racePrize1Str = this.infoRaceAll.racePrize1;
            let racePrize2Str = this.infoRaceAll.racePrize2;
            let racePrize3Str = this.infoRaceAll.racePrize3;
            let racePrize4Str = this.infoRaceAll.racePrize4;
            let racePrize5Str = this.infoRaceAll.racePrize5;

            if (this.infoRaceAll.racePrize1A !== "") {
                racePrize1Str = racePrize1Str + "(+" + this.infoRaceAll.racePrize1A + ")";
            }
            if (this.infoRaceAll.racePrize2A !== "") {
                racePrize2Str = racePrize2Str + "(+" + this.infoRaceAll.racePrize2A + ")";
            }
            if (this.infoRaceAll.racePrize3A !== "") {
                racePrize3Str = racePrize3Str + "(+" + this.infoRaceAll.racePrize3A + ")";
            }
            if (this.infoRaceAll.racePrize4A !== "") {
                racePrize4Str = racePrize4Str + "(+" + this.infoRaceAll.racePrize4A + ")";
            }
            if (this.infoRaceAll.racePrize5A !== "") {
                racePrize5Str = racePrize5Str + "(+" + this.infoRaceAll.racePrize5A + ")";
            }
            this.raceChips[7]["text"] = racePrize1Str;
            this.raceChips[8]["text"] = racePrize2Str;
            this.raceChips[9]["text"] = racePrize3Str;
            this.raceChips[10]["text"] = racePrize4Str;
            this.raceChips[11]["text"] = racePrize5Str;
        },
        //
        // getInfoTrendPrize("all"/"latest6"/"latest3")
        // => return infoTrendPrize
        // : infoHorseAll 로 부터  infoTrendPrize 를 얻는다
        getInfoTrendPrize(range) {
            console.log("[104][DetailCard.vue][getInfoTrendPrize] this.card_title-id : ", this.card_title["id"]);
            let rawTrendPrize = this.infoHorseAll["trendPrize"];
            let profitDaily = {};
            rawTrendPrize.forEach(f => {
                f["PrizeRankWin"].forEach(g => {
                    profitDaily[g["Date"]] = g["WinPrize"];
                });
                f["PrizeRankLose"].forEach(g => {
                    profitDaily[g["Date"]] = g["ExpectedPrize"] * (-1);
                });
            });
            let dates = [];
            dates = Object.keys(profitDaily).sort((a, b) => a - b);
            let targetDates = [];
            if (range == "latest3") {
                targetDates = dates.slice(-3);
            } else if (range == "latest6") {
                targetDates = dates.slice(-6);
            } else if (range == "latest9") {
                targetDates = dates.slice(-9);
            } else { // all
                targetDates = dates;
            }
            let returnDict = {};
            returnDict["x-labels"] = targetDates;
            const tmpTS = targetDates.map(x => {
                return profitDaily[x] / 10000;
            });
            let countProfit = 0;
            returnDict["y-profit"] = tmpTS.map(function(x) {
                if (x > 0) {
                    countProfit = countProfit + 1;
                    return x;
                } else {
                    return 0;
                }
            });
            let countLoss = 0;
            returnDict["y-loss"] = tmpTS.map(function(x) {
                if (x < 0) {
                    countLoss = countLoss + 1;
                    return x;
                } else {
                    return 0;
                }
            });
            let accumTS = 0;
            returnDict["y-accum"] = tmpTS.map(function(x) {
                accumTS = accumTS + x;
                return accumTS;
            });

            const sumReducer = (accum, curr) => accum + curr;
            this.summaryTrendPrizes = this.genSummaryTrendPrize(range, dates.length, returnDict["y-profit"].reduce(sumReducer) * 10000, countProfit, returnDict["y-loss"].reduce(sumReducer) * 10000, countLoss); //, returnDict["y-accum"]); // "매칭률이 가장 높은 기수은 김개똥, 가장 낮은 기수는 박철수다.";

            this.chartDataTrendPrize = {
                labels: returnDict["x-labels"],
                datasets: [
                    {
                        type: "bar",
                        label: "상금+",
                        borderWidth: 1,
                        borderColor: "#400020",
                        backgroundColor: "rgba(255,70,0,0.8)",
                        data: returnDict["y-profit"],
                        order: 2
                    },
                    {
                        type: "bar",
                        label: "상금-",
                        borderWidth: 1,
                        borderColor: "#000080",
                        backgroundColor: "rgba(0,100,255,0.8)",
                        data: returnDict["y-loss"],
                        order: 3
                    },
                    {
                        type: "line",
                        label: "누적 상금",
                        borderColor: "#035703",
                        borderWidth: 2,
                        lineTension: 0.1,
                        backgroundColor: "rgba(0,128,0,0.5)",
                        data: returnDict["y-accum"],
                        order: 1,
                        pointStyle: "circle",
                        pointBackgroundColor: "#035703",
                        pointBorderWidth: 0,
                        pointBorderColor: "rgba(0,0,0,0)"
                    }
                ]
            };
            return returnDict;
        },
        //
        // getInfoTrendRating("all"/"latest6"/"latest3")
        // => return infoTrendRating
        // : infoHorseAll 로 부터  infoTrendRating 를 얻는다
        getInfoTrendRating(range) {
            let rawTrendRating = this.infoHorseAll["trendRating"];
            let ratingDaily = {};
            rawTrendRating.forEach(f => {
                let tmpRating = f["Rating"];
                f["PrizeRankLose"].forEach(g => {
                    ratingDaily[g["Date"]] = tmpRating;
                });
                f["PrizeRankWin"].forEach(g => {
                    ratingDaily[g["Date"]] = tmpRating;
                });
            });
            let sortedDates = [];
            let targetDates = [];
            sortedDates = Object.keys(ratingDaily).sort((a, b) => a - b);
            if (range == "latest3") {
                targetDates = sortedDates.slice(-3);
            } else if (range == "latest6") {
                targetDates = sortedDates.slice(-6);
            } else if (range == "latest9") {
                targetDates = sortedDates.slice(-9);
            } else { // all
                targetDates = sortedDates;
            }
            let targetRatingValue = targetDates.map(f => {
                return ratingDaily[f];
            });
            this.chartDataTrendRating = {
                labels: targetDates,
                datasets: [
                    {
                        type: "line",
                        label: "레이팅",
                        data: targetRatingValue,
                        borderColor: "rgba(59,179,113,1)",
                        borderWidth: 2,
                        fill: false,
                        lineTension: 0.0,
                        pointStyle: "circle",
                        pointBorderWidth: 1,
                        pointBorderColor: "rgba(59,179,113,1)"
                    }
                ]
            };
        },
        //
        // getInfoTrendWeight("all"/"latest6"/"latest3")
        // => return infoTrendWeight
        // : infoHorseAll 로 부터 infoTrendWeight 를 얻는다
        getInfoTrendWeight(range) {
            let rawTrendWeight = this.infoHorseAll["trendWeight"];
            let weightDaily = {};
            rawTrendWeight.forEach(f => {
                let tmpRating = f["Kg"];
                f["PrizeRankLose"].forEach(g => {
                    weightDaily[g["Date"]] = tmpRating;
                });
                f["PrizeRankWin"].forEach(g => {
                    weightDaily[g["Date"]] = tmpRating;
                });
            });
            let sortedDates = [];
            let targetDates = [];
            sortedDates = Object.keys(weightDaily).sort((a, b) => a - b);
            if (range == "latest3") {
                targetDates = sortedDates.slice(-3);
            } else if (range == "latest6") {
                targetDates = sortedDates.slice(-6);
            } else if (range == "latest9") {
                targetDates = sortedDates.slice(-9);
            } else { // all
                targetDates = sortedDates;
            }
            let targetWeightValue = targetDates.map(f => {
                return weightDaily[f];
            });
            this.chartDataTrendWeight = {
                labels: targetDates,
                datasets: [
                    {
                        type: "line",
                        label: "마체중",
                        data: targetWeightValue,
                        borderColor: "rgba(218,165,32,1)",
                        borderWidth: 2,
                        fill: false,
                        lineTension: 0.0,
                        pointStyle: "circle",
                        pointBorderWidth: 1,
                        pointBorderColor: "rgba(218,165,32,1)"
                    }
                ]
            };
        },
        //
        // getInfoMatchHorseNumber("all"/"latest6"/"latest3")
        // => return infoMatchHorseNumber
        // : infoHorseAll 로 부터 infoMatchHorseNumber 를 얻는다
        getInfoMatchHorseNumber(range) {
            // 주의 LineNumber = HorseNumber
            let rawMatchHorseNumber = this.infoHorseAll["matchHorseNumber"];
            let horseNumberDaily = {};
            rawMatchHorseNumber.forEach(f => {
                let tmpHorseNumber = f["LineNumber"];
                f["PrizeRankLose"].forEach(g => {
                    horseNumberDaily[g["Date"]] = tmpHorseNumber;
                });
                f["PrizeRankWin"].forEach(g => {
                    horseNumberDaily[g["Date"]] = tmpHorseNumber;
                });
            });
            let sortedDates = [];
            let targetDates = [];
            sortedDates = Object.keys(horseNumberDaily).sort((a, b) => a - b).map(s => {
                return parseInt(s);
            });
            if (range == "latest3") {
                targetDates = sortedDates.slice(-3);
            } else if (range == "latest6") {
                targetDates = sortedDates.slice(-6);
            } else if (range == "latest9") {
                targetDates = sortedDates.slice(-9);
            } else { // all
                targetDates = sortedDates;
            }
            let infoHorseNumber = {};
            let infoSummaryHorseNumber = {};
            rawMatchHorseNumber.forEach(f => {
                let tmpHorseNumber = f["LineNumber"];
                let tmpProfit = 0;
                let tmpLoss = 0;

                let countWin = 0;
                let countLose = 0;
                f["PrizeRankLose"].forEach(g => {
                    if (targetDates.includes(g["Date"])) {
                        tmpLoss = tmpLoss - g["ExpectedPrize"];
                        countLose += 1;
                    }
                });
                f["PrizeRankWin"].forEach(g => {
                    if (targetDates.includes(g["Date"])) {
                        tmpProfit = tmpProfit + g["WinPrize"];
                        countWin += 1;
                    }
                });
                let tmpActualPlus = 0;
                let tmpActualMinus = 0;
                if (tmpProfit + tmpLoss > 0) {
                    tmpActualPlus = tmpProfit + tmpLoss;
                } else {
                    tmpActualMinus = tmpProfit + tmpLoss;
                }
                if (!(tmpProfit == 0 && tmpLoss == 0)) {
                    infoHorseNumber[tmpHorseNumber] = {
                        Profit: tmpProfit / 10000,
                        Loss: tmpLoss / 10000,
                        ActualPlus: tmpActualPlus / 10000,
                        ActualMinus: tmpActualMinus / 10000
                    };
                }
                infoSummaryHorseNumber[tmpHorseNumber] = "총 " + (countLose + countWin).toString() + "회 (" + countWin + "순위권/" + countLose + "비순위권)";
            });
            let sortedHorseNumber = [];
            sortedHorseNumber = Object.keys(infoHorseNumber).sort((a, b) => a - b);


            this.summaryMatchHorseNumbers = this.genSummaryMatchHorseNumber(range, infoHorseNumber, infoSummaryHorseNumber);

            this.chartDataMatchHorseNumber = {
                labels: sortedHorseNumber.map(z => z + "번마"),
                datasets: [
                    {
                        label: "상금성과+",
                        borderWidth: 1,
                        borderColor: "#400020",
                        backgroundColor: "rgba(255,70,0,1)",
                        data: sortedHorseNumber.map(q => {
                            return infoHorseNumber[q]["ActualPlus"];
                        })
                    },
                    {
                        label: "상금성과-",
                        borderWidth: 1,
                        borderColor: "#000080",
                        backgroundColor: "rgba(0,100,255,1)",
                        data: sortedHorseNumber.map(q => {
                            return infoHorseNumber[q]["ActualMinus"];
                        })
                    },
                    {
                        label: "총 상금+",
                        barPercentage: 0.3,
                        borderColor: "#E53935",
                        backgroundColor: "rgba(255,70,0,0.2)",
                        data: sortedHorseNumber.map(q => {
                            return infoHorseNumber[q]["Profit"];
                        })
                    },
                    {
                        label: "총 상금-",
                        barPercentage: 0.3,
                        borderColor: "#0066FF",
                        backgroundColor: "rgba(0,100,255,0.2)",
                        data: sortedHorseNumber.map(q => {
                            return infoHorseNumber[q]["Loss"];
                        })
                    }
                ]
            };
        },
        //
        // getInfoMatchJockey("all"/"latest6"/"latest3")
        // => return infoMatchJockey
        // : infoHorseAll 로 부터 infoMatchJockey 를 얻는다
        getInfoMatchJockey(range) {
            let rawMatchJockey = this.infoHorseAll["matchJockey"];
            let jockeyDaily = {};
            rawMatchJockey.forEach(f => {
                let tmpJockey = f["Jockey"];
                f["PrizeRankLose"].forEach(g => {
                    jockeyDaily[g["Date"]] = tmpJockey;
                });
                f["PrizeRankWin"].forEach(g => {
                    jockeyDaily[g["Date"]] = tmpJockey;
                });
            });
            let sortedDates = [];
            let targetDates = [];
            sortedDates = Object.keys(jockeyDaily).sort((a, b) => a - b).map(s => {
                return parseInt(s);
            });
            if (range == "latest3") {
                targetDates = sortedDates.slice(-3);
            } else if (range == "latest6") {
                targetDates = sortedDates.slice(-6);
            } else if (range == "latest9") {
                targetDates = sortedDates.slice(-9);
            } else { // all
                targetDates = sortedDates;
            }

            let infoJockey = {};
            let infoSummaryJockey = {};

            rawMatchJockey.forEach(f => {
                let tmpJockey = f["Name"];
                let tmpProfit = 0;
                let tmpLoss = 0;

                let countLose = 0;
                let countWin = 0;
                f["PrizeRankLose"].forEach(g => {
                    if (targetDates.includes(g["Date"])) {
                        tmpLoss = tmpLoss - g["ExpectedPrize"];
                        countLose = countLose + 1;
                    }
                });
                f["PrizeRankWin"].forEach(g => {
                    if (targetDates.includes(g["Date"])) {
                        tmpProfit = tmpProfit + g["WinPrize"];
                        countWin = countWin + 1;
                    }
                });
                let tmpActualPlus = 0;
                let tmpActualMinus = 0;
                if (tmpProfit + tmpLoss > 0) {
                    tmpActualPlus = tmpProfit + tmpLoss;
                } else {
                    tmpActualMinus = tmpProfit + tmpLoss;
                }
                if (!(tmpProfit == 0 && tmpLoss == 0)) {
                    infoJockey[tmpJockey] = {
                        Profit: tmpProfit / 10000,
                        Loss: tmpLoss / 10000,
                        ActualPlus: tmpActualPlus / 10000,
                        ActualMinus: tmpActualMinus / 10000
                    };
                }

                infoSummaryJockey[tmpJockey] = "총 " + (countLose + countWin).toString() + "회 (" + countWin + "순위권/" + countLose + "비순위권)";
            });
            let sortedJockey = [];
            sortedJockey = Object.keys(infoJockey).sort((a, b) => a - b);

            this.summaryMatchJockeys = this.genSummaryMatchJockey(range, infoJockey, infoSummaryJockey);
            this.chartDataMatchJockey = {
                labels: sortedJockey.map(z => z.split("-")[0]),
                datasets: [
                    {
                        label: "상금성과+",
                        borderWidth: 1,
                        borderColor: "#400020",
                        backgroundColor: "rgba(255,70,0,1)",
                        data: sortedJockey.map(q => {
                            return infoJockey[q]["ActualPlus"];
                        })
                    },
                    {
                        label: "상금성과-",
                        borderWidth: 1,
                        borderColor: "#000080",
                        backgroundColor: "rgba(0,100,255,1)",
                        data: sortedJockey.map(q => {
                            return infoJockey[q]["ActualMinus"];
                        })
                    },
                    {
                        label: "총 상금+",
                        barPercentage: 0.3,
                        borderColor: "#E53935",
                        backgroundColor: "rgba(255,70,0,0.2)",
                        data: sortedJockey.map(q => {
                            return infoJockey[q]["Profit"];
                        })
                    },
                    {
                        label: "총 상금-",
                        barPercentage: 0.3,
                        borderColor: "#0066FF",
                        backgroundColor: "rgba(0,100,255,0.2)",
                        data: sortedJockey.map(q => {
                            return infoJockey[q]["Loss"];
                        })
                    }
                ]
            };
        },
        //
        // getInfoMatchTool("all"/"latest6"/"latest3")
        // => return infoMatchTool
        // : infoHorseAll 로 부터 infoMatchTool 를 얻는다
        getInfoMatchTool(range) {
            let rawMatchTool = this.infoHorseAll["matchTool"];
            let toolDaily = {};
            rawMatchTool.forEach(f => {
                let tmpTool = f["Tool"];
                f["PrizeRankLose"].forEach(g => {
                    toolDaily[g["Date"]] = tmpTool;
                });
                f["PrizeRankWin"].forEach(g => {
                    toolDaily[g["Date"]] = tmpTool;
                });
            });
            let sortedDates = [];
            let targetDates = [];
            sortedDates = Object.keys(toolDaily).sort((a, b) => a - b).map(s => {
                return parseInt(s);
            });
            if (range == "latest3") {
                targetDates = sortedDates.slice(-3);
            } else if (range == "latest6") {
                targetDates = sortedDates.slice(-6);
            } else if (range == "latest9") {
                targetDates = sortedDates.slice(-9);
            } else { // all
                targetDates = sortedDates;
            }
            let infoTool = {};
            let infoSummaryTool = {};

            rawMatchTool.forEach(f => {
                let tmpTool = f["Name"];
                if (tmpTool != "") {

                    let tmpProfit = 0;
                    let tmpLoss = 0;
                    let countLoss = 0;
                    let countWin = 0;
                    f["PrizeRankLose"].forEach(g => {
                        if (targetDates.includes(g["Date"])) {
                            tmpLoss = tmpLoss - g["ExpectedPrize"];
                            countLoss = countLoss + 1;
                        }
                    });
                    f["PrizeRankWin"].forEach(g => {
                        if (targetDates.includes(g["Date"])) {
                            tmpProfit = tmpProfit + g["WinPrize"];
                            countWin = countWin + 1;
                        }
                    });
                    let tmpActualPlus = 0;
                    let tmpActualMinus = 0;
                    if (tmpProfit + tmpLoss > 0) {
                        tmpActualPlus = tmpProfit + tmpLoss;
                    } else {
                        tmpActualMinus = tmpProfit + tmpLoss;
                    }
                    if (!(tmpProfit == 0 && tmpLoss == 0)) {
                        infoTool[tmpTool] = {
                            Profit: tmpProfit / 10000,
                            Loss: tmpLoss / 10000,
                            ActualPlus: tmpActualPlus / 10000,
                            ActualMinus: tmpActualMinus / 10000
                        };
                    }
                    ;
                    infoSummaryTool[tmpTool] = "총 " + (countLoss + countWin).toString() + "회 (" + countWin + "순위권/" + countLoss + "비순위권)";
                }
            });

            let sortedTool = [];
            sortedTool = Object.keys(infoTool).sort((a, b) => a - b);
            if (sortedTool.includes("미착용")) {
                sortedTool = sortedTool.filter(f => f != "미착용");
                sortedTool.unshift("미착용");
            }

            this.summaryMatchTools = this.genSummaryMatchTool(range, infoTool, infoSummaryTool);
            this.chartDataMatchTool = {
                labels: sortedTool,
                datasets: [
                    {
                        label: "상금성과+",
                        borderWidth: 1,
                        borderColor: "#400020",
                        backgroundColor: "rgba(255,70,0,1)",
                        data: sortedTool.map(q => {
                            return infoTool[q]["ActualPlus"];
                        })
                    },
                    {
                        label: "상금성과-",
                        borderWidth: 1,
                        borderColor: "#000080",
                        backgroundColor: "rgba(0,100,255,1)",
                        data: sortedTool.map(q => {
                            return infoTool[q]["ActualMinus"];
                        })
                    },
                    {
                        label: "총 상금+",
                        barPercentage: 0.3,
                        borderColor: "#E53935",
                        backgroundColor: "rgba(255,70,0,0.2)",
                        data: sortedTool.map(q => {
                            return infoTool[q]["Profit"];
                        })
                    },
                    {
                        label: "총 상금-",
                        barPercentage: 0.3,
                        borderColor: "#0066FF",
                        backgroundColor: "rgba(0,100,255,0.2)",
                        data: sortedTool.map(q => {
                            return infoTool[q]["Loss"];
                        })
                    }
                ]
            };
        },
        //
        // getInfoMatchRaceDistance("all"/"latest6"/"latest3")
        // => return infoMatchRaceDistance
        // : infoHorseAll 로 부터 infoMatchRaceDistance 를 얻는다
        getInfoMatchRaceDistance(range) {
            let rawMatchRaceDistance = this.infoHorseAll["matchRaceDistance"];
            let raceDistanceDaily = {};
            rawMatchRaceDistance.forEach(f => {
                let tmpRaceDistance = f["RaceDistance"];
                f["PrizeRankLose"].forEach(g => {
                    raceDistanceDaily[g["Date"]] = tmpRaceDistance;
                });
                f["PrizeRankWin"].forEach(g => {
                    raceDistanceDaily[g["Date"]] = tmpRaceDistance;
                });
            });
            let sortedDates = [];
            let targetDates = [];
            sortedDates = Object.keys(raceDistanceDaily).sort((a, b) => a - b).map(s => {
                return parseInt(s);
            });
            if (range == "latest3") {
                targetDates = sortedDates.slice(-3);
            } else if (range == "latest6") {
                targetDates = sortedDates.slice(-6);
            } else if (range == "latest9") {
                targetDates = sortedDates.slice(-9);
            } else { // all
                targetDates = sortedDates;
            }
            let infoRaceDistance = {};
            let infoSummaryRaceDistance = {};
            rawMatchRaceDistance.forEach(f => {
                let tmpRaceDistance = f["DistMeter"];
                let tmpProfit = 0;
                let tmpLoss = 0;
                let countLose = 0;
                let countWin = 0;
                f["PrizeRankLose"].forEach(g => {
                    if (targetDates.includes(g["Date"])) {
                        tmpLoss = tmpLoss - g["ExpectedPrize"];
                        countLose = countLose + 1;
                    }
                });
                f["PrizeRankWin"].forEach(g => {
                    if (targetDates.includes(g["Date"])) {
                        tmpProfit = tmpProfit + g["WinPrize"];
                        countWin = countWin + 1;
                    }
                });
                let tmpActualPlus = 0;
                let tmpActualMinus = 0;
                if (tmpProfit + tmpLoss > 0) {
                    tmpActualPlus = tmpProfit + tmpLoss;
                } else {
                    tmpActualMinus = tmpProfit + tmpLoss;
                }
                if (!(tmpProfit == 0 && tmpLoss == 0)) {
                    infoRaceDistance[tmpRaceDistance] = {
                        Profit: tmpProfit / 10000,
                        Loss: tmpLoss / 10000,
                        ActualPlus: tmpActualPlus / 10000,
                        ActualMinus: tmpActualMinus / 10000
                    };
                }
                infoSummaryRaceDistance[tmpRaceDistance] = "총 " + (countLose + countWin).toString() + "회 (" + countWin + "순위권/" + countLose + "비순위권)";
            });
            let sortedRaceDistance = [];
            sortedRaceDistance = Object.keys(infoRaceDistance).sort((a, b) => a - b);

            this.summaryMatchRaceDistance = this.genSummaryMatchRaceDistance(range, infoRaceDistance, infoSummaryRaceDistance);

            this.chartDataMatchRaceDistance = {
                labels: sortedRaceDistance.map(f => f + "m"),
                datasets: [
                    {
                        label: "상금성과+",
                        borderWidth: 1,
                        borderColor: "#400020",
                        backgroundColor: "rgba(255,70,0,1)",
                        data: sortedRaceDistance.map(q => {
                            return infoRaceDistance[q]["ActualPlus"];
                        })
                    },
                    {
                        label: "상금성과-",
                        borderWidth: 1,
                        borderColor: "#000080",
                        backgroundColor: "rgba(0,100,255,1)",
                        data: sortedRaceDistance.map(q => {
                            return infoRaceDistance[q]["ActualMinus"];
                        })
                    },
                    {
                        label: "총 상금+",
                        barPercentage: 0.3,
                        borderColor: "#E53935",
                        backgroundColor: "rgba(255,70,0,0.2)",
                        data: sortedRaceDistance.map(q => {
                            return infoRaceDistance[q]["Profit"];
                        })
                    },
                    {
                        label: "총 상금-",
                        barPercentage: 0.3,
                        borderColor: "#0066FF",
                        backgroundColor: "rgba(0,100,255,0.2)",
                        data: sortedRaceDistance.map(q => {
                            return infoRaceDistance[q]["Loss"];
                        })
                    }
                ]
            };

        },
        //
        // getInfoMatchTrackCondition("all"/"latest6"/"latest3")
        // => return infoMatchTrackCondition
        // : infoHorseAll 로 부터 infoMatchTrackCondition 를 얻는다
        getInfoMatchRaceTrackCondition(range) {
            let rawMatchRaceTrackCondition = this.infoHorseAll["matchTrackConditionDisc"];
            let raceTrackConditionDaily = {};
            rawMatchRaceTrackCondition.forEach(f => {
                let tmpRaceTrackCondition = f["RaceTrackCondition"];
                f["PrizeRankLose"].forEach(g => {
                    raceTrackConditionDaily[g["Date"]] = tmpRaceTrackCondition;
                });
                f["PrizeRankWin"].forEach(g => {
                    raceTrackConditionDaily[g["Date"]] = tmpRaceTrackCondition;
                });
            });
            let sortedDates = [];
            let targetDates = [];
            sortedDates = Object.keys(raceTrackConditionDaily).sort((a, b) => a - b).map(s => {
                return parseInt(s);
            });
            if (range == "latest3") {
                targetDates = sortedDates.slice(-3);
            } else if (range == "latest6") {
                targetDates = sortedDates.slice(-6);
            } else if (range == "latest9") {
                targetDates = sortedDates.slice(-9);
            } else { // all
                targetDates = sortedDates;
            }
            let infoRaceTrackCondition = {};
            let infoSummaryRaceTrackCondition = {};
            rawMatchRaceTrackCondition.forEach(f => {
                let tmpRaceTrackCondition = f["Condition"];
                let tmpProfit = 0;
                let tmpLoss = 0;
                let countLose = 0;
                let countWin = 0;
                f["PrizeRankLose"].forEach(g => {
                    if (targetDates.includes(g["Date"])) {
                        tmpLoss = tmpLoss - g["ExpectedPrize"];
                        countLose = countLose + 1;
                    }
                });
                f["PrizeRankWin"].forEach(g => {
                    if (targetDates.includes(g["Date"])) {
                        tmpProfit = tmpProfit + g["WinPrize"];
                        countWin = countWin + 1;
                    }
                });
                let tmpActualPlus = 0;
                let tmpActualMinus = 0;
                if (tmpProfit + tmpLoss > 0) {
                    tmpActualPlus = tmpProfit + tmpLoss;
                } else {
                    tmpActualMinus = tmpProfit + tmpLoss;
                }
                if (!(tmpProfit == 0 && tmpLoss == 0)) {
                    // 건조(x<6) / 양호(6<=x<10) / 다습(10<=x<15) / 포화(15<=x<20) / 불량(20<=x)
                    switch (tmpRaceTrackCondition) {
                        case "건조":
                            tmpRaceTrackCondition = "1_" + tmpRaceTrackCondition;
                            break;
                        case "양호":
                            tmpRaceTrackCondition = "2_" + tmpRaceTrackCondition;
                            break;
                        case "다습":
                            tmpRaceTrackCondition = "3_" + tmpRaceTrackCondition;
                            break;
                        case "포화":
                            tmpRaceTrackCondition = "4_" + tmpRaceTrackCondition;
                            break;
                        case "불량":
                            tmpRaceTrackCondition = "5_" + tmpRaceTrackCondition;
                            break;
                    }
                    infoRaceTrackCondition[tmpRaceTrackCondition] = {
                        Profit: tmpProfit / 10000,
                        Loss: tmpLoss / 10000,
                        ActualPlus: tmpActualPlus / 10000,
                        ActualMinus: tmpActualMinus / 10000
                    };
                }
                infoSummaryRaceTrackCondition[tmpRaceTrackCondition] = "총 " + (countLose + countWin).toString() + "회 (" + countWin + "순위권/" + countLose + "비순위권)";
            });
            let sortedRaceTrackCondition = [];
            sortedRaceTrackCondition = Object.keys(infoRaceTrackCondition).sort();

            this.summaryMatchRaceTrackCondition = this.genSummaryMatchRaceTrackCondition(range, infoRaceTrackCondition, infoSummaryRaceTrackCondition);
            this.chartDataMatchRaceTrackCondition = {
                labels: sortedRaceTrackCondition.map(zz => zz.split("_")[1]),
                datasets: [
                    {
                        label: "상금성과+",
                        borderWidth: 1,
                        borderColor: "#400020",
                        backgroundColor: "rgba(255,70,0,1)",
                        data: sortedRaceTrackCondition.map(q => {
                            return infoRaceTrackCondition[q]["ActualPlus"];
                        })
                    },
                    {
                        label: "상금성과-",
                        borderWidth: 1,
                        borderColor: "#000080",
                        backgroundColor: "rgba(0,100,255,1)",
                        data: sortedRaceTrackCondition.map(q => {
                            return infoRaceTrackCondition[q]["ActualMinus"];
                        })
                    },
                    {
                        label: "총 상금+",
                        barPercentage: 0.3,
                        borderColor: "#E53935",
                        backgroundColor: "rgba(255,70,0,0.2)",
                        data: sortedRaceTrackCondition.map(q => {
                            return infoRaceTrackCondition[q]["Profit"];
                        })
                    },
                    {
                        label: "총 상금-",
                        barPercentage: 0.3,
                        borderColor: "#0066FF",
                        backgroundColor: "rgba(0,100,255,0.2)",
                        data: sortedRaceTrackCondition.map(q => {
                            return infoRaceTrackCondition[q]["Loss"];
                        })
                    }
                ]
            };
        },
        convertMilliSecond(str) {
            let ms = 1;
            if (str != "") {
                str = str.replace(/ /g, "");
                str = str.replace(/:/g, ",");
                str = str.replace(/\./g, ",");

                let min = parseInt(str.split(",")[0]) * 60 * 1000;
                let sec = parseInt(str.split(",")[1]) * 1000;
                let mSec = parseInt(str.split(",")[2]);
                if (mSec < 10) {
                    mSec = mSec * 100;
                } else if (mSec < 100) {
                    mSec = mSec * 10;
                } else if (mSec < 1000) {
                    mSec = mSec * 1;
                } else {
                    mSec = 0;
                }
                ms = min + sec + mSec;
                if (ms == 0) {
                    ms = 1;
                }
            }
            return ms;
        },

        //
        getInfoVersusSpeeds22222(target, range) {
            try {

                let rawVersusSpeeds = [];
                rawVersusSpeeds = this.infoRaceAll["raceVersusSpeeds"];

                let isMinMaxAvg = false;
                let isLatest = false;
                switch (target) {
                    case "versusSpeedsLatest":
                        isLatest = true;
                        break;
                    case "versusSpeedsMinMaxAvg":
                        isMinMaxAvg = true;
                        break;
                }

                if (isLatest) {
                    this.raceVersusSpeedsLatest = range; // all 9 6 3
                }
                if (isMinMaxAvg) {
                    this.raceVersusSpeedsMinMaxAvg = range; // min max avg
                }

                let targetLatest = 0;
                switch (this.raceVersusSpeedsLatest) {
                    case "latest3":
                        targetLatest = 3;
                        break;
                    case "latest6":
                        targetLatest = 6;
                        break;
                    case "latest9":
                        targetLatest = 9;
                        break;
                    default :
                        targetLatest = 0;
                        break;
                }

                let targetVersusSpeeds = {};
                for (const tVersusSpeeds of rawVersusSpeeds) {
                    if (tVersusSpeeds["Latest"] == targetLatest) {
                        targetVersusSpeeds["Latest"] = tVersusSpeeds["Latest"];
                        switch (this.raceVersusSpeedsMinMaxAvg) {
                            case "avg":
                                targetVersusSpeeds["_SGs"] = tVersusSpeeds["AverageSGs"];
                                targetVersusSpeeds["_S1Fs"] = tVersusSpeeds["AverageS1Fs"];
                                targetVersusSpeeds["_1FGs"] = tVersusSpeeds["Average1FGs"];
                                targetVersusSpeeds["_3FGs"] = tVersusSpeeds["Average3FGs"];
                                break;
                            case "max":
                                targetVersusSpeeds["_SGs"] = tVersusSpeeds["MaxSGs"];
                                targetVersusSpeeds["_S1Fs"] = tVersusSpeeds["MaxS1Fs"];
                                targetVersusSpeeds["_1FGs"] = tVersusSpeeds["Max1FGs"];
                                targetVersusSpeeds["_3FGs"] = tVersusSpeeds["Max3FGs"];
                                break;
                            case "min":
                                targetVersusSpeeds["_SGs"] = tVersusSpeeds["MinSGs"];
                                targetVersusSpeeds["_S1Fs"] = tVersusSpeeds["MinS1Fs"];
                                targetVersusSpeeds["_1FGs"] = tVersusSpeeds["Min1FGs"];
                                targetVersusSpeeds["_3FGs"] = tVersusSpeeds["Min3FGs"];
                                break;
                        }

                        if (!(tVersusSpeeds["ExceptHIDs"] == undefined || tVersusSpeeds["ExceptHIDs"] == null || tVersusSpeeds["ExceptHIDs"].length == 0)) {
                            this.infoRaceAll["ExceptHIDs"] = tVersusSpeeds["ExceptHIDs"];
                            this.infoRaceAll["ExistExceptHIDs"] = true;
                        } else {
                            this.infoRaceAll["ExceptHIDs"] = [];
                            this.infoRaceAll["ExistExceptHIDs"] = false;
                        }
                        break;
                    }
                }


                let speedHorseInfos = {};
                const sections = ["_SGs", "_S1Fs", "_1FGs", "_3FGs"];
                for (const section of sections) {
                    for (const tSpeedHorse of targetVersusSpeeds[section]) {
                        // "HID" : "horse-AAA-2-11111"
                        // "HorseName" : "AAA"
                        // "HorseNumber" : 1
                        // "Speed" : 11.771

                        // const tHorseNumber = ("" + tSpeedHorse["HorseNumber"]).padStart(2, "0");
                        // const tHorseName = tSpeedHorse["HorseName"];
                        const tSpeed = tSpeedHorse["Speed"];
                        const tHorseKey = ("" + tSpeedHorse["HorseNumber"]).padStart(2, "0") + tSpeedHorse["HorseName"];
                        let tSpeedHorseInfo = speedHorseInfos[tHorseKey];
                        if (tSpeedHorseInfo == undefined) tSpeedHorseInfo = {};
                        tSpeedHorseInfo[section] = tSpeed;
                        speedHorseInfos[tHorseKey] = tSpeedHorseInfo;
                    }
                }

                const pointStyle = "circle";
                const pointBorderWidth = 0;
                const borderWidthAvg = 2;
                const lineTension = 0.0;
                const borderColors = [
                    [
                        "rgba(255,68,51,0.7)",
                        "rgba(255,68,51,1)"
                    ],
                    [
                        "rgba(0,100,100,0.7)",
                        "rgba(0,100,100,1)"
                    ],
                    [
                        "rgba(128,0,128,0.7)",
                        "rgba(128,0,128,1)"
                    ],
                    [
                        "rgba(65,105,225,0.7)",
                        "rgba(65,105,225,1)"
                    ],
                    [
                        "rgba(128,128,0,0.7)",
                        "rgba(128,128,0,1)"
                    ],
                    [
                        "rgba(0,128,128,0.7)",
                        "rgba(0,128,128,1)"
                    ],
                    [
                        "rgba(50,205,50,0.7)",
                        "rgba(50,205,50,1)"
                    ],
                    [
                        "rgba(192,64,0,0.7)",
                        "rgba(192,64,0,1)"
                    ],
                    [
                        "rgba(255,191,0,0.7)",
                        "rgba(255,191,0,1)"
                    ],
                    [
                        "rgba(128,0,128,0.7)",
                        "rgba(128,0,128,1)"
                    ],
                    [
                        "rgba(250,128,114,0.7)",
                        "rgba(250,128,114,1)"
                    ],
                    [
                        "rgba(200,0,200,0.7)",
                        "rgba(200,0,200,1)"
                    ],
                    [
                        "rgba(0,50,150,0.7)",
                        "rgba(0,50,150,1)"
                    ],
                    [
                        "rgba(0,0,150,0.7)",
                        "rgba(0,0,150,1)"
                    ],
                    [
                        "rgba(200,0,200,0.7)",
                        "rgba(200,0,100,0.7)"
                    ]
                ];

                let dataSetSpeed = [];
                let incrIndex = 0;
                Object.entries(speedHorseInfos).forEach(([keyHorse, valueSpeed]) => {
                    dataSetSpeed.push({
                        label: keyHorse,
                        data: [valueSpeed["_SGs"], valueSpeed["_S1Fs"], valueSpeed["_1FGs"], valueSpeed["_3FGs"]],
                        borderColor: borderColors[incrIndex][0],
                        type: "line",
                        borderWidth: borderWidthAvg,
                        fill: false,
                        lineTension: lineTension,
                        pointStyle: pointStyle,
                        pointBorderWidth: pointBorderWidth,
                        pointBorderColor: borderColors[incrIndex][0]
                    });

                    incrIndex = incrIndex + 1;
                });
                this.chartDataVersusSpeeds = {
                    labels: ["전체(SG)", "초반200m(S1F)", "후반200m(1FG)", "후반600m(3FG)"],
                    datasets: dataSetSpeed
                };
                if (dataSetSpeed.length === 0) {
                    this.isEmptyChartDataVersusSpeeds = true;
                }
            } catch {
                this.renewCardRaceDataAll(); // #77 의 핵심부분 render 가 안된다는 것은 중간과정중 error 발생의미. 이를 catch해서 renewCard
            }
        },

        //
        getInfoVersusSpeeds(target, range) {

            // todo
            try {
                console.log("[104][DetailCard.vue][getInfoVersusRecords] In: ", this.card_title["id"], target, range, this.infoRaceAll);

                let rawVersusRecords = [];
                rawVersusRecords = this.infoRaceAll["raceVersusRecords"];

                let isMinMaxAvg = false;
                let isLatest = false;
                switch (target) {
                    case "versusSpeedsLatest":
                        isLatest = true;
                        break;
                    case "versusSpeedsMinMaxAvg":
                        isMinMaxAvg = true;
                        break;
                }

                if (isLatest) {
                    this.raceVersusSpeedsLatest = range; // all 9 6 3
                }
                if (isMinMaxAvg) {
                    this.raceVersusSpeedsMinMaxAvg = range; // min max avg
                }


                let targetLatest = 0;
                switch (this.raceVersusSpeedsLatest) {
                    case "latest3":
                        targetLatest = 3;
                        break;
                    case "latest6":
                        targetLatest = 6;
                        break;
                    case "latest9":
                        targetLatest = 9;
                        break;
                    default :
                        targetLatest = 0;
                        break;
                }

                let recordHorseInfos = {};
                let targetVersusRecords = {};
                const tSectionLabels = [];
                for (const tVersusRecords of rawVersusRecords) {
                    if (tVersusRecords["Latest"] == targetLatest) {
                        targetVersusRecords["Latest"] = tVersusRecords["Latest"];

                        let tmpVersusRecords = [];
                        switch (this.raceVersusSpeedsMinMaxAvg) {
                            case "avg":
                                tmpVersusRecords = tVersusRecords["Averages"];
                                break;
                            case "max":
                                tmpVersusRecords = tVersusRecords["Maxs"];
                                break;
                            case "min":
                                tmpVersusRecords = tVersusRecords["Mins"];
                                break;
                        }

                        for (const tmpVersusRecord of tmpVersusRecords) {
                            const tmpHorseName = tmpVersusRecord["HorseName"]; // "원더풀"
                            const tmpHorseNumber = ("" + tmpVersusRecord["HorseNumber"]).padStart(2, "0");
                            const tmpRecord = tmpVersusRecord["Record"];
                            recordHorseInfos[tmpHorseNumber + tmpHorseName] = tmpRecord;

                            if (tSectionLabels.length == 0) {
                                Object.keys(tmpRecord).forEach(tSectionLabel => {
                                    const dists = tSectionLabel.split("_")[1];
                                    if (dists == "total") {
                                        tSectionLabels.push(tSectionLabel);
                                    } else {
                                        const distSplit = dists.split("-");
                                        if (distSplit[0] != distSplit[1].replaceAll("m", "")) {
                                            tSectionLabels.push(tSectionLabel);
                                        }
                                    }
                                });
                                tSectionLabels.sort();
                            }
                        }

                        if (!(tVersusRecords["ExceptHIDs"] == undefined || tVersusRecords["ExceptHIDs"] == null || tVersusRecords["ExceptHIDs"].length == 0)) {
                            this.infoRaceAll["ExceptHIDs"] = tVersusRecords["ExceptHIDs"];
                            this.infoRaceAll["ExistExceptHIDs"] = true;
                        } else {
                            this.infoRaceAll["ExceptHIDs"] = [];
                            this.infoRaceAll["ExistExceptHIDs"] = false;
                        }
                        break;
                    }
                }


                const pointStyle = "circle";
                const pointBorderWidth = 0;
                const borderWidthAvg = 2;
                const lineTension = 0.0;
                const borderColors = [
                    [
                        "rgba(255,68,51,0.7)",
                        "rgba(255,68,51,1)"
                    ],
                    [
                        "rgba(0,100,100,0.7)",
                        "rgba(0,100,100,1)"
                    ],
                    [
                        "rgba(128,0,128,0.7)",
                        "rgba(128,0,128,1)"
                    ],
                    [
                        "rgba(65,105,225,0.7)",
                        "rgba(65,105,225,1)"
                    ],
                    [
                        "rgba(128,128,0,0.7)",
                        "rgba(128,128,0,1)"
                    ],
                    [
                        "rgba(0,128,128,0.7)",
                        "rgba(0,128,128,1)"
                    ],
                    [
                        "rgba(50,205,50,0.7)",
                        "rgba(50,205,50,1)"
                    ],
                    [
                        "rgba(192,64,0,0.7)",
                        "rgba(192,64,0,1)"
                    ],
                    [
                        "rgba(255,191,0,0.7)",
                        "rgba(255,191,0,1)"
                    ],
                    [
                        "rgba(128,0,128,0.7)",
                        "rgba(128,0,128,1)"
                    ],
                    [
                        "rgba(250,128,114,0.7)",
                        "rgba(250,128,114,1)"
                    ],
                    [
                        "rgba(200,0,200,0.7)",
                        "rgba(200,0,200,1)"
                    ],
                    [
                        "rgba(0,50,150,0.7)",
                        "rgba(0,50,150,1)"
                    ],
                    [
                        "rgba(0,0,150,0.7)",
                        "rgba(0,0,150,1)"
                    ],
                    [
                        "rgba(200,0,200,0.7)",
                        "rgba(200,0,100,0.7)"
                    ]
                ];

                let dataSetSpeed = [];
                let incrIndex = 0;
                Object.entries(recordHorseInfos).forEach(([keyHorse, valueRecord]) => {

                    let totalData = 0.0;
                    const lineData = [];
                    tSectionLabels.forEach(tSectionLabel => {
                        const tmpSpeed = this.calSectionRecordToSpeed(this.infoRaceAll["raceDistance"], tSectionLabel, valueRecord[tSectionLabel]);
                        if (tSectionLabel.includes("total")) {
                            totalData = tmpSpeed;
                        } else if (tmpSpeed !== 0.0) {
                            lineData.push(tmpSpeed);
                        }
                    });
                    lineData.push(totalData);

                    dataSetSpeed.push({
                        label: keyHorse,
                        data: lineData,
                        borderColor: borderColors[incrIndex][0],
                        type: "line",
                        borderWidth: borderWidthAvg,
                        fill: false,
                        lineTension: lineTension,
                        pointStyle: pointStyle,
                        pointBorderWidth: pointBorderWidth,
                        pointBorderColor: borderColors[incrIndex][0]
                    });
                    incrIndex = incrIndex + 1;
                });

                if (dataSetSpeed.length !== 0) {
                    const chartLabels = tSectionLabels.map(l => l.split("_")[1]).splice(1);
                    chartLabels.push("0-" + this.infoRaceAll["raceDistance"] + "m");

                    this.chartDataVersusSpeeds = {
                        labels: chartLabels,
                        datasets: dataSetSpeed
                    };
                } else {
                    this.isEmptyChartDataVersusSpeeds = true;
                }
            } catch {
                this.renewCardRaceDataAll();
            }
        },

        calSectionRecordToSpeed(raceDistance, tSection, tRecord) {

            if (tRecord == 0) {
                return 0.0;
            }
            let tDistance = 0;
            const del00 = tSection.split("_")[1];
            if (del00 === "total") {
                tDistance = raceDistance;
            } else {
                const del01 = del00.split("-");
                const point1 = parseInt(del01[0]);
                const point2 = parseInt(del01[1].replaceAll("m", ""));
                tDistance = point2 - point1;
            }
            if (tDistance <= 0) {
                return 0.0;
            } else {
                return Math.round(tDistance / tRecord * 1000) / 1000;
            }
        },

        //
        getInfoVersusRecords(target, range) {

            // todo
            try {
                console.log("[104][DetailCard.vue][getInfoVersusRecords] this.card_title-id : ", this.card_title["id"]);

                let rawVersusRecords = [];
                rawVersusRecords = this.infoRaceAll["raceVersusRecords"];

                let isMinMaxAvg = false;
                let isLatest = false;
                switch (target) {
                    case "versusRecordsLatest":
                        isLatest = true;
                        break;
                    case "versusRecordsMinMaxAvg":
                        isMinMaxAvg = true;
                        break;
                }

                if (isLatest) {
                    this.raceVersusRecordsLatest = range; // all 9 6 3
                }
                if (isMinMaxAvg) {
                    this.raceVersusRecordsMinMaxAvg = range; // min max avg
                }

                let targetLatest = 0;
                switch (this.raceVersusRecordsLatest) {
                    case "latest3":
                        targetLatest = 3;
                        break;
                    case "latest6":
                        targetLatest = 6;
                        break;
                    case "latest9":
                        targetLatest = 9;
                        break;
                    default :
                        targetLatest = 0;
                        break;
                }


                let recordHorseInfos = {};
                let targetVersusRecords = {};
                for (const tVersusRecords of rawVersusRecords) {
                    if (tVersusRecords["Latest"] == targetLatest) {
                        targetVersusRecords["Latest"] = tVersusRecords["Latest"];

                        let tmpVersusRecords = [];
                        switch (this.raceVersusRecordsMinMaxAvg) {
                            case "avg":
                                tmpVersusRecords = tVersusRecords["Averages"];
                                break;
                            case "max":
                                tmpVersusRecords = tVersusRecords["Maxs"];
                                break;
                            case "min":
                                tmpVersusRecords = tVersusRecords["Mins"];
                                break;
                        }

                        for (const tmpVersusRecord of tmpVersusRecords) {
                            const tmpHorseName = tmpVersusRecord["HorseName"]; // "원더풀"
                            const tmpHorseNumber = ("" + tmpVersusRecord["HorseNumber"]).padStart(2, "0");
                            const tmpRecord = tmpVersusRecord["Record"];
                            recordHorseInfos[tmpHorseNumber + tmpHorseName] = tmpRecord;
                        }

                        if (!(tVersusRecords["ExceptHIDs"] == undefined || tVersusRecords["ExceptHIDs"] == null || tVersusRecords["ExceptHIDs"].length == 0)) {
                            this.infoRaceAll["ExceptHIDs"] = tVersusRecords["ExceptHIDs"];
                            this.infoRaceAll["ExistExceptHIDs"] = true;
                        } else {
                            this.infoRaceAll["ExceptHIDs"] = [];
                            this.infoRaceAll["ExistExceptHIDs"] = false;
                        }
                        break;
                    }
                }

                let tVersusRecord = {};
                const distanceSections = [];

                Object.entries(recordHorseInfos).forEach(([kHorse, vRecord]) => {
                    if (distanceSections.length == 0) {
                        Object.keys(vRecord).forEach(kDistanceSection => {
                            if (!kDistanceSection.includes("total")) {

                                const dists = kDistanceSection.split("_")[1].split("-");

                                if (dists[0] != dists[1].replaceAll("m", "")) {

                                    distanceSections.push(kDistanceSection);
                                }

                            }
                        });
                        distanceSections.sort();
                    }

                    Object.entries(vRecord).forEach(([kDistanceSection, vSecond]) => {
                        const nameDistanceSection = kDistanceSection.split("_")[1];
                        if (nameDistanceSection != "total") {
                            if (tVersusRecord[nameDistanceSection] == undefined || tVersusRecord[nameDistanceSection] == null) {
                                tVersusRecord[nameDistanceSection] = {};
                            }
                            tVersusRecord[nameDistanceSection][kHorse] = vSecond;
                        }
                    });
                });

                const tHorsesTmp = [];

                let isExistRecord = false;
                if (Object.keys(tVersusRecord).some(v => v == "0-200m")) {
                    isExistRecord = true;
                    Object.keys(tVersusRecord["0-200m"]).forEach(kHorse => {
                        tHorsesTmp.push(kHorse);
                    });
                }

                let dataSetRecord = [];

                const borderColors = [
                    "rgba(255,68,51,0.8)",
                    "rgba(0,100,100,0.8)",
                    "rgba(128,0,128,0.8)",
                    "rgba(65,105,225,0.8)",
                    "rgba(128,128,0,0.7)",
                    "rgba(0,128,128,0.7)",
                    "rgba(50,205,50,0.7)",
                    "rgba(192,64,0,0.7)",
                    "rgba(255,191,0,0.7)",
                    "rgba(128,0,128,0.7)",
                    "rgba(250,128,114,0.7)",
                    "rgba(200,0,200,0.7)",
                    "rgba(0,50,150,0.7)",
                    "rgba(0,0,150,0.7)",
                    "rgba(200,0,200,0.7)"
                ];

                if (isExistRecord) {
                    distanceSections.forEach(k => {
                        const kNo = parseInt(k.split("_")[0]);
                        const kName = k.split("_")[1];
                        dataSetRecord.push({
                            label: kName,
                            borderWidth: 1,
                            borderColor: borderColors[kNo],
                            backgroundColor: borderColors[kNo],
                            data: Object.values(tVersusRecord[kName])
                        });
                    });
                }
                this.chartDataVersusRecords = {
                    labels: tHorsesTmp,
                    datasets: dataSetRecord,
                    zoo: "zoo"
                };
                console.log("[104]z ",recordHorseInfos,dataSetRecord, isExistRecord)
                if (!isExistRecord) {
                    this.isEmptyChartDataVersusRecords = true;
                }
            } catch {
                this.renewCardRaceDataAll(); // #77 의 핵심부분 render 가 안된다는 것은 중간과정중 error 발생의미. 이를 catch해서 renewCard
            }
        },

        //
        // getInfoVersusSpeeds(target , range)
        // => // => return infoAnalyticsSpeed
        getInfoAnalyticsSpeed(target, range) {

            let isMinMaxAvg = false;
            let isLatest = false;
            switch (target) {
                case "Latest":
                    isLatest = true;
                    break;
                case "MinMaxAvg":
                    isMinMaxAvg = true;
                    break;
            }

            if (isLatest) {
                this.analyticsSpeedsLatest = range; // all 9 6 3
            }
            if (isMinMaxAvg) {
                this.analyticsSpeedsMinMaxAvg = range; // min max avg
            }

            let rawAnalyticsSpeed = this.infoHorseAll["analyticsSpeed"];
            let rawDates = [];
            rawAnalyticsSpeed.forEach(f => {
                f["Records"].forEach(g => {
                    rawDates.push(g["Date"]);
                });
            });

            let sortedDates = [];
            let targetDates = [];
            sortedDates = rawDates.sort((a, b) => a - b).map(s => {
                return parseInt(s);
            });
            switch (this.analyticsSpeedsLatest) {
                case "latest3":
                    targetDates = sortedDates.slice(-3);
                    break;
                case "latest6":
                    targetDates = sortedDates.slice(-6);
                    break;
                case "latest9":
                    targetDates = sortedDates.slice(-9);
                    break;
                default:
                    targetDates = sortedDates;
                    break;
            }

            const disQualifications = ["주행중지", "실격"];
            let recordDistanceInfo = {};
            rawAnalyticsSpeed.forEach(r => {
                let currDistance = r["DistMeter"];
                let filteredRecords = r["Records"].filter(e => {
                    return targetDates.includes(e["Date"]) && !disQualifications.includes(e["Note"]);
                });
                let bestBecordMilliSecondS1F = 1000000;
                let bestBecordMilliSecond1FG = 1000000;
                let bestBecordMilliSecond3FG = 1000000;
                let bestBecordMilliSecondSG = 1000000;
                let worstBecordMilliSecondS1F = 0;
                let worstBecordMilliSecond1FG = 0;
                let worstBecordMilliSecond3FG = 0;
                let worstBecordMilliSecondSG = 0;
                let averageBecordMilliSecondS1F = 0;
                let averageBecordMilliSecond1FG = 0;
                let averageBecordMilliSecond3FG = 0;
                let averageBecordMilliSecondSG = 0;
                let averageCount = 0;
                filteredRecords.map(mm => {
                    let currMeet = mm["Meet"];
                    let recordMilliSecond1FG = 0;
                    let recordMilliSecond3FG = 0;
                    let recordMilliSecondS1F = 0;
                    let recordMilliSecondSG = 0;
                    switch (currMeet) {
                        case 1:
                            recordMilliSecondS1F = this.convertMilliSecond(mm["SectionMeet1"]["RecordS1F"]);
                            recordMilliSecond1FG = this.convertMilliSecond(mm["SectionMeet1"]["Record1F_G"]);
                            recordMilliSecond3FG = this.convertMilliSecond(mm["SectionMeet1"]["Record3F_G"]);
                            recordMilliSecondSG = this.convertMilliSecond(mm["SectionMeet1"]["RecordTotal"]);
                            break;
                        case 2:
                            recordMilliSecondS1F = this.convertMilliSecond(mm["SectionMeet2"]["RecordS_1F"]);
                            recordMilliSecond1FG = this.convertMilliSecond(mm["SectionMeet2"]["RecordG_1F"]);
                            recordMilliSecond3FG = this.convertMilliSecond(mm["SectionMeet2"]["RecordG_3F"]);
                            recordMilliSecondSG = this.convertMilliSecond(mm["SectionMeet2"]["RecordTotal"]);
                            break;
                        case 3:
                            recordMilliSecondS1F = this.convertMilliSecond(mm["SectionMeet3"]["RecordS_1F"]);
                            recordMilliSecond1FG = this.convertMilliSecond(mm["SectionMeet3"]["Record1F_G"]);
                            recordMilliSecond3FG = this.convertMilliSecond(mm["SectionMeet3"]["Record3F_G"]);
                            recordMilliSecondSG = this.convertMilliSecond(mm["SectionMeet3"]["RecordTotal"]);
                            break;
                    }
                    return {
                        "recordMilliSecond1FG": recordMilliSecond1FG,
                        "recordMilliSecond3FG": recordMilliSecond3FG,
                        "recordMilliSecondS1F": recordMilliSecondS1F,
                        "recordMilliSecondSG": recordMilliSecondSG
                    };
                }).forEach(ff => {
                    bestBecordMilliSecondS1F = Math.min(bestBecordMilliSecondS1F, ff["recordMilliSecondS1F"]);
                    bestBecordMilliSecond1FG = Math.min(bestBecordMilliSecond1FG, ff["recordMilliSecond1FG"]);
                    bestBecordMilliSecond3FG = Math.min(bestBecordMilliSecond3FG, ff["recordMilliSecond3FG"]);
                    bestBecordMilliSecondSG = Math.min(bestBecordMilliSecondSG, ff["recordMilliSecondSG"]);
                    worstBecordMilliSecondS1F = Math.max(worstBecordMilliSecondS1F, ff["recordMilliSecondS1F"]);
                    worstBecordMilliSecond1FG = Math.max(worstBecordMilliSecond1FG, ff["recordMilliSecond1FG"]);
                    worstBecordMilliSecond3FG = Math.max(worstBecordMilliSecond3FG, ff["recordMilliSecond3FG"]);
                    worstBecordMilliSecondSG = Math.max(worstBecordMilliSecondSG, ff["recordMilliSecondSG"]);
                    averageCount = averageCount + 1;
                    averageBecordMilliSecondS1F = averageBecordMilliSecondS1F + ff["recordMilliSecondS1F"];
                    averageBecordMilliSecond1FG = averageBecordMilliSecond1FG + ff["recordMilliSecond1FG"];
                    averageBecordMilliSecond3FG = averageBecordMilliSecond3FG + ff["recordMilliSecond3FG"];
                    averageBecordMilliSecondSG = averageBecordMilliSecondSG + ff["recordMilliSecondSG"];
                });
                if (averageCount > 0) {
                    averageBecordMilliSecondS1F = averageBecordMilliSecondS1F / averageCount;
                    averageBecordMilliSecond1FG = averageBecordMilliSecond1FG / averageCount;
                    averageBecordMilliSecond3FG = averageBecordMilliSecond3FG / averageCount;
                    averageBecordMilliSecondSG = averageBecordMilliSecondSG / averageCount;

                    let bestS1F = Math.round(200 * 1000 / bestBecordMilliSecondS1F * 1000) / 1000;
                    if (bestS1F > 100) {
                        bestS1F = NaN;
                    }
                    let averageS1F = Math.round(200 * 1000 / averageBecordMilliSecondS1F * 1000) / 1000;
                    if (averageS1F > 100) {
                        averageS1F = NaN;
                    }
                    let worstS1F = Math.round(200 * 1000 / worstBecordMilliSecondS1F * 1000) / 1000;
                    if (worstS1F > 100) {
                        worstS1F = NaN;
                    }

                    let best1FG = Math.round(200 * 1000 / bestBecordMilliSecond1FG * 1000) / 1000;
                    if (best1FG > 100) {
                        best1FG = NaN;
                    }

                    let average1FG = Math.round(200 * 1000 / averageBecordMilliSecond1FG * 1000) / 1000;
                    if (average1FG > 100) {
                        average1FG = NaN;
                    }

                    let worst1FG = Math.round(200 * 1000 / worstBecordMilliSecond1FG * 1000) / 1000;
                    if (worst1FG > 100) {
                        worst1FG = NaN;
                    }

                    let best3FG = Math.round(600 * 1000 / bestBecordMilliSecond3FG * 1000) / 1000;
                    if (best3FG > 100) {
                        best3FG = NaN;
                    }

                    let average3FG = Math.round(600 * 1000 / averageBecordMilliSecond3FG * 1000) / 1000;
                    if (average3FG > 100) {
                        average3FG = NaN;
                    }

                    let worst3FG = Math.round(600 * 1000 / worstBecordMilliSecond3FG * 1000) / 1000;
                    if (worst3FG > 100) {
                        worst3FG = NaN;
                    }


                    let bestSG = Math.round(currDistance * 1000 / bestBecordMilliSecondSG * 1000) / 1000;
                    if (bestSG > 100) {
                        bestSG = NaN;
                    }

                    let averageSG = Math.round(currDistance * 1000 / worstBecordMilliSecondSG * 1000) / 1000;
                    if (averageSG > 100) {
                        averageSG = NaN;
                    }

                    let worstSG = Math.round(currDistance * 1000 / averageBecordMilliSecondSG * 1000) / 1000;
                    if (worstSG > 100) {
                        worstSG = NaN;
                    }


                    recordDistanceInfo[currDistance] = {
                        "BestS1F": bestS1F,
                        "AverageS1F": averageS1F,
                        "WorstS1F": worstS1F,
                        "Best1FG": best1FG,
                        "Average1FG": average1FG,
                        "Worst1FG": worst1FG,
                        "Best3FG": best3FG,
                        "Average3FG": average3FG,
                        "Worst3FG": worst3FG,
                        "BestSG": bestSG,
                        "WorstSG": averageSG,
                        "AverageSG": worstSG,
                        "Count": averageCount
                    };
                }
            });

            const pointStyle = "circle";
            const pointBorderWidth = 0;
            // const borderWidth = 1;
            const borderWidthAvg = 2;
            const lineTension = 0.0;
            const borderColors = [
                [
                    "rgba(255,68,51,0.7)",
                    "rgba(255,68,51,1)"
                ],
                [
                    "rgba(0,100,100,0.7)",
                    "rgba(0,100,100,1)"
                ],
                [
                    "rgba(128,0,128,0.7)",
                    "rgba(128,0,128,1)"
                ],
                [
                    "rgba(65,105,225,0.7)",
                    "rgba(65,105,225,1)"
                ],
                [
                    "rgba(128,128,0,0.7)",
                    "rgba(128,128,0,1)"
                ],
                [
                    "rgba(0,128,128,0.7)",
                    "rgba(0,128,128,1)"
                ],
                [
                    "rgba(50,205,50,0.7)",
                    "rgba(50,205,50,1)"
                ],
                [
                    "rgba(192,64,0,0.7)",
                    "rgba(192,64,0,1)"
                ],
                [
                    "rgba(255,191,0,0.7)",
                    "rgba(255,191,0,1)"
                ],
                [
                    "rgba(128,0,128,0.7)",
                    "rgba(128,0,128,1)"
                ],
                [
                    "rgba(250,128,114,0.7)",
                    "rgba(250,128,114,1)"
                ],
                [
                    "rgba(200,0,200,0.7)",
                    "rgba(200,0,200,1)"
                ],
                [
                    "rgba(0,50,150,0.7)",
                    "rgba(0,50,150,1)"
                ],
                [
                    "rgba(0,0,150,0.7)",
                    "rgba(0,0,150,1)"
                ],
                [
                    "rgba(200,0,200,0.7)",
                    "rgba(200,0,100,0.7)"
                ]
            ];
            let dataSetSpeed = [];
            let incrIndex = 0;

            Object.entries(recordDistanceInfo).forEach(([keyDistance, valueSpeed]) => {
                let dataMinMaxAvg = [];
                switch (this.analyticsSpeedsMinMaxAvg) {
                    case "min":
                        dataMinMaxAvg = [valueSpeed["WorstSG"], valueSpeed["WorstS1F"], valueSpeed["Worst1FG"], valueSpeed["Worst3FG"]];
                        break;
                    case "max":
                        dataMinMaxAvg = [valueSpeed["BestSG"], valueSpeed["BestS1F"], valueSpeed["Best1FG"], valueSpeed["Best3FG"]];
                        break;
                    case "avg":
                        dataMinMaxAvg = [valueSpeed["AverageSG"], valueSpeed["AverageS1F"], valueSpeed["Average1FG"], valueSpeed["Average3FG"]];
                        break;
                }
                // dataSetSpeed.push({
                //     label: keyDistance + "m" + " 최고",
                //     data: [valueSpeed["BestSG"], valueSpeed["BestS1F"], valueSpeed["Best1FG"], valueSpeed["Best3FG"]],
                //     borderColor: borderColors[incrIndex][1],
                //     type: "line",
                //     borderWidth: borderWidth,
                //     borderDash: [4, 3],
                //     fill: false,
                //     lineTension: lineTension,
                //     pointStyle: pointStyle,
                //     pointBorderWidth: pointBorderWidth,
                //     pointBorderColor: borderColors[incrIndex][1]
                // });
                dataSetSpeed.push({
                    label: keyDistance + "m",
                    data: dataMinMaxAvg,
                    borderColor: borderColors[incrIndex][0],
                    type: "line",
                    borderWidth: borderWidthAvg,
                    fill: false,
                    lineTension: lineTension,
                    pointStyle: pointStyle,
                    pointBorderWidth: pointBorderWidth,
                    pointBorderColor: borderColors[incrIndex][0]
                });
                // dataSetSpeed.push({
                //     label: keyDistance + "m" + " 최저",
                //     data: [valueSpeed["WorstSG"], valueSpeed["WorstS1F"], valueSpeed["Worst1FG"], valueSpeed["Worst3FG"]],
                //     borderColor: borderColors[incrIndex][1],
                //     type: "line",
                //     borderWidth: borderWidth,
                //     borderDash: [1, 3],
                //     fill: false,
                //     lineTension: lineTension,
                //     pointStyle: pointStyle,
                //     pointBorderWidth: pointBorderWidth,
                //     pointBorderColor: borderColors[incrIndex][1]
                // });
                incrIndex = incrIndex + 1;
            });
            this.chartDataAnalyticsSpeed = {
                labels: ["전체(SG)", "초반200m(S1F)", "후반200m(1FG)", "후반600m(3FG)"],
                datasets: dataSetSpeed
            };
        },

        fillDataChartAll() {
            console.log("[104][DetailCard.vue][fillDataChartAll] this.card_title-id : ", this.card_title["id"]);
            let existStoreDetailCardInfo = {};
            let tmpExistStoreDetailCardInfo = [];

            let tHorse = false;
            let tRace = false;

            if (this.card_title["target"] == "horse") {
                tmpExistStoreDetailCardInfo = this.$store.getters["detailCardStore/GET_DETAIL_HORSE"].filter(ff => ff["id"] == this.card_title["id"]);
                tHorse = true;
            } else { // "race"
                tmpExistStoreDetailCardInfo = this.$store.getters["detailCardStore/GET_DETAIL_RACE"].filter(ff => ff["id"] == this.card_title["id"]);
                tRace = true;
            }

            if (tmpExistStoreDetailCardInfo.length > 0) {
                existStoreDetailCardInfo = tmpExistStoreDetailCardInfo[0];
            } else {
                console.log("[DetailCard.vue][methods-fillDataChartAll] existStoreDetailCardInfo: Empty!");
            }

            const scrollID = this.$store.getters["detailCardStore/GET_DETAIL_SCROLL_ID"];
            if (existStoreDetailCardInfo["id"] != undefined && existStoreDetailCardInfo["id"] != scrollID) {

                if (tHorse) this.infoHorseAll = existStoreDetailCardInfo;
                if (tRace) this.infoRaceAll = existStoreDetailCardInfo;

            } else {
                if (tHorse) {
                    this.updateHorseDetailFromFireStore();
                }
                if (tRace) {
                    this.updateRaceDetailFromFireStore();
                }
            }

            //
            // Chips
            if (tHorse) {
                this.getInfoHorseChip();
                this.getInfoTrendPrize("all");
                this.getInfoTrendRating("all");
                this.getInfoTrendWeight("all");
                this.getInfoMatchHorseNumber("all");
                this.getInfoMatchJockey("all");
                this.getInfoMatchTool("all");
                this.getInfoMatchRaceDistance("all");
                this.getInfoMatchRaceTrackCondition("all");

                this.getInfoAnalyticsSpeed("Latest", "all");
                this.getInfoAnalyticsSpeed("MinMaxAvg", "avg");
            }
            if (tRace) {
                this.getInfoRaceChip();

                this.getInfoVersusSpeeds("versusSpeedsLatest", "all");
                this.getInfoVersusSpeeds("versusSpeedsMinMaxAvg", "avg");

                this.getInfoVersusRecords("versusRecordsLatest", "all");
                this.getInfoVersusRecords("versusRecordsMinMaxAvg", "avg");
            }
        },

        // 경주마의 기본 정보
        renewCardHorseDataAll() {
            console.log("[104][DetailCard.vue][renewCardHorseDataAll] this.card_title-id : ", this.card_title["id"]);
            this.analyticsLogEvent("click_renew_horseDetail");
            this.clickRangeToggle = false;
            this.updateHorseDetailFromFireStore();
        },

        renewCardRaceDataAll() {
            console.log("[104][DetailCard.vue][renewCardRaceDataAll] this.card_title-id : ", this.card_title["id"]);
            this.analyticsLogEvent("click_renew_raceDetail");
            this.clickRangeToggle = false;
            this.updateRaceDetailFromFireStore();
        },

        closeDetailCard() {
            this.analyticsLogEvent("click_close_horseDetail");
            this.clickRangeToggle = false;
            let renewHorseDetailDisplay = this.$store.getters["detailCardStore/GET_DETAIL_DISPLAY"];
            renewHorseDetailDisplay["items"] = renewHorseDetailDisplay["items"].filter(ff => {
                return ff["id"] != this.card_title["id"];
            });
            this.$store.commit("detailCardStore/MUT_DETAIL_DISPLAY_OVERWRITE", renewHorseDetailDisplay);
            localStorage.setItem("DetailDisplay", JSON.stringify(this.$store.getters["detailCardStore/GET_DETAIL_DISPLAY"]));
        },

        initData() {
            console.log("[104][DetailCard.vue][initData] this.card_title-id : ", this.card_title["id"]);
            this.clickRangeToggle = false;
            this.favoriteCard = false;
            this.rootPathShareURL = "https://" + location.host + "/share/";
            this.overlayNotiShareUrlMessage = "";
            this.showOverlayNotiShareURL = false;
            this.toggleActiveHorse = {
                analyticsSpeed: true,

                matchRaceDistance: false,
                matchJockey: false,
                matchHorseNumber: false,
                matchRaceTrackCondition: false,
                matchTool: false,

                trendPrize: false,
                trendRating: false,
                trendWeight: false,

                addChartList: false
            };
            this.radioButtonRangeHorse = {

                analyticsSpeedLatest: {
                    all: true,
                    latest3: false,
                    latest6: false,
                    latest9: false
                },
                analyticsSpeedMinMaxAvg: {
                    avg: true,
                    max: false,
                    min: false
                },
                matchRaceDistance: {
                    all: true,
                    latest3: false,
                    latest6: false,
                    latest9: false
                },
                matchJockey: {
                    all: true,
                    latest3: false,
                    latest6: false,
                    latest9: false
                },
                matchHorseNumber: {
                    all: true,
                    latest3: false,
                    latest6: false,
                    latest9: false
                },
                matchRaceTrackCondition: {
                    all: true,
                    latest3: false,
                    latest6: false,
                    latest9: false
                },
                matchTool: {
                    all: true,
                    latest3: false,
                    latest6: false,
                    latest9: false
                },
                trendPrize: {
                    all: true,
                    latest3: false,
                    latest6: false,
                    latest9: false
                },
                trendRating: {
                    all: true,
                    latest3: false,
                    latest6: false,
                    latest9: false
                },
                trendWeight: {
                    all: true,
                    latest3: false,
                    latest6: false,
                    latest9: false
                }
            };

            this.toggleActiveRace = {
                versusSpeeds: true,
                versusRecords: true,
                addChartList: false
            };
            this.radioButtonRangeRace = {
                versusSpeedsLatest: {
                    all: true,
                    latest3: false,
                    latest6: false,
                    latest9: false
                },

                versusSpeedsMinMaxAvg: {
                    avg: true,
                    max: false,
                    min: false
                },

                versusRecordsLatest: {
                    all: true,
                    latest3: false,
                    latest6: false,
                    latest9: false
                },

                versusRecordsMinMaxAvg: {
                    avg: true,
                    max: false,
                    min: false
                }
            };
            this.raceVersusSpeedsLatest = "all";
            this.raceVersusRecordsLatest = "all";
            this.raceVersusSpeedsMinMaxAvg = "avg";
            this.raceVersusRecordsMinMaxAvg = "avg";
            this.infoRaceAll = {};
            this.infoHorseAll = {};
            this.infoHorseAll2 = [];
            this.chartDataTrendPrize = null;
            this.chartDataTrendRating = null;
            this.chartDataTrendWeight = null;
            this.chartDataMatchHorseNumber = null;
            this.chartDataMatchJockey = null;
            this.chartDataMatchTool = null;
            this.chartDataMatchRaceDistance = null;
            this.chartDataMatchRaceTrackCondition = null;
            this.chartDataAnalyticsSpeed = null;
            this.chartDataVersusSpeeds = null;
            this.chartDataVersusRecords = null;
            this.isEmptyChartDataVersusSpeeds = false;
            this.isEmptyChartDataVersusRecords = false;
            this.horseNumberSummary = "";

            this.summaryTrendPrize = "";
            this.summaryTrendPrizes = [];
            this.summaryMatchHorseNumbers = [];
            this.summaryMatchJockeys = [];
            this.summaryMatchTools = [];
            this.summaryMatchRaceDistance = [];
            this.summaryMatchRaceTrackCondition = [];
            this.jockeySummary = "";
            this.toolSummary = "";
            this.chartData = [];
            this.addButtonActiveHorse = true;
            this.addButtonActiveRace = false;
            this.addHorseChartItems = {
                analyticsSpeed: {
                    label1: "ANALYTICS",
                    label2: "펄롱속도",
                    opened: true
                },
                matchRaceDistance: {
                    label1: "MATCH",
                    label2: "경주거리",
                    opened: true
                },
                matchJockey: {
                    label1: "MATCH",
                    label2: "기수",
                    opened: false
                },
                matchHorseNumber: {
                    label1: "MATCH",
                    label2: "마번",
                    opened: false
                },
                matchRaceTrackCondition: {
                    label1: "MATCH",
                    label2: "경주로상태",
                    opened: false
                },
                matchTool: {
                    label1: "MATCH",
                    label2: "장구(승)",
                    opened: false
                },
                trendPrize: {
                    label1: "TREND",
                    label2: "상금",
                    opened: true
                },
                trendRating: {
                    label1: "TREND",
                    label2: "레이팅",
                    opened: false
                },
                trendWeight: {
                    label1: "TREND",
                    label2: "마체중",
                    opened: false
                }
            };

            this.addRaceChartItems = {
                versusSpeeds: {
                    label1: "VERSUS",
                    label2: "구간속도",
                    opened: true
                },
                versusRecords: {
                    label1: "VERSUS",
                    label2: "구간기록",
                    opened: true
                }
            };

            this.raceChips = [
                // RaceName :       "일반" :             경주명
                {
                    color: "grey lighten-3",
                    icon: "mdi-tag-text-outline",
                    label: "경주명",
                    text: "-"
                },

                // RaceClass :      "국4등급" :           등급
                {
                    color: "grey lighten-3",
                    icon: "mdi-stairs",
                    label: "등급",
                    text: "-"
                },

                // RaceDistance :   1400 :              거리
                {
                    color: "grey lighten-3",
                    icon: "mdi-go-kart-track",
                    label: "거리",
                    text: "-"
                },

                // RaceStart :      "16:30" :           출발시간
                {
                    color: "grey lighten-3",
                    icon: "mdi-timer-outline",
                    label: "출발시간",
                    text: "-"
                },

                // RaceAgeLimit :   "연령오픈" :          연령
                {
                    color: "grey lighten-3",
                    icon: "mdi-chart-gantt",
                    label: "연령",
                    text: "-"
                },

                // RaceBurdenType : "핸디캡" :            부담종류
                {
                    color: "grey lighten-3",
                    icon: "mdi-weight-kilogram",
                    label: "부담종류",
                    text: "-"
                },

                // RaceRating :     "R1~50" :           레이팅
                {
                    color: "grey lighten-3",
                    icon: "mdi-counter",
                    label: "레이팅",
                    text: "-"
                },

                // RacePrize1 :     "31,000,000원" :     1위 상금
                {
                    color: "grey lighten-3",
                    icon: "mdi-numeric-1-circle-outline",
                    label: "1위상금",
                    text: "-"
                },

                // RacePrize2 :     "31,000,000원" :     2위 상금
                {
                    color: "grey lighten-3",
                    icon: "mdi-numeric-2-circle-outline",
                    label: "2위상금",
                    text: "-"
                },

                // RacePrize3 :     "31,000,000원" :     3위 상금
                {
                    color: "grey lighten-3",
                    icon: "mdi-numeric-3-circle-outline",
                    label: "3위상금",
                    text: "-"
                },

                // RacePrize4 :     "31,000,000원" :     4위 상금
                {
                    color: "grey lighten-3",
                    icon: "mdi-numeric-4-circle-outline",
                    label: "4위상금",
                    text: "-"
                },

                // RacePrize5 :     "31,000,000원" :     5위 상금
                {
                    color: "grey lighten-3",
                    icon: "mdi-numeric-5-circle-outline",
                    label: "5위상금",
                    text: "-"
                }
            ];
            this.horseChips = [
                {
                    color: "grey lighten-3",
                    icon: "mdi-horse-human",
                    label: "서울/제주/부경",
                    text: "-"
                },
                {
                    color: "grey lighten-3",
                    icon: "mdi-tag-outline",
                    label: "마번",
                    text: "-"
                },
                {
                    color: "grey lighten-3",
                    icon: "mdi-counter",
                    label: "레이팅",
                    text: "-"
                },
                {
                    color: "grey lighten-3",
                    icon: "mdi-stairs",
                    label: "등급",
                    text: "-"
                },
                {
                    color: "grey lighten-3",
                    icon: "mdi-gender-male-female",
                    label: "성별",
                    text: "-"
                },
                {
                    color: "grey lighten-3",
                    icon: "mdi-flag-outline",
                    label: "산지",
                    text: "-"
                },
                {
                    color: "grey lighten-3",
                    icon: "mdi-cake-variant",
                    label: "생년월일",
                    text: "-"
                },
                {
                    color: "grey lighten-3",
                    icon: "mdi-palette",
                    label: "마색",
                    text: "-"
                },
                {
                    color: "grey lighten-3",
                    icon: "mdi-calendar",
                    label: "출전기간",
                    text: "-"
                },
                {
                    color: "grey lighten-3",
                    icon: "mdi-chart-box-outline",
                    label: "통산전적",
                    text: "-"
                },
                {
                    color: "grey lighten-3",
                    icon: "mdi-crown-outline",
                    label: "승률 복승률 연승률",
                    text: "-"
                },
                {
                    color: "grey lighten-3",
                    icon: "mdi-cash-multiple",
                    label: "총 수득상금",
                    text: "-"
                },
                {
                    color: "grey lighten-3",
                    icon: "mdi-cash",
                    label: "최근6회 수득상금",
                    text: "-"
                },
                {
                    color: "grey lighten-3",
                    icon: "mdi-cash-100",
                    label: "최근3회 수득상금",
                    text: "-"
                }
            ];
        },


        genSummaryTrendPrize(range, totalRaceSize, sumProfit, countProfit, sumLoss, countLoss) {
            let summaries = [];
            let summaryText = "";

            if (range == "latest3") {
                summaryText += "최근3회 경주 중,";
            } else if (range == "latest6") {
                summaryText += "최근6회 경주 중,";
            } else if (range == "latest9") {
                summaryText += "최근9회 경주 중,";
            } else {
                summaryText += "모든경주 (총" + totalRaceSize.toString() + "회)";
            }
            summaries.push(summaryText);

            if (countProfit != 0) {
                summaryText = "순위권 안 (총" + countProfit.toString() + "회) : "
                    + sumProfit.toLocaleString() + "원 상금+";
                summaries.push(summaryText);
            }

            if (countLoss != 0) {
                summaryText = "순위권 밖 (총" + countLoss.toString() + "회) : "
                    + sumLoss.toLocaleString() + "원 상금-";
                summaries.push(summaryText);
            }


            return summaries;
        },


        genSummaryMatchHorseNumber(range, infoHorseNumber, infoSummaryHorseNumber) {

            let summaries = [];
            let summaryText = "";
            let bestHorseNumber = "";
            let bestActual = 1;
            Object.keys(infoHorseNumber).forEach(k => {
                const v = infoHorseNumber[k];
                if (bestActual < v.ActualPlus) {
                    bestActual = v.ActualPlus;
                    bestHorseNumber = k;
                } else if (bestActual == v.ActualPlus) {
                    bestHorseNumber = bestHorseNumber + "," + k;
                }
            });

            Object.keys(infoSummaryHorseNumber).forEach(k => {
                const v = infoSummaryHorseNumber[k];
                if (v != "총 0회 (0순위권/0비순위권)") {
                    summaryText = k + "번마: " + v;
                    if (bestHorseNumber.includes(k)) {
                        summaryText += " (최고성적★)";
                    }
                    summaries.push(summaryText);
                }
            });


            return summaries;
        },


        genSummaryMatchRaceDistance(range, infoRaceDistance, infoSummaryRaceDistance) {

            let summaries = [];
            let summaryText = "";
            let bestRaceDistance = "";
            let bestActual = 1;
            Object.keys(infoRaceDistance).forEach(k => {
                const v = infoRaceDistance[k];
                if (bestActual < v.ActualPlus) {
                    bestActual = v.ActualPlus;
                    bestRaceDistance = k;
                } else if (bestActual == v.ActualPlus) {
                    bestRaceDistance = bestRaceDistance + "," + k;
                }
            });

            Object.keys(infoSummaryRaceDistance).forEach(k => {
                const v = infoSummaryRaceDistance[k];
                if (v != "총 0회 (0순위권/0비순위권)") {
                    summaryText = k + "m: " + v;
                    if (bestRaceDistance.includes(k)) {
                        summaryText += " (최고성적★)";
                    }
                    summaries.push(summaryText);
                }
            });
            return summaries;
        },


        genSummaryMatchRaceTrackCondition(range, infoRaceTrackCondition, infoSummaryRaceTrackCondition) {

            let summaries = [];
            let summaryText = "";
            let bestRaceDistance = "";
            let bestActual = 1;
            Object.keys(infoRaceTrackCondition).forEach(k => {
                const v = infoRaceTrackCondition[k];
                if (bestActual < v.ActualPlus) {
                    bestActual = v.ActualPlus;
                    bestRaceDistance = k;
                } else if (bestActual == v.ActualPlus) {
                    bestRaceDistance = bestRaceDistance + "," + k;
                }
            });

            Object.keys(infoSummaryRaceTrackCondition).sort().forEach(k => {
                const v = infoSummaryRaceTrackCondition[k];
                if (v != "총 0회 (0순위권/0비순위권)") {
                    summaryText = k.split("_")[1] + ": " + v;
                    if (bestRaceDistance.includes(k)) {
                        summaryText += " (최고성적★)";
                    }
                    summaries.push(summaryText);
                }
            });
            return summaries;
        },


        genSummaryMatchJockey(range, infoJockey, infoSummaryJockey) {

            let summaries = [];
            let summaryText = "";
            let bestJockey = "";
            let bestActual = 1;
            Object.keys(infoJockey).forEach(k => {
                const v = infoJockey[k];
                if (bestActual < v.ActualPlus) {
                    bestActual = v.ActualPlus;
                    bestJockey = k;
                } else if (bestActual == v.ActualPlus) {
                    bestJockey = bestJockey + "," + k;
                }
            });

            Object.keys(infoSummaryJockey).forEach(k => {
                const v = infoSummaryJockey[k];
                if (v != "총 0회 (0순위권/0비순위권)") {
                    summaryText = k.split("-")[0] + " 기수: " + v;
                    if (bestJockey.includes(k)) {
                        summaryText += " (최고성적★)";// 매칭률";
                    }
                    summaries.push(summaryText);
                }
            });

            return summaries;
        },


        genSummaryMatchTool(range, infoTool, infoSummaryTool) {

            let summaries = [];
            let summaryText = "";
            let bestTool = "";
            let bestActual = 1;
            Object.keys(infoTool).forEach(k => {
                const v = infoTool[k];
                if (bestActual < v.ActualPlus) {
                    bestActual = v.ActualPlus;
                    bestTool = k;
                } else if (bestActual == v.ActualPlus) {
                    bestTool = bestTool + "," + k;
                }
            });

            let sortedTool = [];
            sortedTool = Object.keys(infoTool).sort((a, b) => a - b);
            if (sortedTool.includes("미착용")) {
                sortedTool = sortedTool.filter(f => f != "미착용");
                sortedTool.unshift("미착용");
            }


            sortedTool.forEach(k => {
                const v = infoSummaryTool[k];
                if (v != "총 0회 (0순위권/0비순위권)") {
                    if (k == "미착용") {
                        k = "미";
                    }
                    summaryText = k + " 착용: " + v;
                    if (bestTool.includes(k)) {
                        summaryText += " (최고성적★)";// 매칭률";
                    }
                    summaries.push(summaryText);
                }
            });
            return summaries;
        }
    },
    watch: {},
    data() {
        return {
            currTS : "",
            toggleBtnName: "다른 분석 열기",
            colorCard: "grey lighten-3",
            colorChipCard: "grey lighten-5",
            loading: false,
            name: "",
            active: "",
            clickRangeToggle: false,
            cannotFavoriteMessageOfDetailCard: "좋아요 기능은\n로그인 후에 사용할 수 있어요 :D",
            rootPathShareURL: "https://" + location.host + "/share/",
            overlayNotiShareUrlMessage: "",
            showOverlayNotiShareURL: false,
            zIndex: 0,
            favoriteCard: false,
            // cardLoading: false,
            // model: null,
            // 1. TREND 상금
            // 2. TREND 레이팅
            // 3. TREND 마체중 -->
            // 4. MATCH 마번 -->
            // 5. MATCH 기수 -->
            // 6. MATCH 장구(승) -->
            // 2. MATCH 경주거리 -->
            // 2. MATCH 경주로 : 건조(x<6) / 양호(6<=x<10) / 다습(10<=x<15) / 포화(15<=x<20) / 불량(20<=x)-->
            // 2. Analytics 스피드 -->
            //
            // Toggle
            toggleActiveHorse: {
                analyticsSpeed: true,

                matchRaceDistance: false,
                matchJockey: false,
                matchHorseNumber: false,
                matchRaceTrackCondition: false,
                matchTool: false,

                trendPrize: false,
                trendRating: false,
                trendWeight: false,

                addChartList: false
            },
            radioButtonRangeHorse: {
                trendPrize: {
                    all: true,
                    latest3: false,
                    latest6: false,
                    latest9: false
                },
                trendRating: {
                    all: true,
                    latest3: false,
                    latest6: false,
                    latest9: false
                },
                trendWeight: {
                    all: true,
                    latest3: false,
                    latest6: false,
                    latest9: false
                },
                matchHorseNumber: {
                    all: true,
                    latest3: false,
                    latest6: false,
                    latest9: false
                },
                matchJockey: {
                    all: true,
                    latest3: false,
                    latest6: false,
                    latest9: false
                },
                matchTool: {
                    all: true,
                    latest3: false,
                    latest6: false,
                    latest9: false
                },
                matchRaceDistance: {
                    all: true,
                    latest3: false,
                    latest6: false,
                    latest9: false
                },
                matchRaceTrackCondition: {
                    all: true,
                    latest3: false,
                    latest6: false,
                    latest9: false
                },
                analyticsSpeedLatest: {
                    all: true,
                    latest3: false,
                    latest6: false,
                    latest9: false
                },
                analyticsSpeedMinMaxAvg: {
                    avg: true,
                    max: false,
                    min: false
                }
            },

            toggleActiveRace: {
                versusSpeeds: true,
                versusRecords: true,
                // ,top3: false

                addChartList: false
            },
            radioButtonRangeRace: {
                versusSpeedsLatest: {
                    all: true,
                    latest9: false,
                    latest6: false,
                    latest3: false
                },
                versusSpeedsMinMaxAvg: {
                    avg: true,
                    max: false,
                    min: false
                },
                versusRecordsLatest: {
                    all: true,
                    latest9: false,
                    latest6: false,
                    latest3: false
                },
                versusRecordsMinMaxAvg: {
                    avg: true,
                    max: false,
                    min: false
                }
            },

            raceVersusSpeedsLatest: "",
            raceVersusSpeedsMinMaxAvg: "",

            analyticsSpeedsLatest: "",
            analyticsSpeedsMinMaxAvg: "",

            infoRaceAll: {},
            infoHorseAll: {},
            infoHorseAll2: [],
            chartDataTrendPrize: null,
            chartDataTrendRating: null,
            chartDataTrendWeight: null,
            chartDataMatchHorseNumber: null,
            chartDataMatchJockey: null,
            chartDataMatchTool: null,
            chartDataMatchRaceDistance: null,
            chartDataMatchRaceTrackCondition: null,
            chartDataAnalyticsSpeed: null,
            chartDataVersusSpeeds: null,
            chartDataVersusRecords: null,
            isEmptyChartDataVersusSpeeds: false,
            isEmptyChartDataVersusRecords: false,
            horseNumberSummary: "",
            summaryTrendPrize: "",
            summaryTrendPrizes: [],
            summaryMatchHorseNumbers: [],
            summaryMatchJockeys: [],
            summaryMatchTools: [],
            summaryMatchRaceDistance: [],
            summaryMatchRaceTrackCondition: [],
            jockeySummary: "",
            toolSummary: "",
            chartData: [],
            addButtonActiveHorse: true,
            addButtonActiveRace: false,
            addHorseChartItems: {
                analyticsSpeed: {
                    label1: "ANALYTICS",
                    label2: "펄롱속도",
                    opened: true
                },
                matchRaceDistance: {
                    label1: "MATCH",
                    label2: "경주거리",
                    opened: true
                },
                matchJockey: {
                    label1: "MATCH",
                    label2: "기수",
                    opened: false
                },
                matchHorseNumber: {
                    label1: "MATCH",
                    label2: "마번",
                    opened: false
                },
                matchRaceTrackCondition: {
                    label1: "MATCH",
                    label2: "경주로상태",
                    opened: false
                },
                matchTool: {
                    label1: "MATCH",
                    label2: "장구(승)",
                    opened: false
                },
                trendPrize: {
                    label1: "TREND",
                    label2: "상금",
                    opened: true
                },
                trendRating: {
                    label1: "TREND",
                    label2: "레이팅",
                    opened: false
                },
                trendWeight: {
                    label1: "TREND",
                    label2: "마체중",
                    opened: false
                }
            },

            addRaceChartItems: {
                versusSpeeds: {
                    label1: "VERSUS",
                    label2: "구간속도",
                    opened: true
                },
                versusRecords: {
                    label1: "VERSUS",
                    label2: "구간기록",
                    opened: true
                }
                // ,
                // top3: {
                //     label1: "PREDICT",
                //     label2: "TOP3",
                //     opened: true
                // }
            },


            raceChips: [

                // 0
                // RaceName :       "일반" :             경주명
                {
                    color: "grey lighten-3",
                    icon: "mdi-tag-text-outline",
                    label: "경주명",
                    text: "-"
                },

                // 1
                // RaceClass :      "국4등급" :           등급
                {
                    color: "grey lighten-3",
                    icon: "mdi-stairs",
                    label: "등급",
                    text: "-"
                },

                // 2
                // RaceDistance :   1400 :              거리
                {
                    color: "grey lighten-3",
                    icon: "mdi-go-kart-track",
                    label: "거리",
                    text: "-"
                },

                // 3
                // RaceStart :      "16:30" :           출발시간
                {
                    color: "grey lighten-3",
                    icon: "mdi-timer-outline",
                    label: "출발시간",
                    text: "-"
                },

                // 4
                // RaceAgeLimit :   "연령오픈" :          연령
                {
                    color: "grey lighten-3",
                    icon: "mdi-chart-gantt",
                    label: "연령",
                    text: "-"
                },

                // 5
                // RaceBurdenType : "핸디캡" :            부담종류
                {
                    color: "grey lighten-3",
                    icon: "mdi-weight-kilogram",
                    label: "부담종류",
                    text: "-"
                },

                // 6
                // RaceRating :     "R1~50" :           레이팅
                {
                    color: "grey lighten-3",
                    icon: "mdi-counter",
                    label: "레이팅",
                    text: "-"
                },

                // 7
                // RacePrize1 :     "31,000,000원" :     1위 상금
                {
                    color: "grey lighten-3",
                    icon: "mdi-numeric-1-circle-outline",
                    label: "1위상금",
                    text: "-"
                },

                // 8
                // RacePrize2 :     "31,000,000원" :     2위 상금
                {
                    color: "grey lighten-3",
                    icon: "mdi-numeric-2-circle-outline",
                    label: "2위상금",
                    text: "-"
                },

                // 9
                // RacePrize3 :     "31,000,000원" :     3위 상금
                {
                    color: "grey lighten-3",
                    icon: "mdi-numeric-3-circle-outline",
                    label: "3위상금",
                    text: "-"
                },

                // 10
                // RacePrize4 :     "31,000,000원" :     4위 상금
                {
                    color: "grey lighten-3",
                    icon: "mdi-numeric-4-circle-outline",
                    label: "4위상금",
                    text: "-"
                },

                // 11
                // RacePrize5 :     "31,000,000원" :     5위 상금
                {
                    color: "grey lighten-3",
                    icon: "mdi-numeric-5-circle-outline",
                    label: "5위상금",
                    text: "-"
                }
            ],

            horseChips: [
                {
                    color: "grey lighten-3",
                    icon: "mdi-horse-human",
                    label: "서울/제주/부경",
                    text: "-"
                },
                {
                    color: "grey lighten-3",
                    icon: "mdi-tag-outline",
                    label: "마번",
                    text: "-"
                },
                {
                    color: "grey lighten-3",
                    icon: "mdi-counter",
                    label: "레이팅",
                    text: "-"
                },
                {
                    color: "grey lighten-3",
                    icon: "mdi-stairs",
                    label: "등급",
                    text: "-"
                },
                {
                    color: "grey lighten-3",
                    icon: "mdi-gender-male-female",
                    label: "성별",
                    text: "-"
                },
                {
                    color: "grey lighten-3",
                    icon: "mdi-flag-outline",
                    label: "산지",
                    text: "-"
                },
                {
                    color: "grey lighten-3",
                    icon: "mdi-cake-variant",
                    label: "생년월일",
                    text: "-"
                },
                {
                    color: "grey lighten-3",
                    icon: "mdi-palette",
                    label: "마색",
                    text: "-"
                },
                {
                    color: "grey lighten-3",
                    icon: "mdi-calendar",
                    label: "출전기간",
                    text: "-"
                },
                {
                    color: "grey lighten-3",
                    icon: "mdi-chart-box-outline",
                    label: "통산전적",
                    text: "-"
                },
                {
                    color: "grey lighten-3",
                    icon: "mdi-crown-outline",
                    label: "승률 복승률 연승률",
                    text: "-"
                },
                {
                    color: "grey lighten-3",
                    icon: "mdi-cash-multiple",
                    label: "총 수득상금",
                    text: "-"
                },
                {
                    color: "grey lighten-3",
                    icon: "mdi-cash",
                    label: "최근6회 수득상금",
                    text: "-"
                },
                {
                    color: "grey lighten-3",
                    icon: "mdi-cash-100",
                    label: "최근3회 수득상금",
                    text: "-"
                }
            ]
        };
    }
};
</script>
<style scoped></style>