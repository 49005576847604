<script>

import { Bar, mixins } from "vue-chartjs";

const { reactiveProp } = mixins;

export default {
    extends: Bar,
    mixins: [reactiveProp],
    data() {
        return {
            options: {
                tooltips: {
                    position:  'average',
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                        // label: function(tooltipItems, data) {
                        //     return data.datasets[tooltipItems.datasetIndex].label.substr(0, 5) + ": " + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index];
                        // },
                        footer: function() {
                            return " -단위 : 속도 (m/s)";
                        }
                    }
                },
                scales: {
                    yAxes: [
                        {
                            gridLines: {
                                display: true
                            },
                            scaleLabel: {
                                display: true,
                                fontColor: "#111",
                                labelString: "속도 ( m / s )",
                                fontSize: 15,
                                padding: 0
                            },
                            ticks: {
                                stepSize: 0.5
                            }
                        }
                    ],
                    xAxes: [
                        {
                            stacked: true,
                            gridLines: {
                                display: true
                            },
                        }
                    ]
                },
                legend: {
                    display: true
                },
                responsive: true,
                maintainAspectRatio: false
            }
        };
    },
    mounted() {
        this.renderChart(this.chartData, this.options);
    }
};
</script>
