<template>
    <v-card
        :loading="loading"
        elevation="0"
        tile
        outlined
        class="mt-1 blue-grey lighten-4">
        <v-card-title class="headline font-weight-medium">
            <v-icon>mdi-clipboard-list-outline</v-icon>
            <v-spacer />
            이번주 출전정보
            <v-spacer />
            <v-btn icon @click="clickRenewOfThisWeekLineUp()">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
        </v-card-title>

        <v-card-subtitle class="text-end mt-0 mb-3 mr-5 ml-0 pa-0 black--text">
            {{ latestThisWeekLineUp["dateRange"] }}
            <!-- {{ latestThisWeekLineUp["ts"] }}-->
        </v-card-subtitle>

        <v-row class="py-0 px-3 mx-0 my-1 pr-2 align-center">
            <v-col cols="12" class="pa-0 ma-0  text-right">
                <v-btn elevation="0"
                       x-small
                       outlined
                       tile
                       :class=selectedStyleLocationSeoul
                       @click="clickToggle('서울', '','')"
                >서울경마
                </v-btn>
                <v-btn elevation="0"
                       x-small
                       outlined
                       tile
                       :class=selectedStyleLocationJeju
                       @click="clickToggle('제주', '','')"
                >제주경마
                </v-btn>
                <v-btn elevation="0"
                       x-small
                       outlined
                       tile
                       :class=selectedStyleLocationBK
                       @click="clickToggle('부경', '','')"
                >부경경마
                </v-btn>
            </v-col>
        </v-row>


        <v-row class="py-0 px-3 ma-0 align-center">
            <v-col cols="12" class="pa-0 ma-0 text-center">
                <v-divider class="mx-2 grey"></v-divider>
            </v-col>
        </v-row>


        <v-row class="py-0 px-3 mx-0 my-1 pr-2 align-center">
            <v-col cols="12" class="pa-0 ma-0  text-right">
                <div v-if="(listDate !== null) && (listDate !== undefined) && (listDate.length !== 0) ">

                    <v-btn
                        v-for="(item, index) in listDate" :key="index"
                        elevation="0"
                        x-small
                        outlined
                        tile
                        :class=selectedStyleDate[item]
                        @click="clickToggle(selectedLocation, item,'')"
                    > {{ item }}
                    </v-btn>
                </div>
                <div v-else>
                    <v-btn

                        elevation="0"
                        x-small
                        outlined
                        tile
                        class="mx-1 my-0 px-2 py-0 text-overline white--text font-weight-black grey darken-3"
                    >
                        날짜 미확정
                    </v-btn>
                </div>
            </v-col>
        </v-row>


        <v-row class="py-0 px-3 ma-0 align-center">
            <v-col cols="12" class="pa-0 ma-0 text-center">
                <v-divider class="mx-2 grey"></v-divider>
            </v-col>
        </v-row>

        <v-row class="py-0 px-3 mx-0 my-1 pr-2 align-center">
            <v-col cols="12" class="pa-0 ma-0  text-right">
                <div v-if="(listRaceNo !== null) && (listRaceNo !== undefined) && (listRaceNo.length !== 0)">
                    <v-btn
                        v-for="(item, index) in listRaceNo" :key="index"
                        elevation="0"
                        x-small
                        outlined
                        tile
                        :class="selectedStyleRaceNo[item]"
                        @click="clickToggle(selectedLocation, selectedDate,item)"
                    > {{ item }}
                    </v-btn>
                </div>
                <div v-else>
                    <v-btn

                        elevation="0"
                        x-small
                        outlined
                        tile
                        class="mx-1 my-0 px-2 py-0 text-overline font-weight-black white--text orange"
                    > 경주 미확정
                    </v-btn>
                </div>
            </v-col>
        </v-row>

        <!-- <v-divider class="mx-5"></v-divider>-->

        <v-simple-table v-if="existShowHorseItems"  height="265px" class="mx-2 my-3 py-2 px-2 black--text grey lighten-4" >
            <template v-slot:default>
                <tbody>
                <tr
                    v-for="(item, index) in showHorseItems"
                    :key="index"
                    @click="clickThisWeekLineUp(item, true)"
                >
                    <td v-if="index==0" class="text-center"></td>
                    <td v-else class="text-center">{{ index }}번마</td>

                    <td v-if="index==0" class="text-center">
                        <b> {{ selectedLocation }} - {{ selectedDate }} - {{ Number(selectedRaceNo) }} </b>
                        <v-icon x-small color="black">mdi-open-in-new</v-icon>
                    </td>
                    <td v-else-if=item.existHorseDetailData class="text-center">
                        <b> {{ item["horse"]["name"] }} </b>
                        <v-icon x-small color="black">mdi-open-in-new</v-icon>
                    </td>
                    <td v-else class="text-center font-weight-light grey--text text--darken-2">
                        <b> {{ item["horse"]["name"] }} </b>
                        <v-icon x-small color="grey darken-2">mdi-close</v-icon>
                    </td>

                    <td v-if="index==0" class="text-center"></td>
                    <td v-else class="text-center">{{ getLocationOfThisWeekLineUp(item) }}</td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>

        <v-card
            v-if=!existShowHorseItems
            elevation="0"
            height="265px" class="mx-2 my-3 py-2 px-2 align-center" >
<!--            class="mt-4 mb-2 ml-5 mr-0  px-0 align-center"-->
<!--        >-->

            <v-row justify="center mt-5">
                <v-img
                    max-height="112"
                    max-width="112"
                    class="text-center"
                    src="../assets/logo_128x128.png"
                    alt="경마-말고고-경마분석-경마문화-서울경마-제주경마-부경경마-문자-경주마-없음-알림"
                ></v-img>
            </v-row>
            <v-row class="text-center">
                <v-col col="12">
                    <v-card-text
                        class="text-center text-h6 blue-grey--text font-weight-black ma-0 pa-0">
                        출전정보 미확정
                    </v-card-text>
                    <v-card-text
                        class="text-center text-subtitle-1 black--text font-weight-black ma-0 pa-0">
                        {{ selectedLocation }}
                    </v-card-text>
                    <v-card-text class="text-body-2 text-center ma-0 pa-0">
                        {{ latestThisWeekLineUp["dateRange"] }}
                    </v-card-text>
                </v-col>
            </v-row>
        </v-card>

        <!-- <br /> -->
    </v-card>
</template>

<script>
import firebase from "firebase";


export default {

    name: "ThisWeekLineUpCard",

    computed: {},

    created() {
        this.loadLatestThisWeekLineUp();
    },

    watch: {},

    data() {

        return {

            "loading": false,
            "latestThisWeekLineUp": {},

            "selectedStyleLocationSeoul": "",
            "selectedStyleLocationJeju": "",
            "selectedStyleLocationBK": "",
            "styleLocationSeoul": "mx-1 my-0 px-5 py-0 text-overline font-weight-black white--text red",
            "styleLocationJeju": "mx-1 my-0 px-5 py-0 text-overline font-weight-black white--text blue",
            "styleLocationBK": "mx-1 my-0 px-5 py-0 text-overline font-weight-black white--text green",
            "styleLocationNone": "mx-1 my-0 px-5 py-0 text-overline grey--text text--darken-3",

            "selectedStyleDate": {},
            "styleDateTrue": "mx-1 my-0 px-2 py-0 text-overline white--text font-weight-black grey darken-3",
            "styleDateFalse": "mx-1 my-0 px-2 py-0 text-overline grey--text text--darken-3",

            "selectedStyleRaceNo": {},
            "styleRaceNoTrue": "mx-1 mt-0 mb-1 pa-0 text-overline white--text orange",
            "styleRaceNoFalse": "mx-1 mt-0 mb-1 pa-0 text-overline grey--text text--darken-2",


            "listLocation": ["서울", "제주", "부경"],
            "listDate": [],
            "listRaceNo": [],

            "selectedLocation": "",
            "selectedDate": "",
            "selectedRaceNo": "",


            "showHorseItems": [],
            "existShowHorseItems": true
        };
    },
    methods: {
        checkToggleLocation(target) {
            return target === this.selectedLocation;
        },
        checkToggleDate(target) {
            return target === this.selectedDate;
        },
        checkToggleRaceNo(target) {
            return target === this.selectedRaceNo;
        },

        getCurrentWeekNumber() {

        },

        loadLatestThisWeekLineUp() {

            this.loading = true;
            // todo 갱신 주기. 현재의 WeekID 와 Data의 WeekID 그리고 TS 를 비교 해야 한다.
            const _this = this;
            firebase.firestore().collection("RaceLineUp").doc("Latest").get().then(function(doc) {
                // firebase.firestore().collection("RaceLineUp").doc("2021-08").get().then(function(doc) {
                let meet1Map = {};
                let meet2Map = {};
                let meet3Map = {};
                let existMeet1 = false;
                let existMeet2 = false;
                let existMeet3 = false;

                const tid = doc.data().TID;
                let meet1 = doc.data().Meet1;
                let meet2 = doc.data().Meet2;
                let meet3 = doc.data().Meet3;

                const weekID = doc.data().WeekID;
                const dateRange = doc.data().DateRange;

                console.log("[HELLO] : ", tid, meet1, meet2, meet3);
                if ((meet1 !== null) && (meet1 !== undefined) && (meet1.length !== 0)) {
                    meet1.forEach(f => {
                        let tDate = f.Date.toString(); // string
                        // let tMeet = f.Meet; // int
                        let tRaceNo = f.RaceNo.toString(); // string
                        if (tRaceNo.length === 1) {
                            tRaceNo = "0" + tRaceNo;
                        }

                        if ((meet1Map["dates"] === undefined) || (meet1Map["dates"] === null)) {
                            meet1Map["dates"] = [tDate];
                        } else {
                            meet1Map["dates"].push(tDate);
                        }

                        if ((meet1Map[tDate] === undefined) || (meet1Map[tDate] === null)) {
                            meet1Map[tDate] = {};
                        }

                        if ((meet1Map[tDate]["races"] === undefined) || (meet1Map[tDate]["races"] === null)) {
                            meet1Map[tDate]["races"] = [tRaceNo];
                        } else {
                            meet1Map[tDate]["races"].push(tRaceNo);
                        }
                        meet1Map[tDate][tRaceNo] = f.Horses.map(g => {
                            const number = g.Number;
                            const id = g.HorseID;
                            const idSplit = g.HorseID.split("-");
                            const target = "horse";
                            const horse = {
                                name: idSplit[1],
                                meet: parseInt(idSplit[2]),
                                hid: idSplit[3]
                            };
                            const existHorseDetailData = g.ExistHorseDetailData;
                            return {
                                number: number,
                                id: id,
                                target: target,
                                horse: horse,
                                existHorseDetailData: existHorseDetailData
                            };
                        });
                    });
                    meet1Map["dates"] = meet1Map["dates"].filter((v, i, a) => a.indexOf(v) === i);
                    meet1Map["dates"] = meet1Map["dates"].sort();
                    existMeet1 = true;
                }

                if ((meet2 !== null) && (meet2 !== undefined) && (meet2.length !== 0)) {
                    meet2.forEach(f => {
                        let tDate = f.Date.toString(); // string
                        // let tMeet = f.Meet; // int
                        let tRaceNo = f.RaceNo.toString(); // string
                        if (tRaceNo.length === 1) {
                            tRaceNo = "0" + tRaceNo;
                        }

                        if ((meet2Map["dates"] === undefined) || (meet2Map["dates"] === null)) {
                            meet2Map["dates"] = [tDate];
                        } else {
                            meet2Map["dates"].push(tDate);
                        }

                        if ((meet2Map[tDate] === undefined) || (meet2Map[tDate] === null)) {
                            meet2Map[tDate] = {};
                        }

                        if ((meet2Map[tDate]["races"] === undefined) || (meet2Map[tDate]["races"] === null)) {
                            meet2Map[tDate]["races"] = [tRaceNo];
                        } else {
                            meet2Map[tDate]["races"].push(tRaceNo);
                        }
                        meet2Map[tDate][tRaceNo] = f.Horses.map(g => {
                            const number = g.Number;
                            const id = g.HorseID;
                            const idSplit = g.HorseID.split("-");
                            const target = "horse";
                            const horse = {
                                name: idSplit[1],
                                meet: parseInt(idSplit[2]),
                                hid: idSplit[3]
                            };
                            const existHorseDetailData = g.ExistHorseDetailData;
                            return {
                                number: number,
                                id: id,
                                target: target,
                                horse: horse,
                                existHorseDetailData: existHorseDetailData
                            };
                        });
                    });
                    meet2Map["dates"] = meet2Map["dates"].filter((v, i, a) => a.indexOf(v) === i);
                    meet2Map["dates"] = meet2Map["dates"].sort();
                    existMeet2 = true;
                }

                if ((meet3 !== null) && (meet3 !== undefined) && (meet3.length !== 0)) {

                    meet3.forEach(f => {
                        let tDate = f.Date.toString(); // string
                        // let tMeet = f.Meet; // int
                        let tRaceNo = f.RaceNo.toString(); // string
                        if (tRaceNo.length === 1) {
                            tRaceNo = "0" + tRaceNo;
                        }


                        if ((meet3Map["dates"] === undefined) || (meet3Map["dates"] === null)) {
                            meet3Map["dates"] = [tDate];
                        } else {
                            meet3Map["dates"].push(tDate);
                        }

                        if ((meet3Map[tDate] === undefined) || (meet3Map[tDate] === null)) {
                            meet3Map[tDate] = {};
                        }

                        if ((meet3Map[tDate]["races"] === undefined) || (meet3Map[tDate]["races"] === null)) {
                            meet3Map[tDate]["races"] = [tRaceNo];
                        } else {
                            meet3Map[tDate]["races"].push(tRaceNo);
                        }
                        meet3Map[tDate][tRaceNo] = f.Horses.map(g => {
                            const number = g.Number;
                            const id = g.HorseID;
                            const idSplit = g.HorseID.split("-");
                            const target = "horse";
                            const horse = {
                                name: idSplit[1],
                                meet: parseInt(idSplit[2]),
                                hid: idSplit[3]
                            };
                            const existHorseDetailData = g.ExistHorseDetailData;
                            return {
                                number: number,
                                id: id,
                                target: target,
                                horse: horse,
                                existHorseDetailData: existHorseDetailData
                            };
                        });
                    });
                    meet3Map["dates"] = meet3Map["dates"].filter((v, i, a) => a.indexOf(v) === i);
                    meet3Map["dates"] = meet3Map["dates"].sort();
                    existMeet3 = true;
                }

                console.log("HELLO : meet1Map: ", meet1Map);
                console.log("HELLO : meet2Map: ", meet2Map);
                console.log("HELLO : meet3Map: ", meet3Map);

                _this.latestThisWeekLineUp = {
                    "서울": meet1Map,
                    "제주": meet2Map,
                    "부경": meet3Map,
                    "ts": new Date().getTime(),
                    "weekID": weekID,
                    "dateRange": dateRange
                };
                console.log("HELLO : this.latestThisWeekLineUp : ", _this.latestThisWeekLineUp, existMeet1, existMeet2, existMeet3);

                if (existMeet1) {
                    _this.clickToggle("서울", "", "");
                } else if (existMeet2) {
                    _this.clickToggle("제주", "", "");
                } else if (existMeet3) {
                    _this.clickToggle("부경", "", "");
                }

                const tmpDisplayItem = _this.$store.getters["detailCardStore/GET_DETAIL_DISPLAY"]["items"];
                console.log("HELLO : tmpDisplayItem: ", tmpDisplayItem);
                if (tmpDisplayItem !== null && tmpDisplayItem !== undefined && tmpDisplayItem.length === 0) {

                    let tmpLocation = {};
                    if (existMeet1) {
                        tmpLocation = _this.latestThisWeekLineUp["서울"];
                    } else if (existMeet2) {
                        tmpLocation = _this.latestThisWeekLineUp["제주"];
                    } else if (existMeet3) {
                        tmpLocation = _this.latestThisWeekLineUp["부경"];
                    }

                    if (tmpLocation !== null && tmpLocation !== undefined) {
                        const tmpLocationDate = tmpLocation["dates"];
                        let tmpTargetDate = ""; // "20210101"
                        if (tmpLocationDate !== null && tmpLocationDate !== undefined && tmpLocationDate.length !== 0) {
                            tmpTargetDate = tmpLocationDate[0];
                            const tmpTargetDateRace = tmpLocation[tmpTargetDate];
                            if (tmpTargetDateRace !== null && tmpTargetDateRace !== undefined && tmpTargetDateRace.length !== 0) {
                                if (tmpTargetDateRace.races !== null && tmpTargetDateRace.races !== undefined && tmpTargetDateRace.races.length !== 0) {
                                    tmpTargetDateRace[tmpTargetDateRace.races[tmpTargetDateRace.races.length - 1]].some(ss => {
                                        if (ss["existHorseDetailData"]) {
                                            _this.clickThisWeekLineUp(ss, false);
                                        }
                                        return ss.existHorseDetailData;
                                    });
                                    _this.clickThisWeekLineUp("", false);
                                }
                            }
                        }
                    }
                }
                _this.loading = false;
            }).catch(function(error) {
                console.log(("HELLO : ERROR Getting cached document: ", error));
                _this.loading = false;
            });
        },

        clickToggle(t1, t2, t3) {
            this.selectedLocation = t1;
            this.selectedDate = t2;
            this.selectedRaceNo = t3;

            this.listLocation = ["서울", "제주", "부경"];
            this.listDate = [];
            this.listRaceNo = [];
            this.showHorseItems = [];
            this.existShowHorseItems = true;

            // this.selectedLocation = t1;
            switch (this.selectedLocation) {
                case "서울":
                    this.selectedStyleLocationSeoul = this.styleLocationSeoul;
                    this.selectedStyleLocationJeju = this.styleLocationNone;
                    this.selectedStyleLocationBK = this.styleLocationNone;
                    break;
                case "제주":
                    this.selectedStyleLocationSeoul = this.styleLocationNone;
                    this.selectedStyleLocationJeju = this.styleLocationJeju;
                    this.selectedStyleLocationBK = this.styleLocationNone;
                    break;
                case "부경":
                    this.selectedStyleLocationSeoul = this.styleLocationNone;
                    this.selectedStyleLocationJeju = this.styleLocationNone;
                    this.selectedStyleLocationBK = this.styleLocationBK;
                    break;
            }

            if (Object.keys(this.latestThisWeekLineUp[this.selectedLocation]).includes("dates")) {


                this.listDate = this.latestThisWeekLineUp[this.selectedLocation]["dates"];

                this.selectedStyleDate = {};

                if (this.selectedDate === "") {
                    this.selectedDate = this.listDate[0];
                }
                this.listDate.forEach(fff => {
                    this.selectedStyleDate[fff] = this.styleDateFalse;
                });
                this.selectedStyleDate[this.selectedDate] = this.styleDateTrue;

                if (Object.keys(this.latestThisWeekLineUp[this.selectedLocation][this.selectedDate]).includes("races")) {

                    this.listRaceNo = this.latestThisWeekLineUp[this.selectedLocation][this.selectedDate]["races"];
                    this.selectedStyleRaceNo = {};

                    if (this.selectedRaceNo === "") {
                        this.selectedRaceNo = this.listRaceNo[this.listRaceNo.length - 1];
                    }
                    this.listRaceNo.forEach(fff => {
                        this.selectedStyleRaceNo[fff] = this.styleRaceNoFalse;
                    });
                    this.selectedStyleRaceNo[this.selectedRaceNo] = this.styleRaceNoTrue;
                    this.showHorseItems = this.latestThisWeekLineUp[this.selectedLocation][this.selectedDate][this.selectedRaceNo];
                    if (this.showHorseItems[0] != "") {
                        this.showHorseItems.unshift("");
                    }
                }
            }

            console.log("[zoo][clickToggle] ", this.latestThisWeekLineUp);
            console.log("[zoo][clickToggle] ", this.selectedLocation, this.selectedDate, this.selectedRaceNo);
            console.log("[zoo][clickToggle] this.showHorseItems ", this.showHorseItems);

            if (this.showHorseItems.length === 0) {
                this.existShowHorseItems = false;
            } else {
                this.existShowHorseItems = true;
            }

        },

        getMeetNuumberOfLocation(loc) {
            let resMeetNo = 0;
            switch (loc) {
                case "서울":
                    resMeetNo = 1;
                    break;
                case "제주":
                    resMeetNo = 2;
                    break;
                case "부경":
                    resMeetNo = 3;
                    break;
            }

            return resMeetNo;
        },

        getLocationOfThisWeekLineUp(item) {

            let meetID = 0;

            if (item["target"] === "horse") {
                meetID = item["horse"]["meet"];
            } else if (item["target"] === "race") {
                meetID = item["race"]["meet"];
            }

            let loc = "";
            switch (meetID) {
                case 1:
                    loc = "서울";
                    break;
                case 2:
                    loc = "제주";
                    break;
                case 3:
                    loc = "부경";
                    break;
            }

            return loc;
        },

        clickRenewOfThisWeekLineUp() {
            console.log("[HELLOZ] clickRenewOfThisWeekLineUp");
            this.loadLatestThisWeekLineUp();
            if (this.latestThisWeekLineUp["서울"] !== null && this.latestThisWeekLineUp["서울"] !== undefined && this.latestThisWeekLineUp["서울"] !== {}) {
                console.log("[HELLOZ] seoul");
                this.clickToggle("서울", "", "");
            } else if (this.latestThisWeekLineUp["제주"] !== null && this.latestThisWeekLineUp["제주"] !== undefined && this.latestThisWeekLineUp["제주"] !== {}) {
                this.clickToggle("제주", "", "");
            } else if (this.latestThisWeekLineUp["부경"] !== null && this.latestThisWeekLineUp["부경"] !== undefined && this.latestThisWeekLineUp["부경"] !== {}) {
                this.clickToggle("부경", "", "");
            }
        },

        clickThisWeekLineUp(item, isScroll) {
            console.log("[71][ThisWeekLineUpCard.vue][clickThisWeekLineUp] IN ", item.existHorseDetailData);


            if (item == "") { // RACE

                let raceMeet = this.getMeetNuumberOfLocation(this.selectedLocation);
                let raceDate = Number(this.selectedDate);
                let raceNo = Number(this.selectedRaceNo);
                let raceDetailID = "race-" + raceMeet + "-" + raceDate + "-" + raceNo;

                console.log("[71][ThisWeekLineUpCard.vue][clickThisWeekLineUp] RACE ID: ", raceDetailID);
                this.analyticsLogEvent("click_thisWeekLineUp_race", raceDetailID);

                let queueItem = {
                    "target": "race",
                    "id": raceDetailID,
                    "race": {
                        "meet": raceMeet,
                        "date": raceDate,
                        "no": raceNo
                    },
                    "favorite": false
                };

                let renewDetailDisplayItems = this.$store.getters["detailCardStore/GET_DETAIL_DISPLAY"]["items"];
                renewDetailDisplayItems = renewDetailDisplayItems.filter(ff => ff["id"] !== raceDetailID);
                renewDetailDisplayItems = [queueItem].concat(renewDetailDisplayItems);

                const renewDetailDisplay = {
                    ts: new Date().getTime(),
                    items: renewDetailDisplayItems
                };

                this.$store.commit("detailCardStore/MUT_DETAIL_DISPLAY_OVERWRITE", renewDetailDisplay);
                localStorage.setItem("DetailDisplay", JSON.stringify(this.$store.getters["detailCardStore/GET_DETAIL_DISPLAY"]));

                if (isScroll) {
                    this.$store.commit("detailCardStore/MUT_DETAIL_SCROLL_ID_OVERWRITE", raceDetailID);
                }
            } else { // HORSE
                console.log("[71][ThisWeekLineUpCard.vue][clickThisWeekLineUp] HORSE item : ", item);

                this.analyticsLogEvent("click_thisWeekLineUp_horse", item["id"]);

                if (item.existHorseDetailData) {
                    let renewDetailDisplayItems = this.$store.getters["detailCardStore/GET_DETAIL_DISPLAY"]["items"];
                    console.log("[ThisWeekLineUpCard.vue][methods-clickThisWeekLineUp] Prev-Opened: ", renewDetailDisplayItems);
                    renewDetailDisplayItems = renewDetailDisplayItems.filter(ff => ff["id"] !== item["id"]);
                    this.$store.getters["favoriteStore/GET_FAVORITE"]["items"].some(fff => {
                        if (fff["id"] === item["id"]) {
                            item["favorite"] = true;
                        } else {
                            item["favorite"] = false;
                        }
                        return fff["id"] === item["id"];
                    });

                    let queueItem = {
                        "target": "horse",
                        "id": item["id"],
                        "horse": item["horse"], // hid:"042961", meet:1, name:"최강퀸"
                        "favorite": item["favorite"]
                    };
                    renewDetailDisplayItems = [queueItem].concat(renewDetailDisplayItems);

                    const renewDetailDisplay = {
                        ts: new Date().getTime(),
                        items: renewDetailDisplayItems
                    };
                    this.$store.commit("detailCardStore/MUT_DETAIL_DISPLAY_OVERWRITE", renewDetailDisplay);
                    localStorage.setItem("DetailDisplay", JSON.stringify(this.$store.getters["detailCardStore/GET_DETAIL_DISPLAY"]));

                    if (isScroll) {
                        this.$store.commit("detailCardStore/MUT_DETAIL_SCROLL_ID_OVERWRITE", item["id"]);
                    }
                } else {
                    alert(`'${item.horse.name}'의 데이터가 없습니다.`);
                }
            }
        },
        analyticsLogEvent(target, hID) {
            let userID = this.$store.getters["userAccountInfoStore/GET_USER_ACCOUNT_INFO"].uid;
            if (!(userID === undefined || userID === null || userID === "")) {  // 로그인 상태
                userID = "none";
            }
            firebase.analytics().logEvent(target, {
                HID: hID,
                UserID: userID
            });
        }
    }
};
</script>
<style scoped></style>>
