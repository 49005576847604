const trendTop10Store = {
    namespaced: true,
    state: {
        thisWeekLineUp: {}
    },
    getters: {
        GET_THIS_WEEK_LINE_UP: state => state.thisWeekLineUp
    },
    mutations: {
        // TREND 는 Firestore 로 부터 받는 Data를 Overwrite 하는 것만 있다.
        MUT_THIS_WEEK_LINE_UP_OVERWRITE: (state, payload) => {
            state.thisWeekLineUp = payload;
        }
    },
};

export default trendTop10Store;