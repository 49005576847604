<template>
    <v-container
        class="fill-height"
        fluid
    >
        {{ token }}
        <!--        <v-btn-->
        <!--            color="primary"-->
        <!--            @click="signin"-->
        <!--        >SignIn</v-btn>-->
    </v-container>
</template>

<script>
import firebase from "firebase";
import "firebase/auth";
import "firebase/functions";


if (!window.Kakao.isInitialized()) {
    window.Kakao.init("cc62197b73736b79417722ae96ff00dc"); // js key
    window.Kakao.isInitialized();
}
export default {
    data() {
        return {
            hasEmail: true,
            token: ""
        };
    },
    created() {
    },
    mounted() {
        const _this = this;
        console.log("[AuthKakao.vue] this.$route.query.code: ", this.$route.query.code);
        const kakaoAuthCode = this.$route.query.code;

        if (kakaoAuthCode) {
            console.log("[AuthKakao.vue] kakaoAuthCode: ", kakaoAuthCode);
            this.token = kakaoAuthCode;


            const hostName = location.hostname;
            let originHost = "";
            if (hostName == "localhost") {
                originHost = "localhost";
            } else if (hostName == "malgogo.ai") {
                originHost = "malgogo";
            }

            console.log("[AuthKakao.vue] originHost: ", originHost);

            // 카카오 로그인 토큰을 파이어베이스 함수에 전달합니다.
            var kakaoAuth = firebase.functions().httpsCallable("kakaotoken");
            kakaoAuth({ code: kakaoAuthCode, originhost: originHost }).then(function(result) {
                console.log("[AuthKakao.vue][functions-kakaotoken][then] result", result);

                // Read result of the Cloud Function.
                var kakaoToken = result.data.kakao_token;
                var fireToken = result.data.firebase_token;

                console.log("[AuthKakao.vue][functions-kakaotoken][then] kakaoToken: ", kakaoToken);
                console.log("[AuthKakao.vue][functions-kakaotoken][then] firebaseToken: ", fireToken);

                // 토근이 정상적으로 처리될 경우 로그인 처리합니다.
                firebase.auth().signInWithCustomToken(fireToken)
                    .then(function(result) {
                        console.log("[AuthKakao.vue][firebase.auth().signInWithCustomToken][then] result: ", result);
                        _this.token = kakaoToken;
                        window.Kakao.Auth.setAccessToken(kakaoToken);
                        if (result.additionalUserInfo.isNewUser) {
                            firebase.analytics().logEvent("login_success", {
                                Provider: "kakao",
                                Message: "newAccount"
                            });
                            console.log("[AuthKakao.vue][firebase.auth().signInWithCustomToken][then] 신규사용자");
                            _this.$router.push({ name: "Account" });   // welcome
                        } else {
                            console.log("[AuthKakao.vue][firebase.auth().signInWithCustomToken][then] 기존사용자");
                            firebase.analytics().logEvent("login_success", {
                                Provider: "kakao",
                                Message: "existingAccount"
                            });
                            _this.$router.push({ name: "Account" });
                        }
                    })
                    .catch(function(error) {
                        // Handle Errors here.
                        var errorCode = error.code;
                        var errorMessage = error.message;
                        console.log("[AuthKakao.vue][firebase.auth().signInWithCustomToken][catch] error: ", errorCode, errorMessage);
                        // console.log(error);
                        alert("로그인 실패했습니다.\n( CODE: L001 )");
                        _this.$router.replace({ name: "Account" });
                        firebase.analytics().logEvent("login_failure", {
                            Provider: "kakao",
                            ErrorCode: "L001"
                        });
                        return;
                    });
            }).catch(function(error) {
                // Getting the Error details.
                console.log("[AuthKakao.vue][functions-kakaotoken][catch] error: ", error.code, error.message, error.details);
                console.log("[AuthKakao.vue][functions-kakaotoken][catch] error-body: ", error.body);
                // alert("정상적이지 않은 접근입니다. 만료된 데이터이거나 이미 사용된 데이터입니다.");
                alert("로그인 실패했습니다.\n( CODE: L002 )");
                firebase.analytics().logEvent("login_failure", {
                    Provider: "kakao",
                    ErrorCode: "L002"
                });
                _this.$router.replace({ name: "Account" });

                return;
            });
        } else {
            alert("로그인 실패했습니다.\n( CODE: L003 )");
            firebase.analytics().logEvent("login_failure", {
                Provider: "kakao",
                ErrorCode: "L003"
            });
            _this.$router.replace({ name: "Account" });
            return;
        }
    },
    methods: {}
};
</script>