const trendTop10Store = {
    namespaced: true,
    state: {
        trendTop10: {}
    },
    getters: {
        GET_TREND_TOP10: state => state.trendTop10
    },
    mutations: {
        // TREND 는 Firestore 로 부터 받는 Data를 Overwrite 하는 것만 있다.
        MUT_TREND_TOP10_OVERWRITE: (state, payload) => {
            state.trendTop10 = payload;
        }
    },
    actions: {}
};

export default trendTop10Store;