/* eslint-disable */
<template>
    <v-container class="overflow-hidden">
        <v-app-bar
            absolute
            class="white"
            elevation="0"
            flat
        >
            <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
            <v-btn icon @click="clickAccountIcon()">
                <v-avatar color="white" size="32">
                    <v-icon v-if=this.turnOnAccount color="green">mdi-menu</v-icon>
                    <v-icon v-else>mdi-menu</v-icon>
                </v-avatar>
            </v-btn>
            <v-spacer></v-spacer>

            <!-- <v-toolbar-title @click="clickHome()">말고고.ai βetα</v-toolbar-title>-->
            <v-img
                max-height="32"
                max-width="32"
                class="text-center"
                src="../assets/logo_128x128.png"
                alt="경마-말고고-경마분석-경마문화-서울경마-제주경마-부경경마-문자-타이틀"
            ></v-img>
            <!-- "말고고.ai" 바로 전에 안보이는 공백사용 'ㅤ' -->
            <v-toolbar-title class=" font-weight-regular" @click="clickHome()">ㅤ말고고.ai</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon @click="clickSearchIcon()">
                <v-icon v-if=this.turnOnSearch color="blue">mdi-magnify</v-icon>
                <v-icon v-else>mdi-magnify</v-icon>
            </v-btn>

            <v-btn icon @click="clickFavoriteIcon()">
                <v-icon v-if=this.turnOnFavorite color="red">mdi-heart-outline</v-icon>
                <v-icon v-else>mdi-heart-outline</v-icon>
            </v-btn>
        </v-app-bar>
    </v-container>
</template>

<script>
import router from "@/router";
import firebase from "firebase";

export default {
    name: "AppBar",

    data() {
        return {
            active: "",
            group: "",
            cannotFavoriteMessageOfAppBar: "좋아요 기능은\n로그인 후에 사용할 수 있어요 :D"
        };
    },

    watch: {},
    computed: {
        turnOnFavorite() {
            if (this.$route.name == "Favorite") {
                return true;
            } else {
                return false;
            }
        },
        turnOnSearch() {
            if (this.$route.name == "Search") {
                return true;
            } else {
                return false;
            }
        },
        turnOnAccount() {
            if (this.$route.name == "Account") {
                return true;
            } else {
                return false;
            }
        }

    },


    created() {
        console.log("[104][AppBar][created] In ");

        // todo
        let lsDetailDisplay = JSON.parse(localStorage.getItem("DetailDisplay"));
        if (lsDetailDisplay == null || lsDetailDisplay == undefined) {
            lsDetailDisplay = {
                ts: new Date().getTime(),
                items: []
            };
        }
        this.$store.commit("detailCardStore/MUT_DETAIL_DISPLAY_OVERWRITE", lsDetailDisplay);

        let prevHorseDetailCardStore = this.$store.getters["detailCardStore/GET_DETAIL_HORSE"];
        const prevHorseDetailCardIDs = prevHorseDetailCardStore.map(f => f.id);

        let prevRaceDetailCardStore = this.$store.getters["detailCardStore/GET_DETAIL_RACE"];
        const prevRaceDetailCardIDs = prevRaceDetailCardStore.map(f => f.id);

        lsDetailDisplay.items.forEach(item => {
            // 1. item.id 가 GET_DETAIL_HORSE 에 있는지 확인
            if (!(prevHorseDetailCardIDs.includes(item.id) || prevRaceDetailCardIDs.includes(item.id))) {
                // 2. 없으면, 해당 item.id 를 Push => fireStore 에서 해당마 정보 Get => item.id 위치에 Update
                let tHorse = false;
                let tRace = false;
                if (item.target == "horse") {
                    tHorse = true;
                } else if (item.target == "race") {
                    tRace = true;
                }

                if (tHorse) {
                    let horseDetailShell = {
                        id: item.id,
                        horseMeet: 0,
                        horseID: "",
                        horseName: "",
                        horseRating: 0,
                        horseGrade: "",
                        horseSex: "",
                        horseHome: "",
                        horseTrainer: "",
                        horseOwner: "",
                        horseBirth: "",
                        horseAge: "",
                        horseColor: "",
                        horseRaceRange: "",
                        horseRaceRecord: "",
                        horseWinningRate: "",
                        horseRegistration: "",
                        horsePrizeAll: "",
                        horsePrizeLatest6: "",
                        horsePrizeLatest3: "",
                        trendPrize: [],
                        trendRating: [],
                        trendWeight: [],
                        matchHorseNumber: [],
                        matchJockey: [],
                        matchTool: [],
                        matchRaceDistance: [],
                        matchTrackConditionDisc: [],
                        matchTrackConditionPercentage: [],
                        analyticsSpeed: []
                    };
                    this.$store.commit("detailCardStore/MUT_DETAIL_HORSE_PUSH_UNIQUE", horseDetailShell);

                    const _this = this;
                    firebase.firestore().collection("HorseDetail").doc(item.id).get().then(function(doc) {
                        horseDetailShell.id = doc.data().HID;
                        horseDetailShell.horseMeet = doc.data().HorseMeet;
                        horseDetailShell.horseID = doc.data().HorseID;
                        horseDetailShell.horseName = doc.data().HorseName;
                        horseDetailShell.horseRating = doc.data().HorseRating;
                        horseDetailShell.horseGrade = doc.data().HorseGrade;
                        horseDetailShell.horseSex = doc.data().HorseSex;
                        horseDetailShell.horseHome = doc.data().HorseHome;
                        const trainerSplits = doc.data().HorseTrainer.split("-");
                        if (trainerSplits.length >= 2) {
                            horseDetailShell.horseTrainer = trainerSplits[1];
                        }
                        const ownerSplits = doc.data().HorseOwner.split("-");
                        if (ownerSplits.length >= 2) {
                            horseDetailShell.horseOwner = ownerSplits[1];
                        }
                        horseDetailShell.horseBirth = doc.data().HorseBirth;
                        horseDetailShell.horseAge = doc.data().HorseAge;
                        horseDetailShell.horseColor = doc.data().HorseColor;
                        horseDetailShell.horseRaceRange = doc.data().HorseRaceRange;
                        horseDetailShell.horseRaceRecord = doc.data().HorseRaceRecord;
                        horseDetailShell.horseWinningRate = doc.data().HorseWinningRate;
                        horseDetailShell.horseRegistration = doc.data().HorseRegistration;
                        horseDetailShell.horsePrizeAll = doc.data().HorsePrizeAll;
                        horseDetailShell.horsePrizeLatest6 = doc.data().HorsePrizeLatest6;
                        horseDetailShell.horsePrizeLatest3 = doc.data().HorsePrizeLatest3;
                        horseDetailShell.trendPrize = doc.data().TrendPrize;
                        horseDetailShell.trendRating = doc.data().TrendRating;
                        horseDetailShell.trendWeight = doc.data().TrendWeight;
                        horseDetailShell.matchHorseNumber = doc.data().MatchHorseNumber;
                        horseDetailShell.matchJockey = doc.data().MatchJockey;
                        horseDetailShell.matchTool = doc.data().MatchTool;
                        horseDetailShell.matchRaceDistance = doc.data().MatchRaceDistance;
                        horseDetailShell.matchTrackConditionDisc = doc.data().MatchTrackConditionDisc;
                        horseDetailShell.matchTrackConditionPercentage = doc.data().MatchTrackConditionPercentage;
                        horseDetailShell.analyticsSpeed = doc.data().AnalyticsSpeed;
                        _this.$store.commit("detailCardStore/MUT_DETAIL_HORSE_UPDATE", horseDetailShell);
                    }).catch(function(error) {
                        console.error("[AppBar][created] lsDetailDisplay. Exception of Firebase HorseDetail : ", error);
                    });
                }

                if (tRace) {
                    console.log("[77][AppBar][created] Race ");
                    let raceDetailShell = {
                        id: item.id,
                        raceDate: 19820612,
                        raceHorses: [],
                        raceMeet: 0,
                        raceAgeLimit: "",
                        raceBurdenType: "",
                        raceClass: "",
                        raceDistance: 0,
                        raceName: "",
                        raceNo: "",
                        racePrize1: "",
                        racePrize1A: "",
                        racePrize2: "",
                        racePrize2A: "",
                        racePrize3: "",
                        racePrize3A: "",
                        racePrize4: "",
                        racePrize4A: "",
                        racePrize5: "",
                        racePrize5A: "",
                        raceRating: "",
                        raceStartTime: "",
                        raceVersusSpeeds: [],
                        raceVersusRecords: []
                        // racePredictScroeTop3 todo
                    };
                    this.$store.commit("detailCardStore/MUT_DETAIL_RACE_PUSH_UNIQUE", raceDetailShell);

                    const _this = this;
                    firebase.firestore().collection("RaceDetail").doc(item.id).get().then(function(doc) {
                        raceDetailShell.id = item.id;
                        raceDetailShell.raceDate = doc.data().Date;
                        raceDetailShell.raceHorses = doc.data().Horses;
                        raceDetailShell.raceMeet = doc.data().Meet;
                        raceDetailShell.raceAgeLimit = doc.data().RaceAgeLimit;
                        raceDetailShell.raceBurdenType = doc.data().RaceBurdenType;
                        raceDetailShell.raceClass = doc.data().RaceClass;
                        raceDetailShell.raceDistance = doc.data().RaceDistance;
                        raceDetailShell.raceName = doc.data().RaceName;
                        raceDetailShell.raceNo = doc.data().RaceNo;
                        raceDetailShell.racePrize1 = doc.data().RacePrize1;
                        raceDetailShell.racePrize1A = doc.data().RacePrize1A;
                        raceDetailShell.racePrize2 = doc.data().RacePrize2;
                        raceDetailShell.racePrize2A = doc.data().RacePrize2A;
                        raceDetailShell.racePrize3 = doc.data().RacePrize3;
                        raceDetailShell.racePrize3A = doc.data().RacePrize3A;
                        raceDetailShell.racePrize4 = doc.data().RacePrize4;
                        raceDetailShell.racePrize4A = doc.data().RacePrize4A;
                        raceDetailShell.racePrize5 = doc.data().RacePrize5;
                        raceDetailShell.racePrize5A = doc.data().RacePrize5A;
                        raceDetailShell.raceRating = doc.data().RaceRating;
                        raceDetailShell.raceStartTime = doc.data().RaceStartTime;
                        raceDetailShell.raceVersusSpeeds = doc.data().VersusSpeeds;
                        raceDetailShell.raceVersusRecords = doc.data().VersusRecords;
                        // raceDetailShell.racePredictScoreTop3 = doc.data().PredictScoreTop3; // todo
                        _this.$store.commit("detailCardStore/MUT_DETAIL_RACE_UPDATE", raceDetailShell);
                    }).catch(function(error) {
                        console.error("[AppBar][created] lsDetailDisplay. Exception of Firebase RaceDetail : ", error);
                    });
                }
            }
        });

        const _this = this;
        firebase.auth().onAuthStateChanged(function(user) {
            if (user == null || user == undefined) { // 로그아웃 상태
                console.log("[104][AppBar.vue][auth().onAuthStateChanged] LogOut Status");
                _this.$store.commit("userAccountInfoStore/MUT_GET_USER_ACCOUNT_INFO_OVERWRITE",
                    {
                        login: false,
                        provider: "",
                        email: "",
                        displayName: "",
                        thumbNailURL: ""
                    }
                );
            } else { // 로그인 상태
                console.log("[104][AppBar.vue][auth().onAuthStateChanged] LogIn Status");

                const loginUser = firebase.auth().currentUser;
                _this.$store.commit("userAccountInfoStore/MUT_GET_USER_ACCOUNT_INFO_OVERWRITE",
                    {
                        login: true,
                        provider: loginUser.providerId,
                        email: loginUser.email,
                        displayName: loginUser.displayName,
                        thumbNailURL: loginUser.photoURL,
                        uid: loginUser.uid
                    }
                );
                _this.getFavoriteOfAccountFromFireStore();
            }
        });
    },

    beforeMount() {
        console.log("[104][AppBar][beforeMount] In ");
        this.getFavoriteOfAccountFromFireStore();
    },

    beforeUpdate() {
        console.log("[104][AppBar][beforeUpdate] In ");
        this.getFavoriteOfAccountFromFireStore();
    },

    updated() {
    },

    mounted() {
    },

    methods: {
        getFavoriteOfAccountFromFireStore() {
            console.log("[104][AppBar] In getFavoriteOfAccountFromFireStore");
            const userEmail = this.$store.getters["userAccountInfoStore/GET_USER_ACCOUNT_INFO"].email;
            let _this = this;
            firebase.firestore().collection("UserDetail").doc(userEmail).get().then(function(doc) {
                let favoriteData = doc.data();
                if ((favoriteData == undefined) || (favoriteData == null)) {
                    const tmpFavorites = {
                        ts: new Date().getTime(),
                        items: []
                    };
                    _this.$store.commit("favoriteStore/MUT_FAVORITE_OVERWRITE", tmpFavorites);
                } else {
                    _this.$store.commit("favoriteStore/MUT_FAVORITE_OVERWRITE", favoriteData["Favorite"]);
                }
            }).catch(function(error) {
                console.error("[AppBar] No UserID on FireStore: ", error);
            });
        },

        clickAccountIcon() {
            if (this.$route.name != "Account") {
                router.push({ name: "Account" });
            } else {
                router.push({ name: "Home" });
            }
        },
        clickSearchIcon() {
            if (this.$route.name != "Search") {
                router.push({ name: "Search" });
            } else {
                router.push({ name: "Home" });
            }
        },
        clickHome() {
            if (this.$route.name != "Home") {
                router.push({ name: "Home" });
            }
        },
        clickFavoriteIcon() {
            if (this.$route.name != "Favorite") {
                // if (this.$store.getters["userAccountInfoStore/GET_USER_ACCOUNT_INFO"].login) {
                //     // 로그인
                //     router.push({ name: "Favorite" });
                // } else {
                //     // 로그아웃
                //     alert(this.cannotFavoriteMessageOfAppBar);
                // }
                    router.push({ name: "Favorite" });
            } else {
                router.push({ name: "Home" });
            }
        }
    }
};
</script>
