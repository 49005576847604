import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index.js";
import vuetify from "./plugins/vuetify";
import "chart.js";
import "hchs-vue-charts";
import Chartkick from "chartkick";
import VueChartkick from "vue-chartkick";
import firebase from "firebase";

Vue.use(window.VueCharts);
Vue.use(VueChartkick, { Chartkick });
Vue.config.productionTip = false;

console.log = function(){};
console.warn = function(){};
console.error = function(){};

// npm run build
// firebase deploy --except functions


const firebaseConfig = {
    apiKey: "AIzaSyDZ93bbNi4GoYNbDMbt4Ckf89117Y5DIS8",
    authDomain: "malgogo.ai",
    databaseURL: "https://gopro-5362b.firebaseio.com",
    projectId: "gopro-5362b",
    storageBucket: "gopro-5362b.appspot.com",
    messagingSenderId: "394742694791",
    appId: "1:394742694791:web:c23d82fcdcb492fc674922",
    measurementId: "G-WT03190HT7"
};
firebase.initializeApp(firebaseConfig);

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount("#app");
