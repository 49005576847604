const searchStore = {
    namespaced: true,
    state: {
        searchHorse : {
            // "ㅅ": {
            //     ts: 1234567890,
            //     items: [
            //         {
            //             Name: "샴로커",
            //             Meet: 1,
            //             HID: "036488",
            //             Active: false
            //         }
            //     ],
            //     key: "ㅅ"
            // }
        },
        searchRaceDate : {}
    },
    getters: {
        GET_SEARCH_HORSE: state => state.searchHorse,
    },
    mutations: {
        MUT_SEARCH_HORSE_PUSH_UNIQUE: (state, searchHorseList) => {
            console.log("[searchStore.js] MUT_SEARCH_HORSE_PUSH_UNIQUE : ",  searchHorseList );
            state.searchHorse[searchHorseList['key']] = searchHorseList;
        },
    },
    actions: {}
};

export default searchStore;