<script>
import { Bar, mixins } from "vue-chartjs";

const { reactiveProp } = mixins;
export default {
    extends: Bar,
    mixins: [reactiveProp],

    data() {
        return {
            options: {
                tooltips: {
                    position:  'average',
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                        label: function(tooltipItems, data) {
                            return data.datasets[tooltipItems.datasetIndex].label + ": " + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] + " 만원";
                        },
                        // footer: function() {
                        //     return " -단위 : 속도 (m/s)";
                        // }
                    }
                },
                scales: {
                    yAxes: [
                        {
                            id: "y-won",
                            // type: 'logarithmic',
                            ticks: {
                                beginAtZero: true,
                                // suggestedMin: 50
                                stepSize: 5000
                                // suggestedMax: 80
                            },
                            gridLines: {
                                display: true,
                                borderDash: [],
                                lineWidth: 1
                            },
                            scaleLabel: {
                                display: true,
                                fontColor: "#111",
                                labelString: "성과 ( 만원 )",
                                fontSize: 15,
                                padding: 0
                            },
                            // stacked: true
                            offset: true,
                            position: "bottom"
                        }
                    ],
                    xAxes: [
                        {
                            id: "x-horse-num",
                            stacked: true,
                            display: true,
                            gridLines: {
                                display: true
                            },
                            // scaleLabel: {
                            //     display: true,
                            //     fontColor: "#111",
                            //     labelString: "장구(승)",
                            //     fontSize: 15,
                            //     padding: 0
                            // }
                        }
                    ]
                },
                legend: {
                    display: true
                },

                responsive: true,
                maintainAspectRatio: false
            }
        };
    },
    mounted() {
        this.renderChart(this.chartData, this.options);
    }
};
</script>
